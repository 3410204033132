let api_key, apiEndpoint, tokenEndpoint, shop_name;
let config = {};
let preOrderCurrentScriptUrl = document.currentScript.src;
let preOrderDomainName = preOrderCurrentScriptUrl
  .replace("http://", "")
  .replace("https://", "")
  .split(/[/?#]/)[0];
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
shop_name = params?.shop ? params?.shop : "";
const host = params?.host;
let forceRedirect =true;
if (preOrderDomainName === "localhost" || preOrderDomainName === "localhost:3001" || preOrderDomainName === "localhost:3000") {
    apiEndpoint = `http://localhost:4000`;
    tokenEndpoint = 'http://localhost:4000/node/admin_api';
    shop_name = 'hiral-preorder.myshopify.com';
}
else if (preOrderDomainName === "appbackend.preorderbat.com") {
    
    if(shop_name=="usman-testing1.myshopify.com"){
         forceRedirect =false;
         console.log("non-embeded app running"+shop_name);
        }
       
        config = {
            apiKey: `f066071a58a555a0e220626c961a85ad`,
            host: host,
            forceRedirect: forceRedirect
        }
    
    apiEndpoint= `https://appbackend.preorderbat.com`;
    tokenEndpoint = 'https://appbackend.preorderbat.com/node/admin_api';
    api_key = "f066071a58a555a0e220626c961a85ad";
}else if (preOrderDomainName === "devbackend.preorderbat.com") {
    config = {
        apiKey: `f066071a58a555a0e220626c961a85ad`,
        host: host,
        forceRedirect: true
    }
    apiEndpoint= `https://devbackend.preorderbat.com`;
    tokenEndpoint = 'https://devbackend.preorderbat.com/node/admin_api';
    api_key = "f066071a58a555a0e220626c961a85ad";
}

export { api_key, apiEndpoint, tokenEndpoint, config, shop_name, host };
