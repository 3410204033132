import  {useEffect,React} from 'react';
import {
    BlockStack,
    Button,
    Checkbox,
  } from "@shopify/polaris";
  import { useRecoilState } from "recoil";
import { useSelector } from "react-redux";

  import { methodSelectState , preOrderStatusCodeState , backInStockStatusCodeState, productSelectedStateBtn  } from "../../store/onboardingAtom";
import ProductSelection from './ProductSelection';
  
const ProductSelectMethod = ({handleNext}) => {
    const [methodChecked, setMethodChecked] = useRecoilState(methodSelectState)
    const [preOrderStatusCode, setPreOrderStatusCode] = useRecoilState(preOrderStatusCodeState)
    const [backInStockStatusCode, setBackInStockStatusCode] = useRecoilState(backInStockStatusCodeState)
    const shopinfo = useSelector((state) => state.commonReducer.shop_data);
    console.log("methodChecked",methodChecked)
    const [productSelectedBtn, setProductSelectedBtn] = useRecoilState(productSelectedStateBtn);

  return (
    <div className='step-3-layout'>
        <BlockStack gap={300}>
            {methodChecked.includes(1) &&
            <div className="method-rounded-card">
                <div className='method-layout'>
                    {preOrderStatusCode &&
                    <Checkbox
                        checked={true}
                    />}
                    <h2 className="heading-size-16">Pre-order</h2>
                </div>
                {!preOrderStatusCode &&
                    <ProductSelection type="pre-order"/>
                }
            </div>
            }
            {methodChecked.includes(2) &&
            <div className="method-rounded-card">
                <div className='method-layout'>
                    {backInStockStatusCode  &&
                        <Checkbox
                            checked={true}
                        />
                    }
                    <h2 className="heading-size-16">Back-in-stock</h2>
                </div>
                {((preOrderStatusCode || !methodChecked.includes(1))&& !backInStockStatusCode)  &&
                    <ProductSelection type="back-in-stock"/>
                }
            </div>
            }
        </BlockStack>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button variant="primary" disabled={productSelectedBtn} onClick={handleNext}>Next</Button>
        </div>
    </div>
  )
}

export default ProductSelectMethod;