import { useCallback, useEffect, useState } from "react";
import {
  IndexTable,
  Card,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  ChoiceList,
  Badge,
  IndexFiltersMode,
  Box,
  Spinner,
  Pagination
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";
import { ApiCall, GetApiCall } from "../../../helpers/axios";
import { useSelector } from "react-redux";
import { format } from "date-fns";
function MainTable({ data }) {
  const shopify = useAppBridge();
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };

  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const sortOptions = [
    { label: 'Order', value: 'order asc', directionLabel: 'Ascending' },
    { label: 'Order', value: 'order desc', directionLabel: 'Descending' },
    { label: 'Customer', value: 'customer asc', directionLabel: 'A-Z' },
    { label: 'Customer', value: 'customer desc', directionLabel: 'Z-A' },
    { label: 'Date', value: 'date asc', directionLabel: 'A-Z' },
    { label: 'Date', value: 'date desc', directionLabel: 'Z-A' },
    { label: 'Total', value: 'total asc', directionLabel: 'Ascending' },
    { label: 'Total', value: 'total desc', directionLabel: 'Descending' },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  // useEffect(() => {
  //   console.log("useEffect sortSelected",sortSelected);
  // }, [sortSelected]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [statusFilter, setStatusFilter] = useState(undefined);
  const [queryValue, setQueryValue] = useState("");
  const handleStatusFilterChange = useCallback((value) => {
    setStatusFilter(value);
    handleSubmissionsAll(value);
  }, []);
  const handleFiltersQueryChange = useCallback(
    (value) => {
      setQueryValue(value);
      handleSubmissionsAll("all", 1, value);
      console.log("queryValue", value);
    },
    []
  );
  const handleStatusFilterRemove = useCallback(
    () => {
      setStatusFilter(undefined);
      handleSubmissionsAll("all", 1, null);
    },
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleStatusFilterRemove();
    handleQueryValueRemove();
  }, [handleStatusFilterRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <ChoiceList
          choices={[
            { label: "All submissions", value: "all" },
            { label: "Email sent", value: "email-sent" },
            { label: "Email pending", value: "email-pending" },
          ]}
          selected={statusFilter || []}
          onChange={handleStatusFilterChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (statusFilter && !isEmpty(statusFilter)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, statusFilter[0]),
      onRemove: handleStatusFilterRemove,
    });

  }

  const [tableData, setTableData] = useState([]);
  const resourceName = {
    singular: "result",
    plural: "results",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(tableData);

  const tableHeadings = [
    { title: "Subscriber" },
    { title: "Product" },
    { title: "Status" },
    { title: "Subscribed date" },
  ];
  let handleSubmissionsAll = async (filter, page = 1, value = null) => {
    try {
      setLoading(true);
      let queryValue = value ? value : null;
      filter = filter ? filter[0] : "all";
      let data = {
        store_client_id: store_client_id,
        export_type: filter,
        page: page,
        search: queryValue,

      };
      console.log("data", data);
      let res = await ApiCall("POST", "/export_submission", data, header);
      if (res.status === 200) {
        setTableData(res?.data?.data?.select_data || []);
        setTotalPages(res?.data?.data?.total_page || 1); // Update total pages
        setCurrentPage(page); // Update current page
        console.log("res", res?.data?.data?.total_page);
      } else {
        setTableData([]);
      }
    } catch (error) {
      setTableData([]);
      console.log("error", error);

    }
    setLoading(false);
    console.log("loading", loading);
  };
  const handlePaginationNext = () => {
    if (currentPage < totalPages) {
      handleSubmissionsAll(statusFilter, currentPage + 1);
    }
  };

  const handlePaginationPrevious = () => {
    if (currentPage > 1) {
      handleSubmissionsAll(statusFilter, currentPage - 1);
    }
  };
  // const dummyData = [
  //   {
  //     id: "1",
  //     subscriber: "abc@abc.com",
  //     product: "swiss knife",
  //     type: "pending",
  //     date: "Nov 25 at 02:26 AM",
  //   },
  //   {
  //     id: "2",
  //     subscriber: "xyz@xyz.com",
  //     product: "shoes",
  //     type: "sent",
  //     date: "Nov 25 at 02:26 AM",
  //   },
  //   {
  //     id: "3",
  //     subscriber: "test@test.com",
  //     product: "coat",
  //     type: "pending",
  //     date: "Nov 25 at 02:26 AM",
  //   },
  // ];

  useEffect(() => {
    handleSubmissionsAll(statusFilter);
  }, []);

  const toggleDeleteProductModal = () => {
    shopify.modal.toggle("delete-product-modal");
  };

  let handleRemoveTableRecord = () => {
    toggleDeleteProductModal();
  };

  let handleRemoveTableRecordSubmit = async () => {

    let res = await ApiCall("DELETE", "/delete-submission", { id: selectedResources }, header);
    console.log("items deleted", selectedResources);
    if (res?.data?.statusCode === 200) {
      handleSubmissionsAll(statusFilter);
    }
    clearSelection();
    toggleDeleteProductModal();
  };

  const toggleSendEmailModal = () => {
    shopify.modal.toggle("send-email-modal");
  };

  let sendEmail = () => {
    toggleSendEmailModal();
  };

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  let handleSendEmailSubmit = async() => {
    console.log("email sent to: ", btnLoading);
    let data = {
      store_client_id: store_client_id,
      selectedResources: selectedResources,
    };
    setBtnLoading(true);
    // await sleep(2000);
    try {  
      let res = await ApiCall("POST", "/send-mail-back-in-stock", data, header);  
      if (res.data.statusCode === 200) {
        console.log("Email sent successfully: ", res.data.message);
      } else {
        console.error("Error sending email: ", res.data.message);
      }
    } catch (error) {
      console.error("API call failed: ", error);
    }
    console.log("email sent to: ", loading);
    clearSelection();
    toggleSendEmailModal();
    console.log("setBtnLoading", setBtnLoading())
    shopify.toast.show('Email Send Successfully', {
      duration: 3000,
    });

    setBtnLoading(false)
    console.log("setBtnLoading", setBtnLoading())
  };

  let handleExportSubmission = async () => {
    let data = {
      store_client_id: store_client_id,
      selectedResources: selectedResources,
    };
    try {
      let res = await ApiCall("POST", "/export_submission", data, header);
      if (res.data.statusCode === 200) {
        const headers = [
          'customer_email', 'customer_phone', 'product_id', 'product_title',
          'product_image', 'product_tags', 'variant_id', 'variant_title',
          'sku', 'inventory_quantity', 'inventory_id'
        ];

        // Generate CSV content
        const generateCSV = (data) => {
          const rows = data.map(row =>
            headers.map(header => `"${row[header] || ''}"`).join(',')
          );
          return [headers.join(','), ...rows].join('\n');
        };
        // Prepare CSV data from the response
        const csvData = generateCSV(res?.data?.data?.select_data);

        // Create a Blob from the CSV data  
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        // Create a link element  
        const link = document.createElement('a');

        // Create a URL for the Blob and set it as the link's href  
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);

        // Set the download attribute with a filename  
        link.setAttribute('download', 'SubmissionsExports.csv');

        // Append the link to the document (it needs to be part of the DOM)  
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download  
        link.click();

        // Clean up the URL and remove the link from the DOM  
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } else {
        console.error("Error downloading CSV: ", res.data.message);
      }
    } catch (error) {
      console.error("API call failed: ", error);
    }
    console.log("handleExportSubmission", selectedResources);
    clearSelection();
  };

  let openSubmission = (id) => {
    navigate(`/back-in-stock/submission/${id}`);
  };

  return (
    <Card padding={"0"}>
      <IndexFilters
        // sortOptions={sortOptions}
        // sortSelected={sortSelected}
        // onSort={setSortSelected}
        queryValue={queryValue}
        queryPlaceholder="Search by customer email, product title, variant title, sku"
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={() => setQueryValue("")}
        tabs={[]}
        filters={filters}
        appliedFilters={appliedFilters}
        onClearAll={handleFiltersClearAll}
        mode={mode}
        setMode={setMode}
      />
      {loading ? (
        <div className="d-flex justify-content-center pt-3">
          <Spinner accessibilityLabel="Loading products" size="large" />
        </div>
      ) : (
        <>
          <IndexTable
            resourceName={resourceName}
            itemCount={tableData.length}
            selectedItemsCount={
              allResourcesSelected ? "All" : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            headings={tableHeadings}
            pagination={{
              hasNext: currentPage < totalPages,
              hasPrevious: currentPage > 1,
              onNext: () => { handlePaginationNext() },
              onPrevious: () => { handlePaginationPrevious() },
            }}
            promotedBulkActions={[
              {
                content: "Send mail",
                onAction: sendEmail,
              },
              {
                content: "Export submissions",
                onAction: handleExportSubmission,
              },
              {
                content: "Delete",
                onAction: handleRemoveTableRecord,
              },
            ]}
          >

            {tableData.map((item, index) => (
              <IndexTable.Row
                id={item.id}
                key={item.id}
                selected={selectedResources.includes(item.id)}
                position={index}
              >
                <IndexTable.Cell>
                  <div onClick={() => openSubmission(item.id)}>
                    {item.customer_email}
                  </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {" "}
                  {item.product_title ? item.product_title.replace(/&quot;/g, '') : item.variant_title} ({item.variant_title})

                </IndexTable.Cell>
                <IndexTable.Cell>
                  {item.status == 0 && <Badge>Email pending</Badge>}
                  {item.status == 1 && <Badge tone="success">Email sent</Badge>}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {format(new Date(item.created_at), "MMM dd 'at' hh:mm a")}
                </IndexTable.Cell>
              </IndexTable.Row>
            ))}
          </IndexTable>

        </>
      )}

      {/* delete confirmation modal */}
      <Modal id="delete-product-modal">
        <TitleBar title="Delete submission?">
          <button variant="primary" onClick={handleRemoveTableRecordSubmit}>
            Delete
          </button>
          <button onClick={toggleDeleteProductModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          All submissions data also are removed. Once deleted, it cannot be
          completed. Are you sure you want to delete?
        </Box>
      </Modal>

      {/* email confirmation modal */}
      <Modal id="send-email-modal">
        <TitleBar title="Are you sure you want to send email?">
          <button variant="primary" disabled={btnLoading} onClick={handleSendEmailSubmit}>
            Send
          </button>
          <button onClick={toggleSendEmailModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          You're about to send an email to the selected submission(s), are you
          sure you want to send it?
        </Box>
      </Modal>
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        if (value == "all") return `Status: All submissions`;
        if (value == "email-sent") return `Status: Email sent`;
        if (value == "email-pending") return `Status: Email pending`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}

export default MainTable;
