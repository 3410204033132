import { atom } from "recoil";

export const methodSelectState = atom({
  key: "methodSelectState",
  default: [],
});

export const preOrderStatusCodeState = atom({
  key: "preOrderStatusCodeState",
  default: false,
});

export const backInStockStatusCodeState = atom({
  key: "backInStockStatusCodeState",
  default: false,
});

export const productSelectedStateBtn = atom({
  key: "productSelectedStateBtn",
  default: true,
});

