import { useRecoilState } from "recoil";
import {
  appearanceEmailButtonState,
  appearanceFieldsState,
  appearanceFooterState,
  appearanceHeaderState,
  appearanceSubscribeButtonState,
} from "../../../../store/appearanceSettingAtom";
import headerImg from "../../../../assets/images/email_img.svg";
import StringToHtml from "../../utils/StringToHtml";
import { useState } from "react";

const Preview = ({ showOnProductPge }) => {
  let [headerData] = useRecoilState(appearanceHeaderState);
  let [emailBtn] = useRecoilState(appearanceEmailButtonState);
  let [subscribeBtn] = useRecoilState(appearanceSubscribeButtonState);
  let [fields] = useRecoilState(appearanceFieldsState);
  let [footerData] = useRecoilState(appearanceFooterState);

  let [isModalOpen, setIsModalOpen] = useState(false);
  let toggleModal = () => setIsModalOpen(!isModalOpen);

  let getGridTemplateColumns = (columns) => {
    let str = "";
    for (let i = 0; i < columns; i++) {
      str += "auto ";
    }
    return str;
  };
  console.log("headerData.imgUrl",headerData.imgUrl);
  let checkBannerSvg = (  
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7803 0.96967C12.0732 1.26256 12.0732 1.73744 11.7803 2.03033L5.28033 8.53033C4.98744 8.82322 4.51256 8.82322 4.21967 8.53033L0.96967 5.28033C0.676777 4.98744 0.676777 4.51256 0.96967 4.21967C1.26256 3.92678 1.73744 3.92678 2.03033 4.21967L4.75 6.93934L10.7197 0.96967C11.0126 0.676777 11.4874 0.676777 11.7803 0.96967Z"
        fill="#0C5132"
      />
    </svg>
  );
  let warningBannerSvg = (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99997 3.75C7.41418 3.75 7.74997 4.08579 7.74997 4.5V8C7.74997 8.41421 7.41418 8.75 6.99997 8.75C6.58576 8.75 6.24997 8.41421 6.24997 8V4.5C6.24997 4.08579 6.58576 3.75 6.99997 3.75Z"
        fill="#5E4200"
      />
      <path
        d="M8.00003 10.5C8.00003 11.0523 7.55232 11.5 7.00003 11.5C6.44775 11.5 6.00003 11.0523 6.00003 10.5C6.00003 9.94771 6.44775 9.5 7.00003 9.5C7.55232 9.5 8.00003 9.94771 8.00003 10.5Z"
        fill="#5E4200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00007 0.5C5.95474 0.5 5.21619 1.20232 4.84765 1.94672C4.65568 2.33448 3.75987 4.0441 2.84308 5.79353L2.81534 5.84646C1.92293 7.54935 1.01717 9.27772 0.807189 9.70196C0.435129 10.4537 0.329336 11.4519 0.89956 12.316C1.46914 13.1791 2.44225 13.5 3.3636 13.5L10.6364 13.5C11.5577 13.5 12.5309 13.1791 13.1005 12.316C13.6707 11.4519 13.5649 10.4537 13.1928 9.70196C12.9832 9.27838 12.08 7.55499 11.189 5.85476L11.1569 5.79352C10.24 4.044 9.3443 2.33454 9.15247 1.94693C8.78402 1.20242 8.04547 0.5 7.00007 0.5ZM6.19193 2.61225C6.59608 1.79592 7.4041 1.79592 7.80809 2.61225C8.01019 3.02061 8.9201 4.75686 9.82966 6.49243C10.7383 8.22621 11.6466 9.9594 11.8485 10.3673C12.2525 11.1837 11.8485 12 10.6364 12L3.3636 12C2.15153 12 1.74749 11.1837 2.15153 10.3673C2.35379 9.95871 3.26471 8.22049 4.17483 6.4838C5.08286 4.75111 5.99008 3.01994 6.19193 2.61225Z"
        fill="#5E4200"
      />
    </svg>
  );
  let xMarkSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      fill="#000"
      viewBox="0 0 16 16"
    >
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
    </svg>
  );

  let product = {
    name: "Hand wash",
    imgUrl: "https://picsum.photos/200",
    price: "0.00",
  };

  return (
    <div className="bat-bis-root">
      <div className="bat-bis-skeleton-button"></div>
      <p className="bat-bis-body-14">$10.00 USD</p>
      <div className="bat-bis-skeleton-paragraph">
        <div className="bat-bis-skeleton-paragraph-item"></div>
        <div className="bat-bis-skeleton-paragraph-item"></div>
        <div className="bat-bis-skeleton-paragraph-item"></div>
        <div className="bat-bis-skeleton-paragraph-item"></div>
      </div>
      {(showOnProductPge == "button_&_popup" ||
        showOnProductPge == "floating_button") && (
        <>
          <button
            className={
              showOnProductPge == "floating_button"
                ? "bat-bis-floating-button"
                : ""
            }
            style={{
              fontSize: emailBtn.fontSize + "px",
              // fontFamily: emailBtn.fontFamily,
              fontWeight: emailBtn.fontWeight,
              color: emailBtn.textColor,
              backgroundColor: emailBtn.backgroundColor,
              padding: emailBtn.padding + "px",
              borderRadius: emailBtn.borderRadius + "px",
              borderWidth: emailBtn.borderWidth + "px",
              borderColor: emailBtn.borderColor,
            }}
            onClick={toggleModal}
          >
            {emailBtn.buttonText}
          </button>
          <p className="bat-bis-body-13">
            Click "Email me when available" to access the form submission.
          </p>
          {isModalOpen && (
            <div className="bat-bis-modal">
              <div className="bat-bis-modal-content">
                <button
                  className="bat-bis-modal-close-btn"
                  onClick={toggleModal}
                >
                  {xMarkSvg}
                </button>
                {headerData.headerContent.includes("header-graphic") && (
                  <img
                    src={headerData.imgUrl ? headerData.imgUrl : headerImg}
                    alt="email image"
                    className="bat-bis-img-full"
                  />
                )}
                <div className="bat-bis-text-center bat-bis-block-stack bat-bis-gap-300">
                  {headerData.headerContent.includes("header-title") && (
                    <h1 className="bat-bis-heading-16">
                      Email me when available
                    </h1>
                  )}
                  {headerData.headerContent.includes("header-description") && (
                    <p className="bat-bis-body-13 bat-bis-text-light">
                      Please enter your email address below to get an email
                      notification as soon as this product is available again.
                    </p>
                  )}
                </div>
                <div className="bat-bis-divider"></div>
                {fields.map((field, fieldIndex) => {
                  if (field.type == "email") {
                    return (
                      <label
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                      >
                        {field.showLabel && (
                          <span
                            className={`bat-bis-form-label ${
                              field.required && field.showRequired
                                ? "required"
                                : ""
                            }`}
                          >
                            {field.label}
                          </span>
                        )}
                        <input
                          type="email"
                          placeholder={field.placeholder}
                          required={field.required}
                          className="bat-bis-text-field"
                        />
                      </label>
                    );
                  }
                  if (field.type == "phone") {
                    return (
                      <label
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                      >
                        {field.showLabel && (
                          <span
                            className={`bat-bis-form-label ${
                              field.required && field.showRequired
                                ? "required"
                                : ""
                            }`}
                          >
                            {field.label}
                          </span>
                        )}
                        <input
                          type="number"
                          placeholder={field.placeholder}
                          required={field.required}
                          className="bat-bis-text-field"
                        />
                      </label>
                    );
                  }
                  if (field.type == "text") {
                    return (
                      <label
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                      >
                        {field.showLabel && (
                          <span
                            className={`bat-bis-form-label ${
                              field.required && field.showRequired
                                ? "required"
                                : ""
                            }`}
                          >
                            {field.label}
                          </span>
                        )}
                        <input
                          type="text"
                          placeholder={field.placeholder}
                          required={field.required}
                          className="bat-bis-text-field"
                        />
                      </label>
                    );
                  }
                  if (field.type == "textarea") {
                    return (
                      <label
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                      >
                        {field.showLabel && (
                          <span
                            className={`bat-bis-form-label ${
                              field.required && field.showRequired
                                ? "required"
                                : ""
                            }`}
                          >
                            {field.label}
                          </span>
                        )}
                        <textarea
                          rows={3}
                          placeholder={field.placeholder}
                          required={field.required}
                          className="bat-bis-text-field"
                        ></textarea>
                      </label>
                    );
                  }
                  if (field.type == "select") {
                    return (
                      <label
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                      >
                        {field.showLabel && (
                          <span
                            className={`bat-bis-form-label ${
                              field.required && field.showRequired
                                ? "required"
                                : ""
                            }`}
                          >
                            {field.label}
                          </span>
                        )}
                        <select
                          value={field.defaultSelected}
                          required={field.required}
                          className="bat-bis-text-field"
                        >
                          <option value="">{field.placeholder}</option>
                          {field.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </label>
                    );
                  }
                  if (field.type == "radio") {
                    return (
                      <div
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                      >
                        {field.showLabel && (
                          <span
                            className={`bat-bis-form-label ${
                              field.required && field.showRequired
                                ? "required"
                                : ""
                            }`}
                          >
                            {field.label}
                          </span>
                        )}
                        <div
                          key={fieldIndex}
                          style={{
                            display: "grid",
                            gap: "4px 16px",
                            gridTemplateColumns: getGridTemplateColumns(
                              field.optionsPerLine
                            ),
                          }}
                        >
                          {field.options.map((option, optionIndex) => (
                            <label
                              className="bat-bis-inline-stack bat-bis-items-center bat-bis-gap-200"
                              key={optionIndex}
                            >
                              <input
                                type="radio"
                                value={option}
                                checked={field.defaultSelected == option}
                                required={field.required}
                                className="bat-bis-input-radio"
                              />
                              <span className="bat-bis-form-label">
                                {option}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  if (field.type == "checkbox") {
                    return (
                      <div
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                      >
                        {field.showLabel && (
                          <span
                            className={`bat-bis-form-label ${
                              field.required && field.showRequired
                                ? "required"
                                : ""
                            }`}
                          >
                            {field.label}
                          </span>
                        )}
                        <div
                          key={fieldIndex}
                          style={{
                            display: "grid",
                            gap: "4px 16px",
                            gridTemplateColumns: getGridTemplateColumns(
                              field.optionsPerLine
                            ),
                          }}
                        >
                          {field.options.map((option, optionIndex) => (
                            <label
                              className="bat-bis-inline-stack bat-bis-items-center bat-bis-gap-200"
                              key={optionIndex}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={field.defaultSelected == option}
                                required={field.required}
                                className="bat-bis-input-check"
                              />
                              <span className="bat-bis-form-label">
                                {option}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  if (field.type == "accept-terms") {
                    return (
                      <label
                        key={fieldIndex}
                        className="bat-bis-inline-stack bat-bis-items-center bat-bis-gap-200"
                      >
                        <input
                          type="checkbox"
                          value={field.defaultValue}
                          checked={field.defaultSelected}
                          required={field.required}
                          className="bat-bis-input-check"
                        />
                        <span className="bat-bis-form-label">
                          <StringToHtml htmlString={field.label} />
                        </span>
                      </label>
                    );
                  }
                  if (field.type == "hidden") {
                    return (
                      <label
                        key={fieldIndex}
                        className="bat-bis-block-stack bat-bis-gap-100"
                        style={{ display: "none" }}
                      >
                        <span className="bat-bis-form-label">
                          <StringToHtml htmlString={field.label} />
                        </span>
                        <input type="hidden" value={field.defaultValue} />
                      </label>
                    );
                  }
                })}
                <div className="bat-bis-resource-item">
                  {headerData.headerContent.includes("product-image") && (
                    <div className="bat-bis-resource-item-thumbnail">
                      <img src={product.imgUrl} />
                    </div>
                  )}
                  {headerData.headerContent.includes("product-name") && (
                    <p className="bat-bis-resource-item-name">{product.name}</p>
                  )}
                  {headerData.headerContent.includes("product-price") && (
                    <p className="bat-bis-resource-item-price">
                      ${product.price}
                    </p>
                  )}
                </div>
                <div className="bat-bis-divider"></div>
                <button
                  style={{
                    fontSize: subscribeBtn.fontSize + "px",
                    // fontFamily: subscribeBtn.fontFamily,
                    fontWeight: subscribeBtn.fontWeight,
                    color: subscribeBtn.textColor,
                    backgroundColor: subscribeBtn.backgroundColor,
                    padding: subscribeBtn.padding + "px",
                    borderRadius: subscribeBtn.borderRadius + "px",
                    borderWidth: subscribeBtn.borderWidth + "px",
                    borderColor: subscribeBtn.borderColor,
                  }}
                >
                  {subscribeBtn.buttonText}
                </button>
                {footerData.showFooter && (
                  <p className="bat-bis-text-center bat-bis-text-light bat-bis-body-12">
                    {footerData.footerText}
                  </p>
                )}
                <div className="bat-bis-banner-success">
                  <div className="bat-bis-banner-icon">{checkBannerSvg}</div>
                  <p className="bat-bis-banner-text">
                    Your notification has been successfully registered.
                  </p>
                </div>
                <div className="bat-bis-banner-warning">
                  <div className="bat-bis-banner-icon">{warningBannerSvg}</div>
                  <p className="bat-bis-banner-text">
                    Your email address has been registered for this product.
                  </p>
                </div>
                <div className="bat-bis-created-by">
                  Created by PreOrder Bat
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showOnProductPge == "form" && (
        <>
          {headerData.headerContent.includes("header-graphic") && (
            <img
              src={
                headerData.imgUrl !=""
                  ? headerData.imgUrl
                  : headerImg
              }
              alt="email image"
              className="bat-bis-img-full"
            />
          )}
          <div className="bat-bis-text-center bat-bis-block-stack bat-bis-gap-300">
            {headerData.headerContent.includes("header-title") && (
              <h1 className="bat-bis-heading-16">Email me when available</h1>
            )}
            {headerData.headerContent.includes("header-description") && (
              <p className="bat-bis-body-13 bat-bis-text-light">
                Please enter your email address below to get an email
                notification as soon as this product is available again.
              </p>
            )}
          </div>
          <div className="bat-bis-divider"></div>
          {fields.map((field, fieldIndex) => {
            if (field.type == "email") {
              return (
                <label
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                >
                  {field.showLabel && (
                    <span
                      className={`bat-bis-form-label ${
                        field.required && field.showRequired ? "required" : ""
                      }`}
                    >
                      {field.label}
                    </span>
                  )}
                  <input
                    type="email"
                    placeholder={field.placeholder}
                    required={field.required}
                    className="bat-bis-text-field"
                  />
                </label>
              );
            }
            if (field.type == "phone") {
              return (
                <label
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                >
                  {field.showLabel && (
                    <span
                      className={`bat-bis-form-label ${
                        field.required && field.showRequired ? "required" : ""
                      }`}
                    >
                      {field.label}
                    </span>
                  )}
                  <input
                    type="number"
                    placeholder={field.placeholder}
                    required={field.required}
                    className="bat-bis-text-field"
                  />
                </label>
              );
            }
            if (field.type == "text") {
              return (
                <label
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                >
                  {field.showLabel && (
                    <span
                      className={`bat-bis-form-label ${
                        field.required && field.showRequired ? "required" : ""
                      }`}
                    >
                      {field.label}
                    </span>
                  )}
                  <input
                    type="text"
                    placeholder={field.placeholder}
                    required={field.required}
                    className="bat-bis-text-field"
                  />
                </label>
              );
            }
            if (field.type == "textarea") {
              return (
                <label
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                >
                  {field.showLabel && (
                    <span
                      className={`bat-bis-form-label ${
                        field.required && field.showRequired ? "required" : ""
                      }`}
                    >
                      {field.label}
                    </span>
                  )}
                  <textarea
                    rows={3}
                    placeholder={field.placeholder}
                    required={field.required}
                    className="bat-bis-text-field"
                  ></textarea>
                </label>
              );
            }
            if (field.type == "select") {
              return (
                <label
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                >
                  {field.showLabel && (
                    <span
                      className={`bat-bis-form-label ${
                        field.required && field.showRequired ? "required" : ""
                      }`}
                    >
                      {field.label}
                    </span>
                  )}
                  <select
                    value={field.defaultSelected}
                    required={field.required}
                    className="bat-bis-text-field"
                  >
                    <option value="">{field.placeholder}</option>
                    {field.options.map((option, optionIndex) => (
                      <option key={optionIndex} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </label>
              );
            }
            if (field.type == "radio") {
              return (
                <div
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                >
                  {field.showLabel && (
                    <span
                      className={`bat-bis-form-label ${
                        field.required && field.showRequired ? "required" : ""
                      }`}
                    >
                      {field.label}
                    </span>
                  )}
                  <div
                    key={fieldIndex}
                    style={{
                      display: "grid",
                      gap: "4px 16px",
                      gridTemplateColumns: getGridTemplateColumns(
                        field.optionsPerLine
                      ),
                    }}
                  >
                    {field.options.map((option, optionIndex) => (
                      <label
                        className="bat-bis-inline-stack bat-bis-items-center bat-bis-gap-200"
                        key={optionIndex}
                      >
                        <input
                          type="radio"
                          value={option}
                          checked={field.defaultSelected == option}
                          required={field.required}
                          className="bat-bis-input-radio"
                        />
                        <span className="bat-bis-form-label">{option}</span>
                      </label>
                    ))}
                  </div>
                </div>
              );
            }
            if (field.type == "checkbox") {
              return (
                <div
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                >
                  {field.showLabel && (
                    <span
                      className={`bat-bis-form-label ${
                        field.required && field.showRequired ? "required" : ""
                      }`}
                    >
                      {field.label}
                    </span>
                  )}
                  <div
                    key={fieldIndex}
                    style={{
                      display: "grid",
                      gap: "4px 16px",
                      gridTemplateColumns: getGridTemplateColumns(
                        field.optionsPerLine
                      ),
                    }}
                  >
                    {field.options.map((option, optionIndex) => (
                      <label
                        className="bat-bis-inline-stack bat-bis-items-center bat-bis-gap-200"
                        key={optionIndex}
                      >
                        <input
                          type="checkbox"
                          value={option}
                          checked={field.defaultSelected == option}
                          required={field.required}
                          className="bat-bis-input-check"
                        />
                        <span className="bat-bis-form-label">{option}</span>
                      </label>
                    ))}
                  </div>
                </div>
              );
            }
            if (field.type == "accept-terms") {
              return (
                <label
                  key={fieldIndex}
                  className="bat-bis-inline-stack bat-bis-items-center bat-bis-gap-200"
                >
                  <input
                    type="checkbox"
                    value={field.defaultValue}
                    checked={field.defaultSelected}
                    required={field.required}
                    className="bat-bis-input-check"
                  />
                  <span className="bat-bis-form-label">
                    <StringToHtml htmlString={field.label} />
                  </span>
                </label>
              );
            }
            if (field.type == "hidden") {
              return (
                <label
                  key={fieldIndex}
                  className="bat-bis-block-stack bat-bis-gap-100"
                  style={{ display: "none" }}
                >
                  <span className="bat-bis-form-label">
                    <StringToHtml htmlString={field.label} />
                  </span>
                  <input type="hidden" value={field.defaultValue} />
                </label>
              );
            }
          })}
          <div className="bat-bis-resource-item">
            {headerData.headerContent.includes("product-image") && (
              <div className="bat-bis-resource-item-thumbnail">
                <img src={product.imgUrl} />
              </div>
            )}
            {headerData.headerContent.includes("product-name") && (
              <p className="bat-bis-resource-item-name">{product.name}</p>
            )}
            {headerData.headerContent.includes("product-price") && (
              <p className="bat-bis-resource-item-price">${product.price}</p>
            )}
          </div>
          <div className="bat-bis-divider"></div>
          <button
            style={{
              fontSize: subscribeBtn.fontSize + "px",
              // fontFamily: subscribeBtn.fontFamily,
              fontWeight: subscribeBtn.fontWeight,
              color: subscribeBtn.textColor,
              backgroundColor: subscribeBtn.backgroundColor,
              padding: subscribeBtn.padding + "px",
              borderRadius: subscribeBtn.borderRadius + "px",
              borderWidth: subscribeBtn.borderWidth + "px",
              borderColor: subscribeBtn.borderColor,
            }}
          >
            {subscribeBtn.buttonText}
          </button>
          {footerData.showFooter && (
            <p className="bat-bis-text-center bat-bis-text-light bat-bis-body-12">
              {footerData.footerText}
            </p>
          )}
          <div className="bat-bis-banner-success">
            <div className="bat-bis-banner-icon">{checkBannerSvg}</div>
            <p className="bat-bis-banner-text">
              Your notification has been successfully registered.
            </p>
          </div>
          <div className="bat-bis-banner-warning">
            <div className="bat-bis-banner-icon">{warningBannerSvg}</div>
            <p className="bat-bis-banner-text">
              Your email address has been registered for this product.
            </p>
          </div>
          <div className="bat-bis-created-by">Created by PreOrder Bat</div>
        </>
      )}
    </div>
  );
};

export default Preview;
