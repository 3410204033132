import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Grid,
  InlineGrid,
  InlineStack,
  Page,
  Text,
  Thumbnail,
  Link as PolarisLink,
  BlockStack,
  Badge,
} from "@shopify/polaris";
import { ImageIcon, SettingsFilledIcon, FilterIcon, DeliveryFilledIcon, DnsSettingsIcon} from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { ApiCall, GetApiCall } from "../../../helpers/axios";
import { useSelector } from "react-redux";


const SettingPage = () => {

  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [loading, setLoading] = useState(false);
  const [isEnable, setIsEnable] = useState(shop_data?.back_in_stock_status == 1 ? true : false );
  const updateBackInStock = async () => {
    setLoading(true);
    let is_enable = isEnable === true ? 0 : 1;
    let data = {
      back_in_stock_status: is_enable,
      store_client_id: store_client_id,
    };
    let result = await ApiCall("PUT", "/back_in_stock_status", data, header);
    if (result.data.statusCode === 200) {
      let is_enable = result.data.data.status === 1 ? true : false;
      setIsEnable(is_enable);
      console.log("updatePreOrder ", result.data);
      setLoading(false);
    }
  };

  let navigate = useNavigate();

  let data = [
    {
      title: "Appearance",
      description: "Modify the appearance of the Back-in-stockbutton and message to match your store's theme",
      imgUrl: SettingsFilledIcon,
      url: "/back-in-stock/setting/appearance",
    },
    {
      title: "Conditions",
      description: "Set conditions for when and how Back-in-stock Button and Message should be shown.",
      imgUrl: FilterIcon,
      url: "/back-in-stock/setting/conditions",
    },
    {
      title: "Delivery policy",
      description: "Define delivery policies for products that will be available again.",
      imgUrl: DeliveryFilledIcon,
      url: "/back-in-stock/setting/delivery-policy",
    },
    // {
    //   title: "Campaigns",
    //   description: "Search for products to make available for.",
    //   imgUrl: ImageIcon,
    //   url: "/back-in-stock/setting/campaigns",
    // },
    // {
    //   title: "Activity updates",
    //   description: "Search for products to make available for.",
    //   imgUrl: ImageIcon,
    //   url: "/back-in-stock/setting/activity",
    // },
    // {
    //   title: "Locations",
    //   description: "Search for products to make available for.",
    //   imgUrl: ImageIcon,
    //   url: "/back-in-stock/setting/locations",
    // },
    {
      title: "Additional",
      description: "Manage additional Css for Back-in-stock Button and Message.",
      imgUrl: DnsSettingsIcon,
      url: "/back-in-stock/setting/additional",
    },
  ];

  return (
    <Page>
      <BlockStack gap={"400"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <BlockStack gap={"100"}>
              <Text as="h1" variant="headingSm">
                Status
              </Text>
              <Text as="h1" variant="bodySm">
                Enable or disable back in stock in your app
              </Text>
            </BlockStack>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card padding={"400"} roundedAbove="xs">
              <InlineStack
                align="space-between"
                blockAlign="center"
                gap={"200"}
              >
                <Text as="h1" variant="headingSm">
                  Back in stock is now{" "}
                  {isEnable ? <Badge>enabled</Badge> : <Badge>disabled</Badge>}
                </Text>
                <Button
                  variant={isEnable ? "secondary" : "primary"}
                  onClick={updateBackInStock}
                  loading={loading}
                >
                  {isEnable ? "Disable" : "Enable"}
                </Button>
              </InlineStack>
            </Card>
          </Grid.Cell>
        </Grid>
        <Card roundedAbove="xs" padding={{ xs: "0", sm: "400" }}>
          <InlineGrid
            gap={"300"}
            columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
          >
            {data.map((item, index) => (
              <div
                className="back-in-stock-setting-div"
                onClick={() => navigate(item.url)}
                key={index}
              >
                <InlineStack gap={"400"} wrap={false} blockAlign="center">
                  <Thumbnail source={item.imgUrl} size="small" />
                  <BlockStack gap={"100"}>
                    <Text as="h1" variant="headingSm">
                      <PolarisLink removeUnderline>
                        {item.title}
                      </PolarisLink>
                    </Text>
                    <Text as="h1" variant="bodySm" tone="subdued">
                      {item.description}
                    </Text>
                  </BlockStack>
                </InlineStack>
              </div>
            ))}
          </InlineGrid>
        </Card>
      </BlockStack>
    </Page>
  );
};

export default SettingPage;
