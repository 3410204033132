import { useState, useEffect,  } from "react";
import { Page, ProgressBar, Text, BlockStack, Card, Bleed, Box } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { ApiCall, GetApiCall } from "../helpers/axios";
import { useNavigate } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import GettingStarted from "../components/OnboardingComponents/GettingStarted";
import SelectionMethod from "../components/OnboardingComponents/SelectionMethod";
import ProductSelection from "../components/OnboardingComponents/ProductSelection";
import PricingPlan from "../components/OnboardingComponents/PricingPlan";
import ThemeSetting from "../components/OnboardingComponents/ThemeSetting";
import ProductSelectMethod from "../components/OnboardingComponents/ProductSelectMethod";
import { useRecoilState } from "recoil";
import { methodSelectState , preOrderStatusCodeState , backInStockStatusCodeState , productSelectedStateBtn } from "../store/onboardingAtom";

const NewOnBoarding = () => {
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const shop_name = shop_data.store_name;
  const onb_info_status = shop_data.onb_info_status;
  const app_onb_bnr_status = shop_data.app_onb_bnr_status;
  const onb_status = shop_data.onboarding_status;
  const store_name = shop_data.store_name;
  const plan_type = shop_data.plan_type;
  const store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token  };
  const [onbStatus, setOnbstatus] = useState(0);
  const navigate = useNavigate();
  const [loadingPro, setLoadingPro] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prevStatus, setPrevStatus] = useState(true);
  const [selected, setSelected] = useState(shopinfo.store_theme_id);
  const tabsno = shop_data.show_plan === 1 ? [1, 2, 3, 4, 5] : [1, 2, 3, 4, 5];
  const [selectedMethod , setSelectedMethod] = useRecoilState(methodSelectState);
  const [preOrderStatusCode, setPreOrderStatusCode] = useRecoilState(preOrderStatusCodeState);
  const [backInStockStatusCode, setBackInStockStatusCode] = useRecoilState(backInStockStatusCodeState);
  const [productSelectedBtn, setProductSelectedBtn] = useRecoilState(productSelectedStateBtn);
  // const tabsno = shop_data.show_plan === 1 ? [1, 2, 3, 4] : [1, 2, 4];
  let is_pre_back_both = shop_data?.is_pre_back_both;
  const onb_pro_status = shop_data?.onb_pro_status;

  const [activeTab, setActiveTab] = useState(tabsno[0]);
  const handleTabClick = (item) => {
    setActiveTab(item);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const goNext = async () => {
    if (activeTab == 2 && Number(plan_type) !== 0 && shop_data.show_plan === 1) {
      setTabIndex(tabIndex + 2);
      setActiveTab(activeTab + 2);
      setProgress(progress + 50);
    } else {
      setTabIndex(tabIndex + 1);
      setActiveTab(activeTab + 1);
      setProgress(progress + 25);
    }

    AppEnable(activeTab);
  };

  const AppEnable = async (activeTab) => {
    let data = {
      store_client_id: shop_data.store_client_id,
      shop_data: shop_data,
      onb_status: activeTab,
      is_pre_back_both: (selectedMethod.includes(1) && selectedMethod.includes(2)) ? 2 : (selectedMethod.includes(1) ? 0 : 1)
    };
    let res = await ApiCall("PUT", "/app-enable", data, header);
    if (res?.data?.statusCode === 200) {
      console.log(res?.data);
    }
  };
  const handleStatusCheckAndAction = async (status, backstatus) => {
    window.open(
      `https://${shop_name}/admin/themes/${selected}/editor?context=apps&template=${selected}&activateAppId=6db3227b-acff-4966-bcdd-53204d4488c4/pre-order-block`,
      "_blank"
    );
    setLoadingPro(true);
    setLoading(true);
    const checkStatus = async (retries = 5, delay = 5000) => {
      for (let i = 0; i < retries; i++) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        const block_status = await AppStatus();
        if (block_status !== prevStatus) {
          setLoadingPro(false);
          setPrevStatus(false);
          setLoading(false);
          AppEnable(5);
          window.location.reload(true);
        }
      }
    };

    checkStatus();
  };
  const handleActivateLater = () => {
    AppEnable(5);
    window.location.reload(true);
   
  };

  let getThemes = async (store_client_id) => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "POST",
        `/themes?store_client_id=${store_client_id}&status=onb`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        let all_themes = res?.data?.data?.themes;
        const publishedTheme = all_themes.find(
          (theme) => theme && theme.role === "main"
        );
        setSelected(parseInt(publishedTheme.value));
      }
    }
  };
  const AppStatus = async () => {
    let result = await GetApiCall(
      "GET",
      `/app_block_status_change?store_client_id=${store_client_id}`,
      header
    );
    let block_status = result.data ? result.data.data : false;
    return block_status;
  };

  useEffect(() => {
    setOnbstatus(onb_status);
    getThemes(shop_data.store_client_id);
        if(is_pre_back_both == 0){
          console.log("working is_pre_back_both 0")

          setSelectedMethod([1]);
        }if (is_pre_back_both == 1) {
          console.log("working is_pre_back_both 1")
          setSelectedMethod([2]);
          setProductSelectedBtn(true);
        }if (is_pre_back_both == 2){
          console.log("working is_pre_back_both 2")

          setSelectedMethod([1,2]);
        }
        if (is_pre_back_both == 3){
          console.log("working is_pre_back_both 3")

          setSelectedMethod([1]);
          setPreOrderStatusCode(true);
        }
        if (is_pre_back_both == 4 ){
          console.log("working is_pre_back_both 4")

          setSelectedMethod([2]);
          setBackInStockStatusCode(true);
        }
        if (is_pre_back_both == 5){
          console.log("working is_pre_back_both 5")

          setSelectedMethod([1,2]);
          setPreOrderStatusCode(true);
          setBackInStockStatusCode(true);
        }
        if(onb_pro_status == 1 && (is_pre_back_both == 0 || is_pre_back_both == 3)){
          console.log("working selectedMethod",selectedMethod)
          setPreOrderStatusCode(true);
          setProductSelectedBtn(false);
        }
        if(onb_pro_status == 2 && (is_pre_back_both == 1 || is_pre_back_both == 4 || is_pre_back_both == 5)){
          console.log("working selectedMethod",selectedMethod)
          setBackInStockStatusCode(true);
          setProductSelectedBtn(false);
        }
        if(onb_pro_status == 1 && is_pre_back_both == 2){
          console.log("working onb_pro_status 1",onb_pro_status,is_pre_back_both)
          setPreOrderStatusCode(true);
        }
        if(onb_pro_status == 2 && is_pre_back_both == 2){
          console.log("working onb_pro_status 2",onb_pro_status,is_pre_back_both)
          setProductSelectedBtn(false);
          setPreOrderStatusCode(true);
          setBackInStockStatusCode(true);
        }
        
    if (onb_status === "1") {
      setTabIndex(tabIndex + 1);
      setActiveTab(activeTab + 1);
      setProgress(progress + (shop_data.show_plan === 1 ? 20 : 33.33));
    }
    if (onb_status === "2") {
      setTabIndex(tabIndex + 2);
      setActiveTab(activeTab + 2);
      setProgress(progress + (shop_data.show_plan === 1 ? 40 : 43.33));
    }
    if (onb_status === "3") {
      if (Number(plan_type) !== 0 && shop_data.show_plan === 1) {
        setTabIndex(tabIndex + 3);
        setActiveTab(activeTab + 3);
        
        setProgress(progress + 80);
      } else {
        
        setTabIndex(tabIndex + 3);
        setActiveTab(activeTab + 3);
        setProgress(progress + (shop_data.show_plan === 1 ? 60 : 66.66));
      }
    }
    if (onb_status === "4") {
      setTabIndex(tabIndex + (shop_data.show_plan === 1 ? 3 : 4));
      setActiveTab(activeTab + (shop_data.show_plan === 1 ? 3 : 4));
      setProgress(progress + (shop_data.show_plan === 1 ? 80 : 86.66));
    }
    if (onb_status === "5") {
      setTabIndex(tabIndex + 5);
      setActiveTab(activeTab + 5);
      setProgress(progress + 100);
    }
  }, [shop_name]);

  return (
    <Page fullWidth>
      <Box className="onb-card-style" borderColor="border" borderWidth="025" background="bg-surface" borderRadius="100" padding={0}>
          <Box padding={400} borderColor="border" borderBlockEndWidth="025" >
            <BlockStack gap={"200"}>
              <Text as="h1" variant="headingMd">
                Setup guide
              </Text>
              <div>
                <div>
                  Welcome to Pre-order. Let’s start with select and install theme
                </div>
                <div className="d-flex gap-2">
                  <div className="mt-2 step_width">
                    {tabIndex + 1} of 5 step complete
                  </div>
                  <div className="progress_bar mt-3">
                    <ProgressBar
                      className="Polaris-ProgressBar__Indicator"
                      tone="success"
                      progress={progress}
                      size="small"
                    />
                  </div>
                </div>
              </div>
            </BlockStack>
          </Box>
          <Box>
            <div className="onb_body">
              <div className="d-flex shrink-0">
                <Tabs>
                  <TabList>
                    {tabsno.map((item) => (
                      <li
                        key={item}
                        className={`react-tabs__tab ${
                          activeTab === item && "react-tabs__tab--selected"
                        } ${activeTab > item && "react-tabs__tab--completed"}`}
                        role="tab"
                        aria-selected="true"
                        data-rttab="true"
                        aria-disabled="true"
                      >
                        <svg
                          className="onb_svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10H0ZM15.2 8.2C15.3444 8.00743 15.4145 7.76922 15.3975 7.52911C15.3804 7.289 15.2773 7.0631 15.1071 6.89289C14.9369 6.72268 14.711 6.61958 14.4709 6.60252C14.2308 6.58545 13.9926 6.65557 13.8 6.8L9 11.6L6.7 9.3C6.50743 9.15557 6.26922 9.08545 6.02911 9.10252C5.789 9.11958 5.5631 9.22268 5.39289 9.39289C5.22268 9.5631 5.11958 9.789 5.10252 10.0291C5.08545 10.2692 5.15557 10.5074 5.3 10.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L15.2 8.2Z"
                            fill="#6D7175"
                          ></path>
                        </svg>
                        Step {item}
                      </li>
                    ))}
                  </TabList>
                  {tabIndex == 0 && <GettingStarted handleNext={goNext} />}
                  {tabIndex == 1 && <SelectionMethod  handleNext={goNext} />}
                  {tabIndex == 2 && <ProductSelectMethod handleNext={goNext} />}
                  {tabIndex == 3 && <PricingPlan handleNext={goNext} />}
                  {}
                  {tabIndex == 4 && (
                    <ThemeSetting
                      handleNext={goNext}
                      loadingbtn={loading}
                      activeButton={handleStatusCheckAndAction}
                      activateLater={handleActivateLater}
                    />
                  )}
                </Tabs>
              </div>
            </div>
          </Box>
      </Box>
    </Page>
  );
};

export default NewOnBoarding;
