import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AllProduct from "./AllProducts";
import {
  Page,
  FormLayout,
  Layout,
  TextField,
  Select,
  Checkbox,
  Button,
  Banner,
  RadioButton,
  Toast,
  Text,
  Card,
  InlineStack,
  Box,
} from "@shopify/polaris";
import Footer from "./Footer";
import preorder_img from "../assets/images/preorder_img.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useNavigate } from "react-router-dom";
import { setShopData } from "../redux/commonSlice";

const DefaultSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [settingId, setSettingID] = useState(null);
  const [defaultSett, setDefaultSett] = useState("");
  const [bannerStatusDefualt, setBannerStatusDefualt] = useState("0");
  const [bannerStatus, setBannerStatus] = useState("1");
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  let is_all_product = shop_data.is_all_product == "0" ? false : true;
  const is_free = shop_data?.is_freeplan;

  const placementOptions = [
    { label: "Below Add to Cart Button", value: "1" },
    { label: "Above Add to Cart Button", value: "2" },
  ];
  const ribbonOptions = [
    { label: "Ribbon", value: "0" },
    { label: "Rectangle", value: "1" },
    { label: "Circle", value: "2" },
  ];

  const [initialState, setInitialState] = useState({
    text: "",
    message: "",
    message_placement: "",
    enable_inventary: false,
    method: "1",
    badge_enabled: false,
    badge_shape: 0,
    badge_text: "Ribbon",
    hide_buy_button: false,
    display_badge_homepage: false,
    show_badge_varient: false,
    display_button_homepage: false,
    cart_label_text: "",
    cart_label_key: "",
  });

  const formSchema = Yup.object().shape({
    text: Yup.string().required("Button text is required"),
    cart_label_key: Yup.string().required("Cart label key is required"),
    cart_label_text: Yup.string().required("Cart label text is required"),
    badge_enabled: Yup.boolean(),
    badge_text: Yup.string().when("badge_enabled", {
      is: true,
      then: Yup.string().required("Badge text is required"),
    }),
  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: () => {
      settingSave();
    },
  });

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const closeBannerDefualt = async () => {
    let response = await ApiCall("PUT", "/setting_banner_status", {}, header);
    if (response.data.statusCode === 200) {
      setBannerStatusDefualt(response.data?.data?.setting_banner_status);
    }
  };
  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={3000}
    />
  ) : null;

  const handleSave = async () => {
    setLoading(true);
    let data = {
      shop_info: shop_data,
      is_all_product: checked ? "1" : "0",
    };
    let response = await ApiCall(
      "PUT",
      "/productVariant_bulk_update",
      data,
      header
    );
    if (response.data.statusCode === 200) {
      let shop_Data = Object.assign({}, shop_data);
      shop_Data.is_all_product = checked ? "1" : "0";
      let shopInf = {
        shopInfo: shop_Data,
        auth_token: shop_data.auth_token,
      };
      dispatch(setShopData(shopInf));
      setToastMessage("Update successfully.");
      toggleActiveToast();
      setLoading(false);
    }
  };
  const getStoreData = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/store_data?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        //  console.log(res);
        setBannerStatus(res?.data?.data[0].all_pro_bnr_status);
      }
    }
  };
  const closeBanner = async () => {
    let response = await ApiCall("PUT", "/product_banner_status", {}, header);
    if (response.data.statusCode === 200) {
      setBannerStatus(response.data?.data?.all_pro_bnr_status);
    }
  };

  const settingSave = async () => {
    setLoading(true);
    let data = {
      default_settings: {
        ...defaultSett,
        default_settings_text:
          formik.values.text !== undefined ? formik.values.text : "",
        default_settings_message:
          formik.values.message !== undefined ? formik.values.message : "",
        default_settings_message_placement:
          formik.values.message_placement !== undefined
            ? formik.values.message_placement
            : "",
        default_settings_enable_inventary:
          formik.values.enable_inventary !== undefined
            ? formik.values.enable_inventary
            : "",
        default_settings_method:
          formik.values.method !== undefined ? formik.values.method : "",
        default_settings_badge_enabled:
          formik.values.badge_enabled !== undefined
            ? formik.values.badge_enabled
            : "",
        default_settings_badge_shape:
          formik.values.badge_shape !== undefined
            ? formik.values.badge_shape
            : "",
        default_settings_badge_text:
          formik.values.badge_text !== undefined
            ? formik.values.badge_text
            : "",
        default_settings_hide_buy_button:
          formik.values.hide_buy_button !== undefined
            ? formik.values.hide_buy_button
            : "",
        default_settings_display_badge_homepage:
          formik.values.display_badge_homepage !== undefined
            ? formik.values.display_badge_homepage
            : "",
        default_settings_show_badge_varient:
          formik.values.show_badge_varient !== undefined
            ? formik.values.show_badge_varient
            : "",
        default_settings_display_button_homepage:
          formik.values.display_button_homepage !== undefined
            ? formik.values.display_button_homepage
            : "",
        default_settings_cart_label_text:
          formik.values.cart_label_text !== undefined
            ? formik.values.cart_label_text
            : "",
        default_settings_cart_label_key:
          formik.values.cart_label_key !== undefined
            ? formik.values.cart_label_key
            : "",
      },
    };
    if (settingId !== null) {
      data.id = settingId;
      let res = await ApiCall("PUT", "/settings", data, header);
      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
      }
    } else {
      data.store_client_id = store_client_id;
      let res = await ApiCall("POST", "/settings", data, header);
      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
      }
    }
  };

  const getAllDefaultsetting = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/settings?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        setSettingID(res?.data?.data[0].id);
        let default_settings = JSON.parse(res?.data?.data[0]?.default_settings);
        setDefaultSett(default_settings);
        if (default_settings) {
          formik.setFieldValue("text", default_settings.default_settings_text);
          formik.setFieldValue(
            "message",
            default_settings.default_settings_message
          );
          formik.setFieldValue(
            "message_placement",
            default_settings.default_settings_message_placement
          );
          formik.setFieldValue(
            "enable_inventary",
            default_settings.default_settings_enable_inventary
          );
          formik.setFieldValue(
            "method",
            default_settings.default_settings_method
          );
          formik.setFieldValue(
            "badge_enabled",
            default_settings.default_settings_badge_enabled
          );
          formik.setFieldValue(
            "badge_shape",
            default_settings.default_settings_badge_shape
          );
          formik.setFieldValue(
            "badge_text",
            default_settings.default_settings_badge_text
          );
          formik.setFieldValue(
            "hide_buy_button",
            default_settings.default_settings_hide_buy_button
          );
          formik.setFieldValue(
            "display_badge_homepage",
            default_settings.default_settings_display_badge_homepage
          );
          formik.setFieldValue(
            "show_badge_varient",
            default_settings.default_settings_show_badge_varient
          );
          formik.setFieldValue(
            "display_button_homepage",
            default_settings.default_settings_display_button_homepage
          );
          formik.setFieldValue(
            "cart_label_text",
            default_settings.default_settings_cart_label_text
          );
          formik.setFieldValue(
            "cart_label_key",
            default_settings.default_settings_cart_label_key
          );
        }
      } else if (
        res.data.status === "error" &&
        res.data.message === "Data not found!"
      ) {
        setSettingID(null);
      }
    }
  };
  const handlePrice = () => {
    navigate("/pricingplan");
  };
  useEffect(() => {
    getAllDefaultsetting();
    setBannerStatusDefualt(shop_data.setting_banner_status);
    setChecked(shop_data.is_all_product == "0" ? false : true);
    getStoreData();
  }, [store_client_id, shop_data]);

  return (
    <>
      <Page
        backAction={{
          content: "Settings",
          onAction: () => navigate("/setting"),
        }}
        title={"General Setting"}
      >
        <Layout>
          <Layout.Section>
            {/* {bannerStatusDefualt === "0" && (
              <Banner
                tone="info"
                title="What do 'General Settings' mean?"
                onDismiss={closeBannerDefualt}
              >
                <p>
                  When you turn on pre-order for a product, you can choose
                  different settings for each version or use the defaults below.
                </p>
                <p>
                  If you haven't set up pre-order for a product yet, you can
                  start by using the search form above to set up pre-order for a
                  product.
                </p>
              </Banner>
            )} */}
            {shop_data.plan_type == 0 && Number(is_free) === 0 && (
              <Banner
                title="Please sign up to our 14 days free trial to unlock preorder for more than 1 product"
                status="warning"
                action={{ content: "Upgrade Plan", onClick: handlePrice }}
              ></Banner>
            )}
            {bannerStatus === "0" && (
              <Banner
                tone="info"
                title="Enable / disable pre-order for all products using default settings takes awhile to take effect."
                onDismiss={closeBanner}
              >
                <p className="custom-banner-description">
                  Please refrain to immediately changing status while the system
                  is syncing the product. This is to prevent to have unsync
                  products setting.
                </p>
                <p>
                  Once you enabled or disabled, the save button will be disabled
                  within 10 mins. while settings are syncing.
                </p>
              </Banner>
            )}
          </Layout.Section>
          <Layout.AnnotatedSection title="All products pre-order status">
            <Card>
              <Checkbox
                label="Enable pre-order for all products using default settings"
                checked={checked}
                onChange={handleChange}
              />
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <Box paddingBlock={"300"}>
          <InlineStack align="end">
            {shop_data.plan_type >= 1 || Number(is_free) === 1 ? (
              <Button
                onClick={handleSave}
                disabled={is_all_product == checked ? true : false}
                loading={loading}
                variant="primary"
              >
                Save
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/pricingplan")}
                variant="primary"
              >
                Save
              </Button>
            )}
          </InlineStack>
        </Box>
        <Layout>
          <Layout.AnnotatedSection title="Button settings">
            <Card>
              <FormLayout>
                <TextField
                  name="text"
                  label="Text on button"
                  value={formik.values.text}
                  placeholder="Preorder now"
                  onChange={(value) => {
                    formik.setFieldValue("text", value);
                  }}
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.text && formik.touched.text
                      ? formik.errors.text
                      : ""
                  }
                />
                <TextField
                  label="Pre-order message"
                  value={formik.values.message}
                  placeholder="Preorder message"
                  multiline={4}
                  onChange={(value) => {
                    formik.setFieldValue("message", value);
                  }}
                  autoComplete="off"
                />
                <Select
                  label="Pre-order message places"
                  options={placementOptions}
                  onChange={(value) => {
                    formik.setFieldValue("message_placement", value);
                  }}
                  value={formik.values.message_placement}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Product picture badge">
            <Card>
              <FormLayout>
                <FormLayout.Group condensed>
                  <div className="row">
                    <div className="col-sm-6 product-img-badge">
                      <img src={preorder_img} />
                      {formik.values.badge_enabled ? (
                        <>
                          {formik.values.badge_shape == 0 ? (
                            <div className="preorder-badge-ribbon">
                              <span className="badge_shape">
                                {formik.values.badge_text}
                              </span>
                            </div>
                          ) : formik.values.badge_shape == 1 ? (
                            <div className="preorder-badge-rectangle badge_shape">
                              <span>{formik.values.badge_text}</span>
                            </div>
                          ) : (
                            <div className="preorder-badge-circle badge_shape">
                              <span>{formik.values.badge_text}</span>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-sm-6">
                      <div className="mob-pt-1">
                        <Checkbox
                          name={`badge_enabled`}
                          label={"Badge turned on"}
                          checked={formik.values.badge_enabled}
                          onChange={() =>
                            formik.setFieldValue(
                              "badge_enabled",
                              !formik.values.badge_enabled
                            )
                          }
                        />
                      </div>
                      <div className="pt-2">
                        <Select
                          label={"Badge shape"}
                          options={ribbonOptions}
                          onChange={(value) => {
                            formik.setFieldValue("badge_shape", value);
                          }}
                          value={formik.values.badge_shape}
                          disabled={!formik.values.badge_enabled}
                        />
                        <div className="pt-3 mob-pt-1">
                          <TextField
                            name="badge_text"
                            label={"Badge text"}
                            value={formik.values.badge_text}
                            placeholder="Badge text"
                            maxLength={15}
                            onChange={(value) => {
                              formik.setFieldValue("badge_text", value);
                            }}
                            autoComplete="off"
                            disabled={!formik.values.badge_enabled}
                            onBlur={formik.handleBlur}
                            error={
                              formik.errors.badge_text &&
                              formik.touched.badge_text
                                ? formik.errors.badge_text
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FormLayout.Group>
              </FormLayout>
              <div className="row pt-3">
                <p>
                  <strong>Badge doesn't show up?</strong>
                  For this feature to work with your theme, it may need to be
                  set up in a certain way. Contact support if you need help.
                </p>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Stock">
            <Card>
              <FormLayout>
                <FormLayout.Group condensed>
                  <Checkbox
                    label={"Make it possible to control stock"}
                    checked={formik.values.enable_inventary}
                    onChange={() =>
                      formik.setFieldValue(
                        "enable_inventary",
                        !formik.values.enable_inventary
                      )
                    }
                  />
                </FormLayout.Group>
              </FormLayout>
              {formik.values.enable_inventary && (
                <>
                  
                  <div>
                    <div className="pb-2">
                      <RadioButton
                        label={
                          <div>
                            <strong>Type 1:&nbsp;</strong>
                            <span>
                            Quantity greater than 0.
                            </span>
                          </div>
                        }
                        checked={formik.values.method === 0}
                        id="stock1"
                        onChange={(_checked, newValue) => {
                          formik.setFieldValue("method", 0);
                        }}
                      />
                    </div>
                    <div className="py-2">
                      <RadioButton
                        label={
                          <div>
                            <strong>Type 2:&nbsp;</strong>
                            <span>
                            Quantity less than or equal to 0.
                            </span>
                          </div>
                        }
                        checked={formik.values.method === 1}
                        id="stock2"
                        onChange={(_checked, newValue) =>
                          formik.setFieldValue("method", 1)
                        }
                      />
                    </div>
                  </div>
                  <div className="stock-note">
                    <p>
                      <strong>Note:&nbsp;</strong>Enable "Continue When Selling Out of Stock" In Shopify Products For Type 2 Settings.
                    </p>
                  </div>
                </>
              )}
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Product page">
            <Card>
              <Checkbox
                label='Hide the "buy now" buttons when the current version is on pre-order.'
                checked={formik.values.hide_buy_button}
                onChange={() =>
                  formik.setFieldValue(
                    "hide_buy_button",
                    !formik.values.hide_buy_button
                  )
                }
              />
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Collection pages">
            <Card>
              <FormLayout>
                <Checkbox
                  label="Put badges on the home page and pages for each group."
                  checked={formik.values.display_badge_homepage}
                  onChange={() =>
                    formik.setFieldValue(
                      "display_badge_homepage",
                      !formik.values.display_badge_homepage
                    )
                  }
                />
                <Checkbox
                  label="Only show the badge if all versions are available for pre-order"
                  checked={formik.values.show_badge_varient}
                  onChange={() =>
                    formik.setFieldValue(
                      "show_badge_varient",
                      !formik.values.show_badge_varient
                    )
                  }
                />
                <Checkbox
                  label="Put pre-order buttons on the homepage and the pages for each group"
                  checked={formik.values.display_button_homepage}
                  onChange={() =>
                    formik.setFieldValue(
                      "display_button_homepage",
                      !formik.values.display_button_homepage
                    )
                  }
                />
                <Text variant="headingSm" as="h1">
                  <p>
                    <strong>Not seeing a badge or button? &nbsp;</strong>
                    This is a function that is being tested, so it might not
                    work with all themes. Please contact support if you need
                    help setting up for free.
                  </p>
                </Text>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Cart label">
            <Card>
              <FormLayout>
                <FormLayout.Group condensed>
                  <TextField
                    name="cart_label_key"
                    label="Cart label key"
                    value={formik.values.cart_label_key}
                    placeholder=""
                    onChange={(value) => {
                      formik.setFieldValue("cart_label_key", value);
                    }}
                    autoComplete="off"
                    error={
                      formik.errors.cart_label_key &&
                      formik.touched.cart_label_key
                        ? formik.errors.cart_label_key
                        : ""
                    }
                  />
                  <TextField
                    name="cart_label_text"
                    label={"Cart label"}
                    value={formik.values.cart_label_text}
                    placeholder=""
                    onChange={(value) => {
                      formik.setFieldValue("cart_label_text", value);
                    }}
                    autoComplete="off"
                    error={
                      formik.errors.cart_label_text &&
                      formik.touched.cart_label_text
                        ? formik.errors.cart_label_text
                        : ""
                    }
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <Box paddingBlockStart={"300"}>
          <InlineStack align="end">
            <Button
              variant="primary"
              onClick={formik.handleSubmit}
              loading={loading}
            >
              Save
            </Button>
          </InlineStack>
        </Box>
        <Footer />
        {toastMarkup}
      </Page>
    </>
  );
};

export default DefaultSetting;
