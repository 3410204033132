import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Box,
  Button,
  InlineStack,
  Page,
  BlockStack,
  InlineGrid,
  ChoiceList,
} from "@shopify/polaris";
import HeaderAppearance from "../components/back-in-stock/setting-page/appearance/HeaderAppearance";
import ButtonStyles from "../components/back-in-stock/setting-page/appearance/ButtonStyles";
import SubscribeButtonStyles from "../components/back-in-stock/setting-page/appearance/SubscribeButtonStyles";
import Footer from "../components/back-in-stock/setting-page/appearance/Footer";
import FieldsSetting from "../components/back-in-stock/setting-page/appearance/FieldsSetting";
import { useRecoilState } from "recoil";
import {
  appearanceEmailButtonState,
  appearanceFieldsState,
  appearanceFooterState,
  appearanceHeaderState,
  appearanceSubscribeButtonState,
} from "../store/appearanceSettingAtom";
import Preview from "../components/back-in-stock/setting-page/appearance/Preview";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";

const BackInStock_setting_appearance = () => {
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token, Authorization: shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };


  let [showOnProductPge, setShowOnProductPge] = useState(["button_&_popup"]);
  let [showOnCollectionPge, setShowOnCollectionPge] = useState(["hide"]);
  let [accord, setAccord] = useState("");

  let toggleAccord = (name) => {
    if (accord == name) {
      setAccord("");
    } else {
      setAccord(name);
    }
  };

  // all data
  const [headerData, setHeaderData] = useRecoilState(appearanceHeaderState);
  const [emailButtonStyle, setEmailButtonStyle] = useRecoilState(appearanceEmailButtonState);
  const [subscribeButtonStyle, setSubscribeButtonStyle] = useRecoilState(appearanceSubscribeButtonState);
  const [fields, setFields] = useRecoilState(appearanceFieldsState);
  const [footerData, setFooterData] = useRecoilState(appearanceFooterState);
  let handleSubmit = async () => {
    setLoading(true);

    let allData = {
      other: {
        showOnProductPge: showOnProductPge[0],
        showOnCollectionPge: showOnCollectionPge[0],
      },
      header: headerData,
      emailButtonStyle,
      subscribeButtonStyle,
      fields,
      footer: footerData,
    };
    let data_back_in_stock = {
      appearance: allData,
      id: settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
      console.log(res);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    console.log(allData);
  };
  useEffect(() => {
    getBackInStockSettings();
  }, [])
  const getBackInStockSettings = async () => {

    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if (result?.data?.statusCode === 200 && result?.data?.status === "success") {
      let appearanceData = result?.data?.data?.appearance;
      setSettingID(result?.data?.data?.id);
      // Validate appearanceData before parsing
      if (typeof appearanceData === "string" && appearanceData.trim() !== "") {
        try {
          appearanceData = JSON.parse(appearanceData);
        } catch (error) {
          console.error("Failed to parse appearanceData:", error, "Original Data:", appearanceData);
          appearanceData = {}; // Default to an empty object or fallback data
        }
      } else if (typeof appearanceData !== "object" || appearanceData === null) {
        console.warn("appearanceData is not a valid JSON string or object:", appearanceData);
        appearanceData = {}; // Ensure it's a valid object
      }
      console.log("appearanceData", appearanceData)
      if (appearanceData) {
        // Safely set states with fallback values
        setShowOnProductPge([appearanceData?.other?.showOnProductPge || "button_&_popup"]);
        setShowOnCollectionPge([appearanceData?.other?.showOnCollectionPge || "hide"]);

        console.log("Setting Header Data:", appearanceData?.header);
        setHeaderData({...headerData , ...appearanceData?.header });

        console.log("Setting Email Button Style:", appearanceData?.emailButtonStyle);
        setEmailButtonStyle(appearanceData?.emailButtonStyle || {});

        console.log("Setting Subscribe Button Style:", appearanceData?.subscribeButtonStyle);
        setSubscribeButtonStyle(appearanceData?.subscribeButtonStyle || {});

        console.log("Setting footer:", appearanceData?.footer);
        setFields(appearanceData?.fields || [])
        setFooterData(appearanceData?.footer || { showFooter: false, footerText: "" });


      }

      // Set the setting ID
    }


  };
  return (
    <Page title="Appearance" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <InlineGrid
          gap={"400"}
          alignItems="start"
          columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
        >
          <div className="order-last order-md-first">
            <BlockStack gap={"400"}>
              <Card roundedAbove="xs">
                <BlockStack gap={"400"}>
                  <ChoiceList
                    title={
                      <h1 className="back-in-stock-right-card-title-style">Show on product detail page</h1>
                    }
                    choices={[
                      {
                        label: "Show button and popup",
                        value: "button_&_popup",
                      },
                      {
                        label: "Show floating button",
                        value: "floating_button",
                      },
                      { label: "Show from", value: "form" },
                      { label: "Hidden", value: "hide" },
                    ]}
                    selected={showOnProductPge}
                    onChange={setShowOnProductPge}
                  />
                  <ChoiceList
                    title={<h1 className="back-in-stock-right-card-title-style">Show on collection page</h1>}
                    choices={[
                      {
                        label: "Show button and popup",
                        value: "button_&_popup",
                      },
                      { label: "Hidden", value: "hide" },
                    ]}
                    selected={showOnCollectionPge}
                    onChange={setShowOnCollectionPge}
                  />
                </BlockStack>
              </Card>
              <HeaderAppearance
                isOpen={accord == "header"}
                toggle={() => toggleAccord("header")}
              />
              <ButtonStyles
                isOpen={accord == "button1"}
                toggle={() => toggleAccord("button1")}
              />
              <SubscribeButtonStyles
                isOpen={accord == "button2"}
                toggle={() => toggleAccord("button2")}
              />
              <FieldsSetting
                isOpen={accord == "fields"}
                toggle={() => toggleAccord("fields")}
              />
              <Footer
                isOpen={accord == "footer"}
                toggle={() => toggleAccord("footer")}
              />
            </BlockStack>
          </div>
          <Box
            padding={"400"}
            background="bg-surface"
            borderRadius="300"
            shadow="100"
            borderWidth="025"
            borderColor="border"
            zIndex="1"
          >
            <Preview showOnProductPge={showOnProductPge[0]} />
          </Box>
        </InlineGrid>
        <div style={{marginBottom: "12px"}}>
        <InlineStack align="end">
          <Button loading={loading} onClick={handleSubmit} variant="primary">
            Save
          </Button>
        </InlineStack>
        </div>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_appearance;
