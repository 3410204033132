import { useEffect, useState } from "react";

import {
  Icon,
  IndexTable,
  Page,
  TextField,
  AppProvider,
  Text,
  EmptySearchResult,
  Spinner,
  Grid,
  Pagination,
  DataTable,
  Card,
  BlockStack,
  Box,
  InlineStack,
  Button,
  ButtonGroup,
  InlineGrid,
  Badge,
} from "@shopify/polaris";
import CustomHeader from "../components/CustomHeader";
import {
  SearchIcon,
  CashDollarIcon,
  ClipboardChecklistIcon,
  CheckIcon,
  ChatIcon,
  DeleteIcon,
  NoteAddIcon,
  ShieldCheckMarkIcon,
  DataPresentationIcon,
} from "@shopify/polaris-icons";
import { useSelector, useDispatch } from "react-redux";
import { GetApiCall } from "../helpers/axios";
import { useNavigate } from "react-router-dom";
import ApplicationStatus from "../components/ApplicationStatus";
import { LazyLoadImage } from "react-lazy-load-image-component";
import noimg from "../assets/images/noimg.jpg";
import Footer from "./Footer";
import { TitleBar } from "@shopify/app-bridge-react";
import { LineChart } from "@shopify/polaris-viz";
import { Crisp } from "crisp-sdk-web";

const Dashboard = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };
  let store_name = shop_data?.store_name;
  const [productData, setProductData] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [bestSeller, setBestSeller] = useState([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [loader, setLoader] = useState(false);
  const [revenueLoader, setRevenueLoader] = useState(false);
  const [totalpage, settotalPage] = useState(1);
  const [currpage, setCurrPage] = useState(1);
  const [pre_order_status, setPreOrderStatus] = useState(shop_data?.pre_order_status);
  const [back_in_stock_status, setBackInStockStatus] = useState(shop_data?.back_in_stock_status);
  const [app_status, setAppStatus] = useState(shop_data?.app_status);
  let shop_currency = shop_data?.currency;
  const preOrderSellerList = async () => {
    if (Object.keys(shop_data).length > 0) {
      setLoader(true);
      setRevenueLoader(true);
      let res = await GetApiCall(
        "GET",
        `/dashboardSellerget?store_client_id=${shop_data.store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        const total_rev =
          res?.data?.data?.total_revenue !== "" &&
          res?.data?.data?.total_revenue !== null
            ? res?.data?.data?.total_revenue
            : 0;
        setBestSeller(res?.data?.data?.best_seller);
        setTotalProduct(res?.data?.data?.total_product);
        setTotalOrder(res?.data?.data?.total_order);
        setTotalRevenue(total_rev);
        setRevenueLoader(false);
        setLoader(false);
        setMonthlyRevenue(res?.data?.data?.monthly_revenue);
      } else {
        setBestSeller([]);
        setTotalProduct(0);
        setTotalOrder(0);
        setTotalRevenue(0);
        setRevenueLoader(false);
        setMonthlyRevenue([]);
      }
    }
  };
  console.log("shop_data", shop_data, pre_order_status, back_in_stock_status, app_status);
  useEffect(() => {
    preOrderSellerList();
  }, [shop_data]);

  const handleIntercomClick = (e) => {
    e.preventDefault();
    Crisp.chat.open();
  };
  const handleSupportClick = (e) => {
    window.open("https://calendly.com/m-usman-shopcommerce", "_blank");
  };
  const preOrderProductList = async (page, searchValue) => {
    if (Object.keys(shop_data).length > 0) {
      setLoader(true);
      const rows = [];
      page = page ? page : 1;
      let searchval = searchValue ? "&search_val=" + searchValue : "";
      let res = await GetApiCall(
        "GET",
        `/getdashordProduct?store_client_id=${shop_data.store_client_id}&page_no=${page}${searchval}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        setLoader(false);
        settotalPage(res?.data?.data?.total_page);
        setCurrPage(page);
        const productData = res?.data?.data?.products;
        if (productData.length > 0) {
          productData.map(({ id, img, title, pre_order_type }) => {
            rows.push([
              <div className="d-flex align-items-center">
                {/* <img width={"40px"} height={"40px"} src={img ? `${img}` : `${noimg}`} /> */}
                <LazyLoadImage
                  src={img ? img : noimg}
                  alt={title}
                  width="40"
                  height="40"
                  effect="blur"
                />
                <div className="px-3">{title}</div>
              </div>,

              <div className="order_type">{pre_order_type}</div>,
            ]);
          });
        } else {
          rows.push([<div className="no-data-found">No data found.</div>]);
        }
        setProductData(rows);
      } else {
        rows.push([<div className="no-data-found">No data found.</div>]);
        setProductData(rows);
        settotalPage(1);
        setCurrPage(1);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (searchValue.length >= 3) {
      preOrderProductList(currpage, searchValue);
    } else {
      preOrderProductList(currpage, "");
    }
  }, [searchValue, shop_data]);

  const resourceName = {
    singular: "customer",
    plural: "customers",
  };

  const sellerMarkup =
    bestSeller.length > 0 &&
    bestSeller.map(({ id, name, quantity, revenue }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{quantity}</IndexTable.Cell>
        <IndexTable.Cell>
          {shop_currency}
          {Number.parseFloat(revenue).toFixed(2)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const emptyStateMarkup = (
    <EmptySearchResult
      title={"No products yet"}
      description={"Try changing the filters or search term"}
      withIllustration
    />
  );

  const Methods = [
    {
      title: "Application Status",
      status: app_status == 1 ? "Active" : "Inactive",
      detail: "You're all set and ready to go! Explore detailed reports, gain valuable insights, and optimize your campaigns for better performance",
      primary: "Theme Setting",
      onClick: () => {
        window.open("https://" + store_name + "/admin/themes/current/editor?context=apps&activateAppId=6db3227b-acff-4966-bcdd-53204d4488c4/pre-order-block", "_blank");
      }
    },
    {
      title: "Pre-order",
      status: pre_order_status == 1 ? "Active" : "Inactive",
      detail:
        "You're all set and ready to go! Explore detailed reports, gain valuable insights, and optimize your campaigns for better performance.",
      primary: "Pre-order Setting",
      onClick: () => {
        navigate("/setting");
      }
    },
    {
      title: "Back-in-stock",
      status: back_in_stock_status == 1 ? "Active" : "Inactive",
      detail:
        "You're all set and ready to go! Explore detailed reports, gain valuable insights, and optimize your campaigns for better performance.",
      primary: "Back-in-stock Setting",
      onClick: () => {
        navigate("/back-in-stock");
      }
    },
  ];
  const tabs = [
    {
      type: "pre-order",
      title: "Revenue Pre-order",
      amount: `${shop_currency} ${totalRevenue}`,
    },
    {
      type: "back-in-stock",
      title: "Revenue Back-in-stock",
      amount: "Comming Soon",
    },
  ];
  const [activeTab, setActiveTab] = useState("pre-order");
  const handleTabToggle = (tab) => {
    setActiveTab(tab);
  };

  const preOrderData = [
    {
      name: "Sales",
      data: monthlyRevenue,
    },
  ];
  const backInStockData = [
    {
      name: "Sales",
      data: [
        { key: "Jan", value: 500 },
        { key: "Feb", value: 800 },
        { key: "Mar", value: 400 },
        { key: "Apr", value: 200 },
      ],
    },
  ];

  const threeSupportChannels = [
    {
      Icon: ChatIcon,
      Title: "Contact Support",
      Detail:
        "Our customer support team is here to assist you with any questions, issues, or guidance you need.",
    },
    {
      Icon: NoteAddIcon,
      Title: "Feature requests",
      Detail:
        "Have an idea for improvement? Submit a feature request and help us enhance your experience!",
    },
    {
      Icon: DeleteIcon,
      Title: "Remove Branding",
      Detail:
        "Customize your experience by removing branding for a clean, professional look.",
    },
  ];

  const twoSupportChannels = [
    {
      Icon: DataPresentationIcon,
      Title: "Book a live demo",
      Detail:
        "Experience our product in action! Book a live demo and see how it works for you.",
    },
    {
      Icon: ShieldCheckMarkIcon,
      Title: " Verify your account",
      Detail:
        "Secure your access! Verify your account to unlock all features and ensure account security.",
    },
  ];

  return (
    <>
      {window.location.hostname === "localhost" ||
      window.location.href.indexOf("ngrok") > 1 ? (
        <CustomHeader
          Header="Dashboard"
          ButtonName="Support/FAQ"
          Pricingbtn="Upgrade plan"
        />
      ) : (
        ""
      )}
      {/* {app_status == "0" && (
        <Page>
          <div className="pre-setting-layout">
            <ApplicationStatus header={header} shop_data={shopinfo} />
          </div>
        </Page>
      )} */}
      {window.location.hostname !== "localhost" && (
        <>
          <AppProvider>
            {/* <AppBridgeProvider config={config}> */}
            <TitleBar title="Dashboard" />
            <Page>
             

              <BlockStack gap="400">
                <BlockStack gap={400}>
                  <BlockStack>
                    <h2 className="heading-size-16">App Status</h2>
                  </BlockStack>
                  <InlineGrid columns={{ sm: "1", md: "3" }} gap={400}>
                    {Methods.map((method, index) => (
                      <Card>
                        <BlockStack gap={300}>
                          <InlineStack gap={200}>
                            <Text as="h2" variant="headingMd">
                              {method.title}
                            </Text>
                            <Badge tone={method.status === "Active" ? "success-strong" : "error-strong"}>{method.status}</Badge>
                          </InlineStack>
                          <Text tone="subdued" as="p">
                            {method.detail}
                          </Text>
                          <InlineStack gap={200}>
                            <Button onClick={method.onClick} variant="primary">{method.primary}</Button>
                            {/* <Button>{method.secondary}</Button> */}
                          </InlineStack>
                        </BlockStack>
                      </Card>
                    ))}
                  </InlineGrid>
                </BlockStack>
                <h2 className="heading-size-16">Revenue</h2>
                <Card>
                    <BlockStack gap="400">
                      <InlineGrid gap={400} columns={{ sm: "2" }}>
                        {tabs.map((tab, index) => (
                          <div
                            className={`${
                              activeTab === tab.type
                                ? "active-revenue-tabs"
                                : "inactive-revenue-tabs"
                            }`}
                            onClick={() => handleTabToggle(tab.type)}
                            key={index}
                          >
                            <InlineStack align="space-between" gap={200}>
                              <Text as="h2" variant="headingMd">
                                {tab.title}
                              </Text>
                              <Text variant="headingMd" as="p">
                                {tab.amount}
                              </Text>
                            </InlineStack>
                          </div>
                        ))}
                      </InlineGrid>
                      {/* {activeTab === "pre-order" && (
                          <div style={{ width: "100%", height: "300px" }}>
                            <LineChart data={preOrderData} />
                          </div>
                        )}
                        {activeTab === "back-in-stock" && (
                          <div style={{ width: "100%", height: "300px" }}>
                            <LineChart data={backInStockData} />
                          </div>
                        )} */}
                    </BlockStack>
                  </Card>
                <BlockStack gap={400}>
                  <h2 className="heading-size-16">Support Channels</h2>
                  <Card padding={0}>
                    <InlineGrid columns={{ sm: "1", md: "3" }}>
                      {threeSupportChannels.map((item) => (
                        <Box
                          key={item}
                          paddingBlock={400}
                          paddingInline={"600"}
                          borderInlineEndWidth={"025"}
                          borderBlockEndWidth={"025"}
                          borderColor="border"
                          onClick={handleIntercomClick}
                          cursor="pointer"
                        >
                          <BlockStack gap={"300"} align="center">
                            <BlockStack gap={"200"} align="center">
                              <Icon source={item.Icon} tone="base" />
                              <Text alignment="center" variant="headingMd">
                                {item.Title}
                              </Text>
                            </BlockStack>
                            <Text alignment="center" variant="bodySm">
                              {item.Detail}
                            </Text>
                          </BlockStack>
                        </Box>
                      ))}
                    </InlineGrid>
                  </Card>
                  <Card padding={0}>
                    <InlineGrid columns={{ sm: "1", md: "2" }}>
                      {twoSupportChannels.map((item) => (
                        <Box
                          key={item}
                          paddingBlock={400}
                          paddingInline={"600"}
                          borderInlineEndWidth={"025"}
                          borderBlockEndWidth={"025"}
                          borderColor="border"
                          onClick={handleSupportClick}
                          cursor="pointer"
                        >
                          <BlockStack gap={"300"} align="center">
                            <BlockStack gap={"200"} align="center">
                              <Icon source={item.Icon} tone="base" />
                              <Text alignment="center" variant="headingMd">
                                {item.Title}
                              </Text>
                            </BlockStack>
                            <Text alignment="center" variant="bodySm">
                              {item.Detail}
                            </Text>
                          </BlockStack>
                        </Box>
                      ))}
                    </InlineGrid>
                  </Card>
                </BlockStack>
                {/* <Grid>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                    <Card>
                      <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                        <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                          <h2 className="Polaris-Text--root Polaris-Text--headingMd">
                            Items from orders
                          </h2>
                        </div>
                        <div className="Polaris-LegacyStack__Item">
                          <div className="Polaris-ButtonGroup">
                            <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                              <Icon source={CheckIcon} color="base"></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      {revenueLoader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="small"
                        />
                      ) : (
                        <div className="pt-3">
                          <b>{totalProduct}</b>
                        </div>
                      )}
                    </Card>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                    <Card>
                      <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                        <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                          <h2 className="Polaris-Text--root Polaris-Text--headingMd">
                            Placed orders
                          </h2>
                        </div>
                        <div className="Polaris-LegacyStack__Item">
                          <div className="Polaris-ButtonGroup">
                            <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                              <Icon
                                source={ClipboardChecklistIcon}
                                color="base"
                              ></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      {revenueLoader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="small"
                        />
                      ) : (
                        <div className="pt-3">
                          <b>{totalOrder}</b>
                        </div>
                      )}
                    </Card>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
                    <Card>
                      <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                        <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                          <h2 className="Polaris-Text--root Polaris-Text--headingMd">
                            Generated revenue
                          </h2>
                        </div>
                        <div className="Polaris-LegacyStack__Item">
                          <div className="Polaris-ButtonGroup">
                            <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                              <Icon source={CashDollarIcon} color="base"></Icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      {revenueLoader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="small"
                        />
                      ) : (
                        <div className="pt-3">
                          <b>
                            {shop_currency}{" "}
                            {Number.parseFloat(totalRevenue).toFixed(2)}
                          </b>
                        </div>
                      )}
                    </Card>
                  </Grid.Cell>
                </Grid> */}
                {/* <Card>
                  <BlockStack gap={"200"}>
                    <Text as="h1" variant="headingSm">
                      Purchased products list
                    </Text>
                    <TextField
                      key={`search`}
                      prefix={<Icon source={SearchIcon} color="base" />}
                      value={searchValue}
                      onChange={(value) => {
                        setSearchValue(value);
                      }}
                      clearButton
                      onClearButtonClick={() => setSearchValue("")}
                      autoComplete="off"
                    />
                    <div className="pt-3 pre-product-list">
                      {loader ? (
                        <Spinner
                          accessibilityLabel="Spinner example"
                          size="large"
                        />
                      ) : (
                        <DataTable
                          columnContentTypes={["text", "text"]}
                          headings={[
                            <div className="preorder_pro_heading">Product</div>,

                            <div className="preorder_pro_heading">
                              Pre-order setting type
                            </div>,
                          ]}
                          rows={productData}
                        />
                      )}
                      <div className="single-product-pagination">
                        {totalpage > 1 ? (
                          <Pagination
                            hasPrevious={
                              currpage === 1 || currpage > totalpage
                                ? false
                                : true
                            }
                            previousTooltip="Previous"
                            onPrevious={() => {
                              preOrderProductList(currpage - 1, searchValue);
                            }}
                            hasNext={totalpage === currpage ? false : true}
                            nextTooltip="Next"
                            onNext={() => {
                              preOrderProductList(currpage + 1, searchValue);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </BlockStack>
                </Card>
                <Card>
                  <BlockStack gap={"200"}>
                    <Text as="h1" variant="headingSm">
                      Best selling products
                    </Text>
                    <div className="best_seller">
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={bestSeller.length}
                        emptyState={emptyStateMarkup}
                        selectable={false}
                        headings={[
                          { title: "Name" },
                          { title: "Quantity" },
                          { title: "Revenue" },
                        ]}
                      >
                        {sellerMarkup}
                      </IndexTable>
                    </div>
                  </BlockStack>
                </Card> */}
              </BlockStack>
              <Footer />
            </Page>
            {/* </AppBridgeProvider> */}
          </AppProvider>
        </>
      )}
    </>
  );
};

export default Dashboard;
