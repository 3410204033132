import { useState } from "react";
import { InlineStack, Tabs } from "@shopify/polaris";
import SubmissionPage from "../components/back-in-stock/submission-page";
import SubscribedProductPage from "../components/back-in-stock/subscribed-product";
import SettingPage from "../components/back-in-stock/setting-page";
import { useRecoilState } from "recoil";
import { currentTabState } from "../store/backinstockGlobalAtom";

const BackInStock = () => {
  const [selectedTab, setSelectedTab] = useRecoilState(currentTabState);

  const tabs = [
    {
      id: "Setting",
      content: "Setting",
    },
    {
      id: "Submission",
      content: "Submission",
    },
    {
      id: "Subscribe product",
      content: "Subscribe product",
    },
  ];

  return (
    <div>
      <InlineStack align="center">
        <Tabs
          tabs={tabs}
          selected={selectedTab}
          onSelect={setSelectedTab}
          fitted
        />
      </InlineStack>
      {selectedTab == 0 && <SettingPage />}
      {selectedTab == 1 && <SubmissionPage />}
      {selectedTab == 2 && <SubscribedProductPage />}
    </div>
  );
};

export default BackInStock;
