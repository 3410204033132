import React from "react";
import { BrowserRouter as AppRouter, Route, Routes } from "react-router-dom";
import Header from "../pages/Header";
import EditSingleProduct from "../pages/EditSingleProduct";
import AddTag from "../pages/AddTag";
import Dashboard from "../pages/Dashboard";
import SingleProduct from "../pages/SingleProduct";
import SingleProductSetting from "../pages/SingleProductSetting";
import StylesSetting from "../pages/StylesSetting";
import Tags from "../pages/Tags";
import AllProducts from "../pages/AllProducts";
import InstallUninstall from "../pages/InstallUninstall";
import NewOnBoarding from "../pages/new-onboarding";
import PricingPlan from "../components/OnboardingComponents/PricingPlan";
import PricingPlanMain from "../components/OnboardingComponents/PricingPlanMain";
import AdvancedSetting from "../pages/AdvancedSetting";
import Setting from "../pages/Setting";
// import PricingPlan from "../pages/PricingPlan";
import PageNotFound from "../pages/404/PageNotFound";
import DefaultSetting from "../pages/DefaultSetting";
import PreOrderEmail from "../pages/PreOrderEmail";
import BackInStock from "../pages/BackInStock";
import AddSubscriber from "../pages/AddSubscriber";
import OutOfStock from "../pages/OutOfStock";
import SubmissionDetail from "../pages/SubmissionDetail";
import BackInStock_setting_additional from "../pages/BackInStock_setting_additional";
import BackInStock_setting_location from "../pages/BackInStock_setting_location";
import BackInStock_setting_activity from "../pages/BackInStock_setting_activity";
import BackInStock_setting_delivery_policy from "../pages/BackInStock_setting_delivery_policy";
import BackInStock_setting_campaigns from "../pages/BackInStock_setting_campaigns";
import BackInStock_setting_conditions from "../pages/BackInStock_setting_conditions";
import BackInStock_setting_appearance from "../pages/BackInStock_setting_appearance";

const Router = (data) => {
  // let newinstall =false;
  // const currentDate = new Date(2024, 9, 2);
  // const installDate = new Date(data.shop.install_date);
  // if(installDate.getTime() > currentDate.getTime()){
  //   newinstall  =true;
  // }
  // console.log(newinstall)

  return (
    <AppRouter>
      {window.location.hostname === "localhost" ? <Header /> : ""}
      <Routes>
        {data.onBoardingStatus === 5 ? (
          <Route path={"/"} exact={true} element={<Dashboard />} />
        ) : (
          <Route path={"/"} exact={true} element={<NewOnBoarding />} />
        )}
        <Route path={"/single-product"} element={<SingleProduct />} />
        <Route path={"/analytics"} exact={true} element={<Dashboard />} />
        <Route
          path={"/single-product/setting/:product_id/:type/:variant_id"}
          exact={true}
          element={<SingleProductSetting />}
        />
        <Route
          path={"/new-onboarding"}
          exact={true}
          element={<NewOnBoarding />}
        />
        <Route path={"/tags"} exact={true} element={<Tags />} />
        <Route path={"/addTag"} exact={true} element={<AddTag />} />
        <Route path={"/tags/edit/:id"} exact={true} element={<AddTag />} />
        <Route path={"/all-products"} exact={true} element={<AllProducts />} />
        <Route
          path={"/install-uninstall"}
          exact={true}
          element={<InstallUninstall />}
        />
        <Route
          path={"/advanced-setting"}
          exact={true}
          element={<AdvancedSetting />}
        />
        <Route path={"/setting"} exact={true} element={<Setting />} />
        <Route
          path={"/defaultSetting"}
          exact={true}
          element={<DefaultSetting />}
        />
        <Route
          path={"/style-setting"}
          exact={true}
          element={<StylesSetting />}
        />
        <Route
          path={"/edit-single-product/:product_id"}
          exact={true}
          element={<EditSingleProduct />}
        />
        <Route
          path={"/edit-single-product"}
          exact={true}
          element={<EditSingleProduct />}
        />
        <Route
          path={"/email-setting"}
          exact={true}
          element={<PreOrderEmail />}
        />
        <Route
          path={"/pricingplan"}
          exact={true}
          element={<PricingPlanMain />}
        />
        {/* back in stock links */}
        <Route path={"/back-in-stock"} exact={true} element={<BackInStock />} />
        <Route
          path={"/back-in-stock/add-subscriber"}
          exact={true}
          element={<AddSubscriber />}
        />
        <Route
          path={"/back-in-stock/out-of-stock"}
          exact={true}
          element={<OutOfStock />}
        />
        <Route
          path={"/back-in-stock/submission/:id"}
          exact={true}
          element={<SubmissionDetail />}
        />
        <Route
          path={"/back-in-stock/setting/appearance"}
          exact={true}
          element={<BackInStock_setting_appearance />}
        />
        <Route
          path={"/back-in-stock/setting/conditions"}
          exact={true}
          element={<BackInStock_setting_conditions />}
        />
        <Route
          path={"/back-in-stock/setting/delivery-policy"}
          exact={true}
          element={<BackInStock_setting_delivery_policy />}
        />
        <Route
          path={"/back-in-stock/setting/campaigns"}
          exact={true}
          element={<BackInStock_setting_campaigns />}
        />
        <Route
          path={"/back-in-stock/setting/activity"}
          exact={true}
          element={<BackInStock_setting_activity />}
        />
        <Route
          path={"/back-in-stock/setting/locations"}
          exact={true}
          element={<BackInStock_setting_location />}
        />
        <Route
          path={"/back-in-stock/setting/additional"}
          exact={true}
          element={<BackInStock_setting_additional />}
        />
        {/* 404 */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AppRouter>
  );
};

export default Router;
