import React from 'react';
import {
    EmptyState
  } from "@shopify/polaris";
  import app_logo from "../../assets/images/app-logo.svg";
  import { LazyLoadImage } from "react-lazy-load-image-component";  
  
const GettingStarted = ({handleNext}) => {
  return (
    <div className="m-auto">
        <EmptyState
            action={{
              content: 'Get started',
              onClick: handleNext
            }}
            heading="Welcome to PreOrder Bat app"
        >
            <LazyLoadImage
                    src={`${app_logo}`}
                    alt="image"
                  />
            <p>Introducing PreOrder Bat, Our app simplifies the way you handle pre-orders, providing a straightforward and easy-to-use tool that integrates seamlessly with your e-commerce business.</p>
        </EmptyState>
    </div> 
  )
}

export default GettingStarted;