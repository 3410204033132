import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  Button,
  Checkbox,
  RadioButton,
  TextField,
  Select,
  Toast,
  Text,
  Tooltip,
  Icon,
  Layout,
  AppProvider,
  Box,
  InlineStack,
  Card,
  BlockStack,
  InlineGrid,
  FormLayout,
  ButtonGroup,
} from "@shopify/polaris";
import { handlePageRefresh } from "../helpers/commonFunctions";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiCall, ShopifyCall } from "../helpers/axios";
import { setSingeProductData } from "../redux/singleProductSlice";
import noimg from "../assets/images/noimg.jpg";
import {
  htmlSpecialCharacterEncode,
  htmlspecialchars_decode,
} from "../helpers/commonFunctions";
import Footer from "./Footer";
import { InfoIcon } from "@shopify/polaris-icons";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";

const EditSingleProduct = () => {
  const shopify = useAppBridge();

  const [checked, setChecked] = useState([false]);
  const navigate = useNavigate();
  let dispatch = useDispatch();
  let { product_id } = useParams();
  const [settingType, setSettingType] = useState([false]);
  const [isOpen, setIsOpen] = useState([true]);
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [varientIndex, setVarientIndex] = useState("");
  const [dbData, setDbData] = useState([]);
  const single_product_data = useSelector(
    (state) => state.singleProductReducer.single_product_data
  );
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const store_name = shop_data.store_name;
  const [variant, setvariant] = useState([]);
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };
  const [productImage, setProductImage] = useState({});
  const [productData, setProductData] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openTab, setOpenTab] = useState();

  ///partial payemnent

  const [ppOrderDateType, setPpOrderDateType] = useState([true]);

  const partial_payment_event_opts = [
    { label: "Order placed", value: "1" },
    { label: "Order fulfilled", value: "2" },
  ];
  const partial_payment_days_opts = [
    {
      label:
        "0 days (used to collection payment as soon as order is fulfilled can be disabled when event is order placed)",
      value: "1",
    },
    { label: "3 days", value: "3" },
    { label: "7 days", value: "7" },
    { label: "14 days", value: "14" },
    { label: "Custom", value: "5" },
  ];
  const handlePPOrderDateType = (checked, newValue, index) => {
    let type = [...ppOrderDateType];
    type[index] = !type[index];
    setPpOrderDateType(type);
  };
  // text: "Preorder now",
  const [initialState, setInitialState] = useState({
    text: ["Preorder now"],
    message: [],
    message_placement: [],
    enable_inventary: [],
    method: [],
    order_start_date: [],
    order_end_date: [],
    cart_label_text: [],
    inventory_management: [],
    inventory_quantity: [],
    variant_name: [],
    prevent_customers: [],
    order_remain_message: [],
    order_message: [],
    notify_customers: [],
    buy_now: [],
    // partial payment
    partial_payment: [],
    pp_Deposit_amount: [],
    pp_day_type: [],
    pp_deposit_amount_type: [],
    pp_cp_fixed_date: [],
    pp_cp_event: [],
    pp_cp_days: [],
    pp_cp_collect_after: [],
    sellingPlanGroup_Id: [],
    sellingPlan_id: [],
  });

  const formSchema = Yup.object().shape({
    text: Yup.string().required("Button text is required"),
    cart_label_text: Yup.string().required("Cart label text is required"),
  });

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: (values) => {
      productSave(values);
    },
  });

  useEffect(() => {
    if (!product_id && Object.keys(single_product_data).length === 0) {
      navigate("/single-product");
    }
  }, []);

  useEffect(() => {
    if (single_product_data) {
      window.addEventListener("beforeunload", handlePageRefresh);
      setvariant(
        Object.keys(single_product_data).length > 0 &&
          single_product_data.product_settings &&
          single_product_data.product_settings !== ""
          ? Object.values(JSON.parse(single_product_data.product_settings))
          : ""
      );
      setOpenTab(
        Object.keys(single_product_data).length > 0 &&
          single_product_data.product_settings &&
          Object.values(JSON.parse(single_product_data.product_settings))[0]
            .product_variant_name
      );
    } else {
      window.removeEventListener("beforeunload", handlePageRefresh);
    }
  }, [single_product_data]);

  const getProductDetail = async (id) => {
    setLoading(true);
    if (Object.keys(shop_data).length > 0) {
      let data = {
        store_client_id: store_client_id,
        shop_info: shop_data,
        shop: store_name,
      };
      let res = await ShopifyCall(
        "POST",
        `/product-detail?product_id=${id}`,
        data,
        header
      );

      if (res.data.status === "success" && res.data.statusCode === 200) {
        setProductData(res.data.data);
        dispatch(setSingeProductData(res?.data?.data.db_data));
        setDbData(res?.data?.data.db_data);
        setProductImage(res.data.data.image);
        setProductVariants(res.data.data.variants);
        setOpenTab(res.data.data.variants[0].title);
        setLoading(false);
      } else if (res.data.status === "error") {
        setProductData([]);
        setProductImage({});
        setProductVariants([]);
        setLoading(true);
      }
    }
  };

  useEffect(() => {
    if (product_id) {
      getProductDetail(product_id);
    }
  }, [product_id, shop_data]);

  const productSave = async (values) => {
    setLoading(true);
    if (productData && Object.keys(productData).length > 0) {
      
      let product_data = {
        image: (productData.image) ? productData.image.src : '',
        tags: productData.tags,
        title: productData.title,
      };
      let data = {
        store_client_id: store_client_id,
        product_id: product_id,
        product_title: productData.title,
        product_data: product_data,
        is_variant_product: productVariants.length > 1 ? 1 : 0,
      };
      let status_arr = [];

      let obj = {};
      if (productVariants && productVariants.length > 0) {
        productVariants.map((item, index) => {
          if(ppOrderDateType[index] ===true){
            values.pp_day_type[index] = 0;
            values.pp_cp_event[index] = "";
            values.pp_cp_days[index] = "";
          }
          if(ppOrderDateType[index] ===false){
            values.pp_day_type[index] = 1;
            values.pp_cp_fixed_date[index] = "";
          }
          obj[item.id] = {
            is_enable: checked[index] ? "1" : "0",
            product_inventory_quantity:
              values.inventory_quantity[index] !== undefined
                ? values.inventory_quantity[index]
                : "",
            product_inventory_management:
              values.inventory_management[index] !== undefined
                ? values.inventory_management[index]
                : "",
            product_variant_name:
              values.variant_name[index] !== undefined
                ? values.variant_name[index]
                : "Default Title",
          };
          status_arr.push(obj[item.id].is_enable);
          if (checked[index] && settingType[index]) {
            if(ppOrderDateType[index] === true){
              values.pp_day_type[index] = 0;
            }
            if(ppOrderDateType[index] ===false){
              values.pp_day_type[index] = 1;
            }
            obj[item.id] = {
              is_enable: "1",
              is_custome_setting: settingType[index] ? "1" : "0",
              product_text:
                values.text[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.text[index])
                  : "",
              product_message:
                values.message[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.message[index])
                  : "",
              product_message_placement:
                values.message_placement[index] !== undefined
                  ? values.message_placement[index]
                  : "1",
              product_enable_inventary:
                values.enable_inventary[index] !== undefined
                  ? values.enable_inventary[index]
                  : "",
              product_method:
                values.method[index] !== undefined ? values.method[index] : "0",
              product_order_start_date:
                values.order_start_date[index] !== undefined
                  ? values.order_start_date[index]
                  : "",
                   // partial payment fields
              product_partial_payment:
              values.partial_payment[index] !== undefined
                ? values.partial_payment[index]
                : "",
            product_pp_deposit_amount_type:
              values.pp_deposit_amount_type[index] !== undefined
                ? values.pp_deposit_amount_type[index]
                : 0,
            product_pp_Deposit_amount:
              values.pp_Deposit_amount[index] !== undefined
                ? values.pp_Deposit_amount[index]
                : 0,
            product_pp_day_type:
              values.pp_day_type[index] !== undefined
                ? values.pp_day_type[index]
                : "",
                product_pp_cp_fixed_date:
                values.pp_cp_fixed_date[index] !== undefined
                  ? values.pp_cp_fixed_date[index]
                  : new Date(),
              product_pp_cp_event:
                values.pp_cp_event[index] !== undefined
                    ? values.pp_cp_event[index]
                    : "",
              product_pp_cp_days:
                 values.pp_cp_days[index] !== undefined
                    ? values.pp_cp_days[index]
                    : "",
              product_pp_cp_collect_after:
                 values.pp_cp_collect_after[index] !== undefined
              ? values.pp_cp_collect_after[index]
              : "",
              sellingPlanGroup_Id:
              values.sellingPlanGroup_Id[index] !== undefined
                  ? values.sellingPlanGroup_Id[index]
                  : "",
                  sellingPlan_id:
              values.sellingPlan_id[index] !== undefined
                  ? values.sellingPlan_id[index]
                  : "",
              product_order_end_date:
                values.order_end_date[index] !== undefined
                  ? values.order_end_date[index]
                  : "",
              product_cart_label_text:
                values.cart_label_text[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.cart_label_text[index])
                  : "",
              product_variant_name:
                values.variant_name[index] !== undefined
                  ? values.variant_name[index]
                  : "Default Title",
              product_inventory_management:
                values.inventory_management[index] !== undefined
                  ? values.inventory_management[index]
                  : "",
              product_inventory_quantity:
                values.inventory_quantity[index] !== undefined
                  ? values.inventory_quantity[index]
                  : "",
              product_prevent_customers:
                values.prevent_customers[index] !== undefined
                  ? values.prevent_customers[index]
                  : "",
              product_order_remain_message:
                values.order_remain_message[index] !== undefined
                  ? htmlSpecialCharacterEncode(
                    values.order_remain_message[index]
                  )
                  : "",
              product_order_message:
                values.order_message[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.order_message[index])
                  : "",
              product_notify_customers:
                values.notify_customers[index] !== undefined
                  ? values.notify_customers[index]
                  : "",
              product_buy_now:
                values.buy_now[index] !== undefined
                  ? values.buy_now[index]
                  : "",
            };
          }
          if (checked[index]) {
            obj[item.id].is_custome_setting = settingType[index] ? "1" : "0";
          }
        });
        data.setting_data = obj;
        if (status_arr.includes("0") && status_arr.includes("1")) {
          data.product_variant_status = "2";
        } else if (status_arr.includes("1")) {
          data.product_variant_status = "1";
        } else if (status_arr.includes("0")) {
          data.product_variant_status = "0";
        }
      }
      let res = await ApiCall("POST", "/single-product", data, header);
      if (res.data.statusCode === 200) {
        
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
        setTimeout(() => navigate("/single-product"), 2000);
      }
    } else {
      let data = {
        id: single_product_data.id
          ? parseInt(single_product_data.id)
          : product_id,
      };
      if (single_product_data && Object.keys(single_product_data).length > 0) {
        let obj = {};
        let status_arr = [];
        if (single_product_data.product_settings !== "") {
          Object.keys(JSON.parse(single_product_data.product_settings)).map(
            (item, index) => {
              if(ppOrderDateType[index] ===true){
                values.pp_day_type[index] = 0;
                values.pp_cp_event[index] = "";
                values.pp_cp_days[index] = "";
              }
              if(ppOrderDateType[index] ===false){
                values.pp_day_type[index] = 1;
                values.pp_cp_fixed_date[index] = "";
              }
              obj[item] = {
                is_enable: checked[index] ? "1" : "0",
                product_inventory_quantity:
                  values.inventory_quantity[index] !== undefined
                    ? values.inventory_quantity[index]
                    : "",
                product_inventory_management:
                  values.inventory_management[index] !== undefined
                    ? values.inventory_management[index]
                    : "",
                product_variant_name:
                  values.variant_name[index] !== undefined
                    ? values.variant_name[index]
                    : "Default Title",
              };
              status_arr.push(obj[item].is_enable);
              if (checked[index]) {
                obj[item].is_custome_setting = settingType[index] ? "1" : "0";
              }
              if (checked[index] && settingType[index]) {
                obj[item] = {
                  is_enable: "1",
                  is_custome_setting: "1",
                  product_text:
                    values.text[index] !== undefined
                      ? htmlSpecialCharacterEncode(values.text[index])
                      : "",
                  product_message:
                    values.message[index] !== undefined
                      ? htmlSpecialCharacterEncode(values.message[index])
                      : "",
                  product_message_placement:
                    values.message_placement[index] !== undefined
                      ? values.message_placement[index]
                      : "1",
                  product_enable_inventary:
                    values.enable_inventary[index] !== undefined
                      ? values.enable_inventary[index]
                      : "",
                  product_method:
                    values.method[index] !== undefined
                      ? values.method[index]
                      : "0",
                    // partial payment fields
                    product_partial_payment:
                    values.partial_payment[index] !== undefined
                      ? values.partial_payment[index]
                      : "",
                  product_pp_deposit_amount_type:
                    values.pp_deposit_amount_type[index] !== undefined
                      ? values.pp_deposit_amount_type[index]
                      : 0,
                      product_pp_day_type:
                      values.pp_day_type[index] !== undefined
                        ? values.pp_day_type[index]
                        : "",
                  product_pp_Deposit_amount:
                    values.pp_Deposit_amount[index] !== undefined
                      ? values.pp_Deposit_amount[index]
                      : 0,
                  product_pp_cp_fixed_date:
                    values.pp_cp_fixed_date[index] !== undefined
                      ? values.pp_cp_fixed_date[index]
                      : new Date(),
                  product_pp_cp_event:
                    values.pp_cp_event[index] !== undefined
                      ? values.pp_cp_event[index]
                      : "",
                  product_pp_cp_days:
                    values.pp_cp_days[index] !== undefined
                      ? values.pp_cp_days[index]
                      : "",
                  product_pp_cp_collect_after:
                    values.pp_cp_collect_after[index] !== undefined
                      ? values.pp_cp_collect_after[index]
                      : "",
                      sellingPlanGroup_Id:
                      values.sellingPlanGroup_Id[index] !== undefined
                          ? values.sellingPlanGroup_Id[index]
                          : "",
                          sellingPlan_id:
                      values.sellingPlan_id[index] !== undefined
                          ? values.sellingPlan_id[index]
                          : "",
                  // end
                  product_order_start_date:
                    values.order_start_date[index] !== undefined
                      ? values.order_start_date[index]
                      : "",
                  product_order_end_date:
                    values.order_end_date[index] !== undefined
                      ? values.order_end_date[index]
                      : "",
                  product_cart_label_text:
                    values.cart_label_text[index] !== undefined
                      ? htmlSpecialCharacterEncode(
                        values.cart_label_text[index]
                      )
                      : "",
                  product_inventory_quantity:
                    values.inventory_quantity[index] !== undefined
                      ? values.inventory_quantity[index]
                      : "",
                  product_inventory_management:
                    values.inventory_management[index] !== undefined
                      ? values.inventory_management[index]
                      : "",
                  product_variant_name:
                    values.variant_name[index] !== undefined
                      ? values.variant_name[index]
                      : "Default Title",
                  product_prevent_customers:
                    values.prevent_customers[index] !== undefined
                      ? values.prevent_customers[index]
                      : "",
                  product_order_remain_message:
                    values.order_remain_message[index] !== undefined
                      ? htmlSpecialCharacterEncode(
                        values.order_remain_message[index]
                      )
                      : "",
                  product_order_message:
                    values.order_message[index] !== undefined
                      ? htmlSpecialCharacterEncode(values.order_message[index])
                      : "",
                  product_notify_customers:
                    values.notify_customers[index] !== undefined
                      ? values.notify_customers[index]
                      : "",
                  product_buy_now:
                    values.buy_now[index] !== undefined
                      ? values.buy_now[index]
                      : "",
                };
              }
            }
          );
        }
        data.setting_data = obj;
        if (status_arr.includes("0") && status_arr.includes("1")) {
          data.product_variant_status = "2";
        } else if (status_arr.includes("1")) {
          data.product_variant_status = "1";
        } else if (status_arr.includes("0")) {
          data.product_variant_status = "0";
        }
      }
      let res = await ApiCall("POST", "/single-product", data, header);

      if (res.data.statusCode === 200) {
        
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
        setTimeout(() => navigate("/single-product"), 2000);
      }
    }
  };


  useEffect(() => {
    let data = "";
    if (
      product_id &&
      dbData.length == 0 &&
      productVariants &&
      productVariants.length > 0
    ) {
      productVariants.map((item, index) => {
        initialState.text = [...initialState.text, data];
        initialState.message = [...initialState.message, data];
        initialState.message_placement = [
          ...initialState.message_placement,
          data,
        ];
        initialState.enable_inventary = [
          ...initialState.enable_inventary,
          false,
        ];
        initialState.method = [...initialState.method, 0];
        initialState.order_start_date = [
          ...initialState.order_start_date,
          data,
        ];
        initialState.order_end_date = [...initialState.order_end_date, data];
        initialState.cart_label_text = [...initialState.cart_label_text, data];
        initialState.inventory_management = [
          ...initialState.inventory_management,
          item.inventory_management,
        ];
        initialState.inventory_quantity = [
          ...initialState.inventory_quantity,
          item.inventory_quantity,
        ];
        initialState.variant_name = [...initialState.variant_name, item.title];
        initialState.prevent_customers = [
          ...initialState.prevent_customers,
          false,
        ];
        initialState.order_remain_message = [
          ...initialState.order_remain_message,
          "",
        ];
        initialState.order_message = [...initialState.order_message, ""];
        initialState.notify_customers = [
          ...initialState.notify_customers,
          false,
        ];
        initialState.buy_now = [...initialState.buy_now, false];
         // Partial payment fields
         initialState.partial_payment = [...initialState.partial_payment, false]; // or appropriate default value
         initialState.pp_deposit_amount_type = [
           ...initialState.pp_deposit_amount_type ,
           0,
         ]; // deposit amount type
         initialState.pp_Deposit_amount = [...initialState.pp_Deposit_amount, 0]; // deposit amount
         initialState.pp_cp_fixed_date = [...initialState.pp_cp_fixed_date, new Date()]; // fixed date
         initialState.pp_day_type = [...initialState.pp_day_type, ""];
         initialState.pp_cp_event = [...initialState.pp_cp_event, ""]; // event name
         initialState.pp_cp_days = [...initialState.pp_cp_days, ""]; // number of days
         initialState.sellingPlanGroup_Id = [...initialState.sellingPlanGroup_Id, ""]; // number of days
         initialState.sellingPlan_id = [...initialState.sellingPlan_id, ""]; // number of days
         initialState.pp_cp_collect_after = [
           ...initialState.pp_cp_collect_after,
           "",
         ]; // collect after value
      });
      setInitialState(initialState);
    }
  }, [productVariants]);


  useEffect(() => {
    if (
      !product_id &&
      Object.keys(single_product_data).length > 0 &&
      single_product_data.product_settings !== ""
    ) {
      Object.values(JSON.parse(single_product_data.product_settings)).map(
        (item, index) => {
          checked[index] = item.is_enable === "1" ? true : false;
          settingType[index] = item.is_custome_setting === "1" ? true : false;
          ppOrderDateType[index] = item.product_pp_day_type === 0 ? true : false;
          initialState.text = [
            ...initialState.text,
            htmlspecialchars_decode(item.product_text),
          ];
          initialState.message = [
            ...initialState.message,
            htmlspecialchars_decode(item.product_message),
          ];
          initialState.message_placement = [
            ...initialState.message_placement,
            item.product_message_placement,
          ];
          initialState.enable_inventary = [
            ...initialState.enable_inventary,
            item.product_enable_inventary,
          ];
          initialState.method = [...initialState.method, item.product_method];
          initialState.order_start_date = [
            ...initialState.order_start_date,
            item.product_order_start_date !== "" &&
              item.product_order_start_date
              ? new Date(item.product_order_start_date)
              : "",
          ];
          initialState.order_end_date = [
            ...initialState.order_end_date,
            item.product_order_end_date !== "" && item.product_order_end_date
              ? new Date(item.product_order_end_date)
              : "",
          ];
          initialState.cart_label_text = [
            ...initialState.cart_label_text,
            htmlspecialchars_decode(item.product_cart_label_text),
          ];
          initialState.inventory_management = [
            ...initialState.inventory_management,
            item.product_inventory_management,
          ];
          initialState.inventory_quantity = [
            ...initialState.inventory_quantity,
            item.product_inventory_quantity,
          ];
          initialState.variant_name = [
            ...initialState.variant_name,
            item.product_variant_name,
          ];
          initialState.prevent_customers = [
            ...initialState.prevent_customers,
            item.product_prevent_customers,
          ];
          initialState.order_remain_message = [
            ...initialState.order_remain_message,
            htmlspecialchars_decode(item.product_order_remain_message),
          ];
          initialState.order_message = [
            ...initialState.order_message,
            htmlspecialchars_decode(item.product_order_message),
          ];
          initialState.notify_customers = [
            ...initialState.notify_customers,
            item.product_notify_customers,
          ];
          initialState.buy_now = [
            ...initialState.buy_now,
            item.product_buy_now,
          ];
           // Add Partial Payment Fields
           initialState.partial_payment = [
            ...initialState.partial_payment,
            item.product_partial_payment || false, // Default to false if undefined
          ];
          initialState.pp_deposit_amount_type = [
            ...initialState.pp_deposit_amount_type,
            item.product_pp_deposit_amount_type || 0, // Default to empty string
          ];
          initialState.pp_day_type = [
            ...initialState.pp_day_type,
            item.product_pp_day_type , // Ensure it's a number
          ];
          initialState.pp_Deposit_amount = [
            ...initialState.pp_Deposit_amount,
            item.product_pp_Deposit_amount || 0, // Default to empty string
          ];
          initialState.pp_cp_fixed_date = [
            ...initialState.pp_cp_fixed_date,
            item.product_pp_cp_fixed_date !== "" && item.product_pp_cp_fixed_date
              ? new Date(item.product_pp_cp_fixed_date)
              : new Date(),
          ];
          initialState.pp_cp_event = [
            ...initialState.pp_cp_event,
            item.product_pp_cp_event || "", // Default to empty string
          ];
          initialState.pp_cp_days = [
            ...initialState.pp_cp_days,
            item.product_pp_cp_days || "", // Default to empty string
          ];
          initialState.pp_cp_collect_after = [
            ...initialState.pp_cp_collect_after,
            item.product_pp_cp_collect_after || "", // Default to empty string
          ];

          initialState.sellingPlanGroup_Id = [
            ...initialState.sellingPlanGroup_Id,
            item.sellingPlanGroup_Id || "", // Default to empty string
          ];
          initialState.sellingPlan_id = [
            ...initialState.sellingPlan_id,
            item.sellingPlan_id || "", // Default to empty string
          ];
        }
      );
      setInitialState(initialState);
    }
  }, [single_product_data]);


  useEffect(() => {
    if (Object.keys(dbData).length > 0 && dbData.product_settings !== "") {
      let arr = [];
      Object.values(JSON.parse(dbData.product_settings)).map((item, index) => {
        arr.push(item.is_enable == "1" ? true : false);
        settingType[index] = item.is_custome_setting === "1" ? true : false;
        ppOrderDateType[index] = item.product_pp_day_type === 0 ? true : false;

        initialState.text = [
          ...initialState.text,
          htmlspecialchars_decode(item.product_text),
        ];
        initialState.message = [
          ...initialState.message,
          htmlspecialchars_decode(item.product_message),
        ];
        initialState.message_placement = [
          ...initialState.message_placement,
          item.product_message_placement,
        ];
        initialState.enable_inventary = [
          ...initialState.enable_inventary,
          item.product_enable_inventary,
        ];
        initialState.method = [...initialState.method, item.product_method];
        initialState.order_start_date = [
          ...initialState.order_start_date,
          item.product_order_start_date !== "" && item.product_order_start_date
            ? new Date(item.product_order_start_date)
            : "",
        ];
        initialState.order_end_date = [
          ...initialState.order_end_date,
          item.product_order_end_date !== "" && item.product_order_end_date
            ? new Date(item.product_order_end_date)
            : "",
        ];
        initialState.cart_label_text = [
          ...initialState.cart_label_text,
          htmlspecialchars_decode(item.product_cart_label_text),
        ];
        initialState.inventory_management = [
          ...initialState.inventory_management,
          item.product_inventory_management,
        ];
        initialState.inventory_quantity = [
          ...initialState.inventory_quantity,
          item.product_inventory_quantity,
        ];
        initialState.variant_name = [
          ...initialState.variant_name,
          item.product_variant_name,
        ];
        initialState.prevent_customers = [
          ...initialState.prevent_customers,
          item.product_prevent_customers ? true : false,
        ];
        initialState.order_remain_message = [
          ...initialState.order_remain_message,
          htmlspecialchars_decode(item.product_order_remain_message),
        ];
        initialState.order_message = [
          ...initialState.order_message,
          htmlspecialchars_decode(item.product_order_message),
        ];
        initialState.notify_customers = [
          ...initialState.notify_customers,
          item.product_notify_customers ? true : false,
        ];
        initialState.buy_now = [
          ...initialState.buy_now,
          item.product_buy_now ? true : false,
        ];
        // Add Partial Payment Fields
        initialState.partial_payment = [
          ...initialState.partial_payment,
          item.product_partial_payment || false, // Default to false if undefined
        ];
        initialState.pp_deposit_amount_type = [
          ...initialState.pp_deposit_amount_type,
          item.product_pp_deposit_amount_type || 0, // Default to empty string
        ];
        initialState.pp_day_type = [
          ...initialState.pp_day_type,
          item.product_pp_day_type || "", // Default to empty string
        ];
        initialState.pp_Deposit_amount = [
          ...initialState.pp_Deposit_amount,
          item.product_pp_Deposit_amount || 0, // Default to empty string
        ];
        initialState.pp_cp_fixed_date = [
          ...initialState.pp_cp_fixed_date,
          item.product_pp_cp_fixed_date !== "" && item.product_pp_cp_fixed_date
            ? new Date(item.product_pp_cp_fixed_date)
            : new Date(),
        ];
        initialState.pp_cp_event = [
          ...initialState.pp_cp_event,
          item.product_pp_cp_event || "", // Default to empty string
        ];
        initialState.pp_cp_days = [
          ...initialState.pp_cp_days,
          item.product_pp_cp_days || "", // Default to empty string
        ];
        initialState.pp_cp_collect_after = [
          ...initialState.pp_cp_collect_after,
          item.product_pp_cp_collect_after || "", // Default to empty string
        ];
        initialState.sellingPlanGroup_Id = [
          ...initialState.sellingPlanGroup_Id,
          item.sellingPlanGroup_Id || "", // Default to empty string
        ];
        initialState.sellingPlan_id = [
          ...initialState.sellingPlan_id,
          item.sellingPlan_id || "", // Default to empty string
        ];
      });
      setChecked(arr);
      setInitialState(initialState);
    }
  }, [dbData]);




  const placementOptions = [
    { label: "Below Add to Cart Button", value: "1" },
    { label: "Above Add to Cart Button", value: "2" },
  ];

  const ribbonOptions = [
    { label: "Ribbon", value: "0" },
    { label: "Rectangle", value: "1" },
    { label: "Circle", value: "2" },
  ];

  const handleChange = (_checked, newValue, index) => {
    let type = [...settingType];
    type[index] = !type[index];
    setSettingType(type);
  };

  let toggle = (index) => {
    let open = [...isOpen];
    if (!open[index] === true) {
      let arr = [];
      for (let i = 0; i < open.length; i++) {
        arr.push(false);
      }
      open = arr;
    }
    open[index] = !open[index];
    setIsOpen(open);
  };

  let handleCheck = (index) => {
    let check = [...checked];
    check[index] = !check[index];
    setChecked(check);
  };

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={1000}
    />
  ) : null;

  const copyVarientsSetting = (findIndex) => {
    // console.log("single_product_data",single_product_data)
    // console.log("productData",productData)
    // console.log("productVariants",productVariants)
    if (single_product_data && Object.keys(single_product_data).length > 0) {
      if (single_product_data.product_settings !== "") {
        Object.values(JSON.parse(single_product_data.product_settings)).map(
          (item, index) => {
            
            checked[index] = checked[findIndex];
            settingType[index] = settingType[findIndex];
            formik.values.message[index] = htmlspecialchars_decode(
              formik.values.message[findIndex]
            );
            formik.values.text[index] = htmlspecialchars_decode(
              formik.values.text[findIndex]
            );
            formik.values.message_placement[index] =
              formik.values.message_placement[findIndex];
            formik.values.enable_inventary[index] =
              formik.values.enable_inventary[findIndex];
            formik.values.method[index] = formik.values.method[findIndex];
            formik.values.order_start_date[index] =
              formik.values.order_start_date[findIndex];
            formik.values.order_end_date[index] =
              formik.values.order_end_date[findIndex];
            formik.values.prevent_customers[index] =
              formik.values.prevent_customers[findIndex];
            formik.values.order_remain_message[index] = htmlspecialchars_decode(
              formik.values.order_remain_message[findIndex]
            );
            formik.values.order_message[index] = htmlspecialchars_decode(
              formik.values.order_message[findIndex]
            );
            formik.values.notify_customers[index] =
              formik.values.notify_customers[findIndex];
            formik.values.buy_now[index] = initialState.buy_now[findIndex];
            formik.values.cart_label_text[index] = htmlspecialchars_decode(
              formik.values.cart_label_text[findIndex]
            );
             // New Partial Payment Values
             //ppOrderDateType
             formik.values.pp_day_type[index] = formik.values.pp_day_type[findIndex];
             formik.values.partial_payment[index] =
             formik.values.partial_payment[findIndex];
            formik.values.pp_Deposit_amount[index] =
              formik.values.pp_Deposit_amount[findIndex];
            formik.values.pp_deposit_amount_type[index] =
              formik.values.pp_deposit_amount_type[findIndex];
            formik.values.pp_cp_fixed_date[index] =
              formik.values.pp_cp_fixed_date[findIndex];
            formik.values.pp_cp_event[index] =
              formik.values.pp_cp_event[findIndex];
            formik.values.pp_cp_days[index] =
              formik.values.pp_cp_days[findIndex];
            formik.values.pp_cp_collect_after[index] =
             formik.values.pp_cp_collect_after[findIndex];

             formik.values.sellingPlanGroup_Id[index] =
             formik.values.sellingPlanGroup_Id[findIndex];
             formik.values.sellingPlan_id[index] =
             formik.values.sellingPlan_id[findIndex];
           formik.values.pp_day_type[index] =
             formik.values.pp_day_type[findIndex];
          }

        );
      }
      toggleModal();
    }

    if (
      product_id &&
      productData &&
      Object.keys(productData).length > 0 &&
      productVariants &&
      productVariants.length > 0
    ) {
      productVariants.map((item, index) => {
        console.log("formik.values",formik.values,item)
        checked[index] = checked[findIndex];
        settingType[index] = settingType[findIndex];
        formik.values.message[index] = htmlspecialchars_decode(
          formik.values.message[findIndex]
        );
        formik.values.text[index] = htmlspecialchars_decode(
          formik.values.text[findIndex]
        );
        formik.values.message_placement[index] =
          formik.values.message_placement[findIndex];
        formik.values.enable_inventary[index] =
          formik.values.enable_inventary[findIndex];
        formik.values.method[index] = formik.values.method[findIndex];
        formik.values.order_start_date[index] =
          formik.values.order_start_date[findIndex];
        formik.values.order_end_date[index] =
          formik.values.order_end_date[findIndex];
        formik.values.order_remain_message[index] = htmlspecialchars_decode(
          formik.values.order_remain_message[findIndex]
        );
        formik.values.order_message[index] = htmlspecialchars_decode(
          formik.values.order_message[findIndex]
        );
        formik.values.buy_now[index] = formik.values.buy_now[findIndex];
        formik.values.cart_label_text[index] = htmlspecialchars_decode(
          formik.values.cart_label_text[findIndex]
        );
         // New Partial Payment Values
        //ppOrderDateType 
        ppOrderDateType[index] = ppOrderDateType[findIndex];
        formik.values.pp_day_type[index] = formik.values.pp_day_type[findIndex];
         formik.values.partial_payment[index] =
         formik.values.partial_payment[findIndex];
       formik.values.pp_Deposit_amount[index] =
         formik.values.pp_Deposit_amount[findIndex];
       formik.values.pp_deposit_amount_type[index] =
         formik.values.pp_deposit_amount_type[findIndex];
       formik.values.pp_cp_fixed_date[index] =
         formik.values.pp_cp_fixed_date[findIndex];
       formik.values.pp_cp_event[index] =
         formik.values.pp_cp_event[findIndex];
       formik.values.pp_cp_days[index] =
         formik.values.pp_cp_days[findIndex];
       formik.values.pp_cp_collect_after[index] =
         formik.values.pp_cp_collect_after[findIndex];
         formik.values.sellingPlanGroup_Id[index] =
         formik.values.sellingPlanGroup_Id[findIndex];
         formik.values.sellingPlan_id[index] =
         formik.values.sellingPlan_id[findIndex];
       formik.values.pp_day_type[index] =
         formik.values.pp_day_type[findIndex];
      });
      toggleModal();
    }
  };


  const handleModal = (index, flag) => {
    setVarientIndex(index);
    if (flag) {
      copyVarientsSetting(varientIndex);
      setToastMessage("Copy settings Successfully..!");
      toggleActiveToast();
    } else {
      toggleModal();
    }
  };

  let toggleModal = () => shopify.modal.toggle("confirm-modal");

  return (
    <>
      {window.location.hostname !== "localhost" && (
        <>
          <AppProvider>
            <TitleBar title={!product_id ? "Edit product" : "Add product"}>
              <button variant="breadcrumb" onClick={() => navigate("/single-product")}>
                Single product
              </button>
            </TitleBar>
            {!product_id ? (
              <Page
                backAction={{
                  content: "Settings",
                  onAction: () => navigate("/single-product"),
                }}
                title={
                  Object.keys(single_product_data).length > 0 &&
                  single_product_data.product_detail !== ""
                    ? JSON.parse(single_product_data.product_detail).title
                    : ""
                }
              >
                <Layout>
                  <Layout.Section variant="oneThird">
                    <BlockStack gap="400">
                      <Card>
                        <InlineStack wrap={false} gap="200" blockAlign="center">
                          <div className="product-edit-img">
                            <img
                              src={
                                Object.keys(single_product_data).length > 0 &&
                                single_product_data.product_detail !== ""
                                  ? JSON.parse(
                                      single_product_data.product_detail
                                    ).image
                                  : `${noimg}`
                              }
                              alt="demo"
                              className="w-full h-auto"
                            />
                          </div>
                          <Text variant="bodyLg" as="p">
                            {Object.keys(single_product_data).length > 0 &&
                            single_product_data.product_detail !== ""
                              ? JSON.parse(single_product_data.product_detail)
                                  .title
                              : ""}
                          </Text>
                        </InlineStack>
                      </Card>
                      <Card padding="0">
                        <div className="custom-single-product-header-title-Variant py-3">
                          Variant
                        </div>
                        <ul
                          className="custom-single-product-header-title-Variant_ul"
                          style={{ marginBottom: "8px" }}
                        >
                          {Object.keys(single_product_data).length > 0 &&
                          single_product_data.product_settings &&
                          single_product_data.product_settings !== ""
                            ? Object.values(
                                JSON.parse(single_product_data.product_settings)
                              ).map((item, index) => {
                                return (
                                  <>
                                    <Box background="bg-fill-secondary">
                                      <li
                                        className={`product_variant_name ps-4 ${
                                          item.product_variant_name == openTab
                                            ? "variant_active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          setOpenTab(item.product_variant_name)
                                        }
                                      >
                                        {item.product_variant_name}
                                      </li>
                                    </Box>
                                  </>
                                );
                              })
                            : ""}
                        </ul>
                      </Card>
                    </BlockStack>
                  </Layout.Section>
                  <Layout.Section>
                    <div>
                      {Object.keys(single_product_data).length > 0 &&
                        single_product_data.product_settings &&
                        single_product_data.product_settings !== "" &&
                        Object.values(
                          JSON.parse(single_product_data.product_settings)
                        ).map((item, index) => {
                          return (
                            <div
                              className={`border-top-edit ${
                                item.product_variant_name == openTab
                                  ? "d-block"
                                  : "d-none"
                              }`}
                            >
                              <div key={index} className="edit_product">
                                <Card>
                                  <BlockStack gap="400">
                                    <InlineStack
                                      blockAlign="center"
                                      align="space-between"
                                    >
                                      <Text as="h1" variant="headingSm">
                                        {item.product_variant_name}
                                      </Text>
                                      {Object.values(
                                        JSON.parse(
                                          single_product_data.product_settings
                                        )
                                      ).length > 1 && (
                                        <Button
                                          variant="primary"
                                          onClick={() => {
                                            setVarientIndex(index);
                                            copyVarientsSetting(index);
                                          }}
                                        >
                                          Copy settings to all variants
                                        </Button>
                                      )}
                                    </InlineStack>
                                    <div className="row">
                                      <div className="col-lg-12 inventory">
                                        <div className="product-table flex-grow-1">
                                          <Card
                                            padding="0"
                                            key={`mainfirst-${index}`}
                                          >
                                            <table className="w-100 table m-0">
                                              <tbody>
                                                <tr>
                                                  <th>
                                                    Keeping track of stock
                                                  </th>
                                                  <td>
                                                    {item.product_inventory_management !==
                                                    ""
                                                      ? "Enable"
                                                      : "Disable"}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <th>Status of Inventory</th>
                                                  <td>
                                                    {
                                                      item.product_inventory_quantity
                                                    }
                                                  </td>
                                                </tr>{" "}
                                                <tr>
                                                  <th>Quantity Oversell</th>
                                                  <td>
                                                    {item.product_inventory_management !==
                                                    ""
                                                      ? "Enable"
                                                      : "Disable"}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </Card>
                                        </div>
                                      </div>
                                    </div>
                                    <InlineGrid
                                      gap="200"
                                      columns={{
                                        xs: 1,
                                        sm: 1,
                                        md: ["oneThird", "twoThirds"],
                                        lg: ["oneThird", "twoThirds"],
                                        xl: ["oneThird", "twoThirds"],
                                      }}
                                    >
                                      <Text as="h1" variant="headingSm">
                                        Enable
                                      </Text>
                                      <BlockStack gap="200">
                                        <Checkbox
                                          label="Enable pre-order for this variant"
                                          checked={checked[index]}
                                          onChange={() => handleCheck(index)}
                                          name="is_enable"
                                        />
                                        <InlineGrid
                                          columns={{
                                            xs: 1,
                                            sm: 1,
                                            md: 2,
                                            lg: 2,
                                            xl: 2,
                                          }}
                                        >
                                          <RadioButton
                                            label="Use default settings"
                                            checked={!settingType[index]}
                                            disabled={!checked[index]}
                                            onChange={(_checked, newValue) =>
                                              handleChange(
                                                _checked,
                                                newValue,
                                                index
                                              )
                                            }
                                          />
                                          <RadioButton
                                            label="Use settings you choose."
                                            checked={settingType[index]}
                                            disabled={!checked[index]}
                                            onChange={(_checked, newValue) =>
                                              handleChange(
                                                _checked,
                                                newValue,
                                                index
                                              )
                                            }
                                          />
                                        </InlineGrid>
                                      </BlockStack>
                                    </InlineGrid>
                                  </BlockStack>
                                </Card>
                                {checked[index] && settingType[index] && (
                                  <div className="row">
                                    <div className="card-border-setting">
                                      <Card>
                                        <BlockStack gap={"200"}>
                                          <Text as="h1" variant="headingSm">
                                            Button
                                          </Text>
                                          <div className="row gy-2">
                                            <div className="col-lg-4">
                                              <TextField
                                                label="Text on button "
                                                value={
                                                  formik.values.text[index]
                                                }
                                                placeholder="Preorder now"
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `text.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                                onBlur={formik.handleBlur}
                                                error={
                                                  formik.errors.text &&
                                                  formik.touched.text
                                                    ? formik.errors.text
                                                    : ""
                                                }
                                              />
                                            </div>
                                            <div className="col-lg-4">
                                              <TextField
                                                label="Pre-order message"
                                                value={
                                                  formik.values.message[index]
                                                }
                                                placeholder="Preorder message"
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `message.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                              />
                                            </div>
                                            <div className="col-lg-4">
                                              <Select
                                                label="message placements"
                                                options={placementOptions}
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `message_placement.${index}`,
                                                    value
                                                  );
                                                }}
                                                value={
                                                  formik.values
                                                    .message_placement[index]
                                                }
                                              />
                                            </div>
                                          </div>
                                        </BlockStack>
                                      </Card>
                                    </div>
                                    {/* Stock Method Card */}
                                    <div className="card-border-setting">
                                      <Card key={`mainsec-${index}`}>
                                        <BlockStack gap={"200"}>
                                          <Text as="h1" variant="headingSm">
                                            Stock
                                          </Text>
                                          <Checkbox
                                            label="Select Inventory Level For Pre-Order Products"
                                            checked={
                                              formik.values.enable_inventary[
                                                index
                                              ]
                                            }
                                            onChange={() =>
                                              formik.setFieldValue(
                                                `enable_inventary.${index}`,
                                                !formik.values.enable_inventary[
                                                  index
                                                ]
                                              )
                                            }
                                          />
                                          {formik.values.enable_inventary[
                                            index
                                          ] && (
                                            <>
                                              
                                              <div>
                                                <div className="border-bottom pb-2">
                                                  <RadioButton
                                                    label={
                                                      <div>
                                                        <strong>
                                                          Type 1:&nbsp;
                                                        </strong>
                                                        <span>
                                                          Quantity greater than 0.
                                                        </span>
                                                      </div>
                                                    }
                                                    checked={
                                                      formik.values.method[
                                                        index
                                                      ] === 0
                                                    }
                                                    id={`method_1.${index}`}
                                                    onChange={(
                                                      _checked,
                                                      newValue
                                                    ) => {
                                                      formik.setFieldValue(
                                                        `method.${index}`,
                                                        0
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                <div className="py-2">
                                                  <RadioButton
                                                    label={
                                                      <div>
                                                        <strong>
                                                         Type 2:&nbsp;
                                                        </strong>
                                                        <span>
                                                          Quantity less than or equal to 0.
                                                        </span>
                                                      </div>
                                                    }
                                                    checked={
                                                      formik.values.method[
                                                        index
                                                      ] === 1
                                                    }
                                                    id={`method_2.${index}`}
                                                    onChange={(
                                                      _checked,
                                                      newValue
                                                    ) =>
                                                      formik.setFieldValue(
                                                        `method.${index}`,
                                                        1
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="stock-note">
                                                <p>
                                                  <strong>Note:&nbsp;</strong>
                                                  Enable "Continue When Selling Out of Stock" In Shopify Products For Type 2 Settings.
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </BlockStack>
                                      </Card>
                                    </div>
                                    {/* Pre-order partial payment Start Card */}
                                    <div className="card-border-setting" style={{zIndex: "30"}}>
                                      <div className="date-picker-card-box" key={`mainsec-${index}`}>
                                        <BlockStack gap={"200"}>
                                          <Text as="h1" variant="headingSm">
                                            Pre-order partial payment
                                          </Text>

                                          {/* <p style={{ marginBottom: '37px' }}>
                                            Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                          </p> */}
                                          <FormLayout>
                                            <div className="row pb-3">
                                              <div className="display-flex">
                                                <Checkbox
                                                  name="partial_payment"
                                                  label="Partial payment"
                                                  checked={
                                                    formik.values
                                                      .partial_payment[index]
                                                  }
                                                  onChange={() =>
                                                    formik.setFieldValue(
                                                      `partial_payment.${index}`,
                                                      !formik.values
                                                        .partial_payment[index]
                                                    )
                                                  }
                                                />
                                              </div>
                                              {formik.values.partial_payment[
                                                index
                                              ] && (
                                                <>
                                                  <div className="d-flex pt-2 justify-content-between dpstAmntBox">
                                                    <div className="pp_deposit_amnt">
                                                      <TextField
                                                        name="pp_deposit_amount"
                                                        label="Deposit amount"
                                                        type="number"
                                                        value={
                                                          formik.values
                                                            .pp_Deposit_amount[
                                                            index
                                                          ]
                                                        }
                                                        onChange={(value) => {
                                                          const newValue =
                                                            parseInt(value, 10);
                                                          if (newValue < 0) {
                                                            formik.setFieldValue(
                                                              `pp_Deposit_amount.${index}`,
                                                              0
                                                            );
                                                          } else {
                                                            formik.setFieldValue(
                                                              `pp_Deposit_amount.${index}`,
                                                              newValue
                                                            );
                                                          }
                                                        }}
                                                        autoComplete="off"
                                                      />
                                                    </div>
                                                    <div className="pp_deposit_btns">
                                                      <ButtonGroup variant="segmented">
                                                        <Button
                                                          pressed={
                                                            formik.values
                                                              .pp_deposit_amount_type[
                                                              index
                                                            ] === 0
                                                          }
                                                          onClick={() => {
                                                            formik.setFieldValue(
                                                              `pp_deposit_amount_type.${index}`,
                                                              0
                                                            );
                                                          }}
                                                        >
                                                          %
                                                        </Button>
                                                        <Button
                                                          name
                                                          pressed={
                                                            formik.values
                                                              .pp_deposit_amount_type[
                                                              index
                                                            ] === 1
                                                          }
                                                          onClick={() => {
                                                            formik.setFieldValue(
                                                              `pp_deposit_amount_type.${index}`,
                                                              1
                                                            );
                                                          }}
                                                        >
                                                          $
                                                        </Button>
                                                      </ButtonGroup>
                                                    </div>
                                                  </div>
                                                  <div className="pt-2 display-flex">
                                                    <RadioButton
                                                      label="Fixed date"
                                                      checked={
                                                        ppOrderDateType[index]
                                                      }
                                                      onChange={(_, newValue) =>
                                                        handlePPOrderDateType(
                                                          _,
                                                          true,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  {ppOrderDateType[index] && (
                                                    <div
                                                      className="row"
                                                      style={{
                                                        paddingLeft: "40px",
                                                      }}
                                                    >
                                                      <div className="start_date mob-pb-1">
                                                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular ">
                                                          Collect Payment On
                                                        </span>
                                                        <Datetime
                                                          value={
                                                            formik.values
                                                              .pp_cp_fixed_date[
                                                              index
                                                            ]
                                                          }
                                                          onChange={(value) => {
                                                            formik.setFieldValue(
                                                              `pp_cp_fixed_date.${index}`,
                                                              value._d
                                                            );
                                                          }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              readOnly
                                                            />
                                                          )}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                  <div className="pt-2 display-flex">
                                                    <RadioButton
                                                      label="Calculate from order date"
                                                      checked={
                                                        !ppOrderDateType[index]
                                                      }
                                                      onChange={(_, newValue) =>
                                                        handlePPOrderDateType(
                                                          _,
                                                          false,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  {!ppOrderDateType[index] && (
                                                    <>
                                                      <div
                                                        className="row"
                                                        style={{
                                                          paddingLeft: "40px",
                                                        }}
                                                      >
                                                        <Text
                                                          variant="headingMd"
                                                          as="h6"
                                                        >
                                                          Collect Payment On
                                                        </Text>
                                                      </div>
                                                      <div
                                                        className="row"
                                                        style={{
                                                          paddingLeft: "40px",
                                                        }}
                                                      >
                                                        <Select
                                                          label="event"
                                                          options={
                                                            partial_payment_event_opts
                                                          }
                                                          value={
                                                            formik.values
                                                              .pp_cp_event[
                                                              index
                                                            ]
                                                          }
                                                          name="pp_cp_event"
                                                          onChange={(value) => {
                                                            formik.setFieldValue(
                                                              `pp_cp_event.${index}`,
                                                              value
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                      <div
                                                        className="row pt-2"
                                                        style={{
                                                          paddingLeft: "40px",
                                                        }}
                                                      >
                                                        <Select
                                                          label="days"
                                                          options={
                                                            partial_payment_days_opts
                                                          }
                                                          value={
                                                            formik.values
                                                              .pp_cp_days[index]
                                                          }
                                                          name="pp_cp_days"
                                                          onChange={(value) => {
                                                            formik.setFieldValue(
                                                              `pp_cp_days.${index}`,
                                                              value
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                      {formik.values.pp_cp_days[
                                                        index
                                                      ] === "5" && (
                                                        <div
                                                          className="row pt-2"
                                                          style={{
                                                            paddingLeft: "40px",
                                                          }}
                                                        >
                                                          <TextField
                                                            label="collect after"
                                                            type="number"
                                                            name="pp_cp_collect_after"
                                                            value={
                                                              formik.values
                                                                .pp_cp_collect_after[
                                                                index
                                                              ]
                                                            }
                                                            onChange={(
                                                              value
                                                            ) => {
                                                              const newValue =
                                                                parseInt(
                                                                  value,
                                                                  10
                                                                );
                                                              if (
                                                                newValue < 0
                                                              ) {
                                                                formik.setFieldValue(
                                                                  `pp_cp_collect_after.${index}`,
                                                                  0
                                                                );
                                                              } else {
                                                                formik.setFieldValue(
                                                                  `pp_cp_collect_after.${index}`,
                                                                  newValue
                                                                );
                                                              }
                                                            }}
                                                            autoComplete="off"
                                                          />
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </FormLayout>
                                        </BlockStack>
                                      </div>
                                    </div>
                                    {/* Pre-order partial payment End Card */}
                                    {/* Pre-order period */}
                                    <div className="card-border-setting z-1">
                                      <div
                                        className="date-picker-card-box"
                                        key={`mainfour-${index}`}
                                      >
                                        <BlockStack gap={"200"}>
                                          <Text as="h1" variant="headingSm">
                                            Pre-order period
                                          </Text>
                                          <div>
                                            <p>Timezone: Asia/Kolkata</p>
                                            <p className="py-2">
                                              The pre-order time tells you when
                                              your pre-order settings will turn
                                              on and/or off. If you want your
                                              pre-order settings to take effect
                                              right away, leave the pre-order
                                              start date blank or choose a date
                                              in the past.
                                            </p>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-6 start_date mob-pb-1">
                                              <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular ">
                                                Pre-order start date
                                              </span>
                                              <Datetime
                                                value={
                                                  formik.values
                                                    .order_start_date[index]
                                                }
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_start_date.${index}`,
                                                    value._d
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </div>
                                            <div className="col-lg-6 end_date">
                                              <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                                                Pre-order end date
                                              </span>
                                              <Datetime
                                                value={
                                                  formik.values.order_end_date[
                                                    index
                                                  ]
                                                }
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_end_date.${index}`,
                                                    value._d
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </div>
                                          </div>
                                        </BlockStack>
                                      </div>
                                    </div>
                                    <div className="card-border-setting">
                                      <Card roundAbove="xs">
                                        <BlockStack gap={"200"}>
                                          <Text as="h1" variant="headingSm">
                                            A stock alert
                                          </Text>
                                          <div className="row">
                                            <div className="display-flex">
                                              <Checkbox
                                                label="Customers shouldn't be able to buy more than the amount of pre-order stock."
                                                checked={
                                                  formik.values
                                                    .prevent_customers[index]
                                                }
                                                onChange={() =>
                                                  formik.setFieldValue(
                                                    `prevent_customers.${index}`,
                                                    !formik.values
                                                      .prevent_customers[index]
                                                  )
                                                }
                                              />
                                              <div className="mt-1 ml-1">
                                                <Tooltip
                                                  content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                  dismissOnMouseOut
                                                  borderRadius={1}
                                                  width="wide"
                                                >
                                                  <Icon
                                                    source={InfoIcon}
                                                    color="base"
                                                  />
                                                </Tooltip>
                                              </div>
                                              {/* <br />
                                      <span className="stock-warning-color">
                                        Only works with Inventory Management
                                        Setting #1 and Specific Pre-order Stock.
                                        This function might not work with every
                                        theme. After you turn this feature on,
                                        we suggest that you check your shop's
                                        "add to cart" and "checkout" functions.
                                      </span> */}
                                            </div>
                                            <div className="row pt-2">
                                              <TextField
                                                label="Not enough items left to pre-order message"
                                                value={
                                                  formik.values
                                                    .order_remain_message[index]
                                                }
                                                placeholder=""
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_remain_message.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                              />
                                              <div className="stock-warning-color">
                                                <p>
                                                  Note: We only have qty unit(s)
                                                  left. number of units will be
                                                  filled right away, and the
                                                  rest will have to be
                                                  pre-ordered.
                                                </p>
                                                <p className="font-12">
                                                  Variables: qty. Your Shopify
                                                  stock will replace the
                                                  amount..
                                                </p>
                                              </div>
                                            </div>
                                            <div className="pt-2">
                                              <div className="display-flex">
                                                <Checkbox
                                                  label="Tell people when a part of their order will be on pre-order."
                                                  checked={
                                                    formik.values
                                                      .notify_customers[index]
                                                  }
                                                  onChange={() =>
                                                    formik.setFieldValue(
                                                      `notify_customers.${index}`,
                                                      !formik.values
                                                        .notify_customers[index]
                                                    )
                                                  }
                                                />
                                                <div className="mt-1 ml-1">
                                                  <Tooltip
                                                    content={`Only for Setting #2 of Inventory Management. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions`}
                                                    dismissOnMouseOut
                                                    borderRadius={1}
                                                    width="wide"
                                                  >
                                                    <Icon
                                                      source={InfoIcon}
                                                      color="base"
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row pt-2">
                                              <TextField
                                                label="Not enough items left to pre-order message"
                                                value={
                                                  formik.values.order_message[
                                                    index
                                                  ]
                                                }
                                                placeholder=""
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_message.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                              />
                                              <div className="stock-warning-color">
                                                <p>
                                                  Note: We only have qty unit(s)
                                                  left. number of units will be
                                                  filled right away, and the
                                                  rest will have to be
                                                  pre-ordered.
                                                </p>
                                                <p className="font-12">
                                                  Variables: qty. Your Shopify
                                                  stock will replace the
                                                  amount..
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </BlockStack>
                                      </Card>
                                    </div>
                                    <div className="card-border-setting">
                                      <Card>
                                        <BlockStack gap={"200"}>
                                          <Text as="h1" variant="headingSm">
                                            Product page
                                          </Text>
                                          <Checkbox
                                            label="Hide the 'buy now' buttons when the current version is on pre-order."
                                            checked={
                                              formik.values.buy_now[index]
                                            }
                                            onChange={() =>
                                              formik.setFieldValue(
                                                `buy_now.${index}`,
                                                !formik.values.buy_now[index]
                                              )
                                            }
                                          />
                                        </BlockStack>
                                      </Card>
                                    </div>
                                    <div className="card-border-setting">
                                      <Card key={`mainsev-${index}`}>
                                        <BlockStack gap={"200"}>
                                          <Text as="h1" variant="headingSm">
                                            Cart label
                                          </Text>
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <TextField
                                                label="Cart label text"
                                                value={
                                                  formik.values.cart_label_text[
                                                    index
                                                  ]
                                                }
                                                placeholder="Pre-order item"
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `cart_label_text.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                                error={
                                                  formik.errors
                                                    .cart_label_text &&
                                                  formik.touched.cart_label_text
                                                    ? formik.errors
                                                        .cart_label_text
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </div>
                                        </BlockStack>
                                      </Card>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      <div className="text-end pt-3">
                        <Button
                          variant="primary"
                          onClick={() => formik.handleSubmit()}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Layout.Section>
                </Layout>
                <Footer />
              </Page>
            ) : productData && Object.keys(productData).length > 0 ? (
              <Page
                backAction={{
                  content: "Settings",
                  onAction: () => navigate("/single-product"),
                }}
                title={Object.keys(productData).length > 0 && productData.title}
              >
                <Layout>
                  <Layout.Section variant="oneThird">
                    <BlockStack gap="400">
                      <Card key={`cardmain`}>
                        <InlineStack
                          wrap={false}
                          gap={"200"}
                          blockAlign="center"
                        >
                          <div className="product-edit-img">
                            <img
                              src={
                                productImage && productImage?.src
                                  ? productImage?.src
                                  : `${noimg}`
                              }
                              alt="demo"
                              className="w-full h-auto"
                            />
                          </div>
                          <Text variant="bodyLg" as="p">
                            {Object.keys(productData).length > 0 &&
                              productData.title}
                          </Text>
                        </InlineStack>
                      </Card>
                      <Card padding="0">
                        <div className="custom-single-product-header-title-Variant pt-3 pb-3">
                          Variant
                        </div>
                        <ul
                          className="custom-single-product-header-title-Variant_ul"
                          style={{ marginBottom: "8px" }}
                        >
                          {productVariants &&
                            productVariants.map((x) => {
                              return (
                                <>
                                  <Box background="bg-fill-secondary">
                                    <li
                                      className={`product_variant_name ps-4 ${
                                        x.title == openTab
                                          ? "variant_active"
                                          : ""
                                      }`}
                                      onClick={() => setOpenTab(x.title)}
                                    >
                                      {x.title}
                                    </li>
                                  </Box>
                                </>
                              );
                            })}
                        </ul>
                      </Card>
                    </BlockStack>
                  </Layout.Section>
                  <Layout.Section>
                    <BlockStack gap="400">
                      <div className="card-border-setting-padding">
                        {productVariants &&
                          productVariants?.map((item, index) => {
                            return (
                              <>
                                <div
                                  className={
                                    item.title == openTab ? "d-block" : "d-none"
                                  }
                                >
                                  <Card>
                                    <BlockStack gap="400">
                                      <InlineStack
                                        blockAlign="center"
                                        align="space-between"
                                      >
                                        <Text as="h1" variant="headingMd">
                                          {item.title}
                                        </Text>
                                        {productVariants.length > 1 && (
                                          <Button
                                            onClick={() => {
                                              setVarientIndex(index);
                                              copyVarientsSetting(index);
                                            }}
                                            variant="primary"
                                          >
                                            Copy settings to all variants
                                          </Button>
                                        )}
                                      </InlineStack>
                                      <div className="border-top product-edit-variant-detail">
                                        <InlineGrid
                                          columns={{
                                            xs: 1,
                                            sm: 1,
                                            md: ["oneThird", "twoThirds"],
                                            lg: ["oneThird", "twoThirds"],
                                            xl: ["oneThird", "twoThirds"],
                                          }}
                                          gap={{ xs: "200", md: "400" }}
                                          alignItems="center"
                                        >
                                          <BlockStack gap="300">
                                            <Text as="h1" variant="headingSm">
                                              Variant details
                                            </Text>
                                            <p>
                                              If the details of the products in
                                              Pre-order Now aren't matching up
                                              with Shopify, click here to
                                              quickly fix it.
                                            </p>
                                          </BlockStack>
                                          <div className="inventory flex-grow-1">
                                            <div className="product-table">
                                              <Card
                                                padding="0"
                                                key={`mainfirst-${index}`}
                                              >
                                                <table className="w-100 table m-0">
                                                  <tbody>
                                                    <tr>
                                                      <th>
                                                        Keeping track of stock
                                                      </th>
                                                      <td>
                                                        {item.inventory_management !==
                                                        ""
                                                          ? "Enable"
                                                          : "Disable"}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th>
                                                        Status of Inventory
                                                      </th>
                                                      <td>
                                                        {
                                                          item.inventory_quantity
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th>Quantity Oversell</th>
                                                      <td>
                                                        {item.inventory_management !==
                                                        ""
                                                          ? "Enable"
                                                          : "Disable"}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </Card>
                                            </div>
                                          </div>
                                        </InlineGrid>
                                      </div>
                                      <InlineGrid
                                        gap="200"
                                        columns={{
                                          xs: 1,
                                          sm: 1,
                                          md: ["oneThird", "twoThirds"],
                                          lg: ["oneThird", "twoThirds"],
                                          xl: ["oneThird", "twoThirds"],
                                        }}
                                      >
                                        <Text as="h1" variant="headingSm">
                                          Enable
                                        </Text>
                                        <BlockStack gap="200">
                                          <Checkbox
                                            label="Enable pre-order for this variant"
                                            checked={checked[index]}
                                            onChange={() => handleCheck(index)}
                                            name="is_enable"
                                          />
                                          <InlineGrid
                                            columns={{
                                              xs: 1,
                                              sm: 1,
                                              md: 2,
                                              lg: 2,
                                              xl: 2,
                                            }}
                                          >
                                            <RadioButton
                                              label="Use default settings"
                                              checked={!settingType[index]}
                                              disabled={!checked[index]}
                                              onChange={(_checked, newValue) =>
                                                handleChange(
                                                  _checked,
                                                  newValue,
                                                  index
                                                )
                                              }
                                            />
                                            <RadioButton
                                              label="Use settings you choose."
                                              checked={settingType[index]}
                                              disabled={!checked[index]}
                                              onChange={(_checked, newValue) =>
                                                handleChange(
                                                  _checked,
                                                  newValue,
                                                  index
                                                )
                                              }
                                            />
                                          </InlineGrid>
                                        </BlockStack>
                                      </InlineGrid>
                                    </BlockStack>
                                  </Card>
                                  {checked[index] && settingType[index] && (
                                    <div className="row">
                                      {/* Button Card */}
                                      <div className="card-border-setting">
                                        <Card key={`cardtwo-${index}`}>
                                          <BlockStack gap={"200"}>
                                            <Text as="h1" variant="headingSm">
                                              Button
                                            </Text>
                                            <div className="row gy-2">
                                              <div className="col-lg-4">
                                                <TextField
                                                  label="Text on button "
                                                  value={
                                                    formik.values.text[index]
                                                  }
                                                  placeholder="Preorder now"
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `text.${index}`,
                                                      value
                                                    );
                                                  }}
                                                  autoComplete="off"
                                                  onBlur={formik.handleBlur}
                                                  error={
                                                    formik.errors.text &&
                                                    formik.touched.text
                                                      ? formik.errors.text
                                                      : ""
                                                  }
                                                />
                                              </div>
                                              <div className="col-lg-4 mob-pt-1">
                                                <TextField
                                                  label="Pre-order message"
                                                  value={
                                                    formik.values.message[index]
                                                  }
                                                  placeholder="hello"
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `message.${index}`,
                                                      value
                                                    );
                                                  }}
                                                  autoComplete="off"
                                                />
                                              </div>
                                              <div className="col-lg-4 mob-pt-1">
                                                <Select
                                                  label="message placements"
                                                  options={placementOptions}
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `message_placement.${index}`,
                                                      value
                                                    );
                                                  }}
                                                  value={
                                                    formik.values
                                                      .message_placement[index]
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </BlockStack>
                                        </Card>
                                      </div>
                                      {/* Stock Method Card */}
                                      <div className="card-border-setting">
                                        <Card key={`cardthird-${index}`}>
                                          <BlockStack gap={"200"}>
                                            <Text as="h1" variant="headingSm">
                                              Stock
                                            </Text>
                                            <Checkbox
                                              label="Select Inventory Level For Pre-Order Products"
                                              checked={
                                                formik.values.enable_inventary[
                                                  index
                                                ]
                                              }
                                              onChange={() =>
                                                formik.setFieldValue(
                                                  `enable_inventary.${index}`,
                                                  !formik.values
                                                    .enable_inventary[index]
                                                )
                                              }
                                            />
                                            {formik.values.enable_inventary[
                                              index
                                            ] && (
                                              <>
                                                
                                                <div>
                                                  <div className="border-bottom pb-2">
                                                    <RadioButton
                                                      label={
                                                        <div>
                                                          <strong>
                                                           Type 1:&nbsp;
                                                          </strong>
                                                          <span>
                                                            Quantity greater than 0.
                                                          </span>
                                                        </div>
                                                      }
                                                      checked={
                                                        formik.values.method[
                                                          index
                                                        ] === 0
                                                      }
                                                      id={`method_1.${index}`}
                                                      onChange={(
                                                        _checked,
                                                        newValue
                                                      ) => {
                                                        formik.setFieldValue(
                                                          `method.${index}`,
                                                          0
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="py-2">
                                                    <RadioButton
                                                      label={
                                                        <div>
                                                          <strong>
                                                           Type 2:&nbsp;
                                                          </strong>
                                                          <span>
                                                            Quantity less than or equal to 0.
                                                          </span>
                                                        </div>
                                                      }
                                                      checked={
                                                        formik.values.method[
                                                          index
                                                        ] === 1
                                                      }
                                                      id={`method_2.${index}`}
                                                      onChange={(
                                                        _checked,
                                                        newValue
                                                      ) =>
                                                        formik.setFieldValue(
                                                          `method.${index}`,
                                                          1
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="stock-note">
                                                  <p>
                                                    <strong>Note:&nbsp;</strong>
                                                    Enable "Continue When Selling Out of Stock" In Shopify Products For Type 2 Settings.
                                                  </p>
                                                </div>
                                              </>
                                            )}
                                          </BlockStack>
                                        </Card>
                                      </div>
                                      {/* Product Image Card */}
                                      {/* Pre-order partial payment Start Card */}
                                      <div className="card-border-setting">
                                        <Card
                                          sectioned
                                          key={`card-pp-${index}`}
                                        >
                                          <BlockStack gap={"200"}>
                                            <Text as="h1" variant="headingSm">
                                              Pre-order partial payment
                                            </Text>
                                            {/* <p style={{ marginBottom: '37px' }}>
                                            Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                          </p> */}
                                            <FormLayout>
                                              <div className="row pb-3">
                                                <div className="display-flex">
                                                  <Checkbox
                                                    name="partial_payment"
                                                    label="Partial payment"
                                                    checked={
                                                      formik.values
                                                        .partial_payment[index]
                                                    }
                                                    onChange={() =>
                                                      formik.setFieldValue(
                                                        `partial_payment.${index}`,
                                                        !formik.values
                                                          .partial_payment[
                                                          index
                                                        ]
                                                      )
                                                    }
                                                  />
                                                </div>
                                                {formik.values.partial_payment[
                                                  index
                                                ] && (
                                                  <>
                                                    <div className="d-flex pt-2 justify-content-between dpstAmntBox">
                                                      <div className="pp_deposit_amnt">
                                                        <TextField
                                                          name="pp_deposit_amount"
                                                          label="Deposit amount"
                                                          type="number"
                                                          value={
                                                            formik.values
                                                              .pp_Deposit_amount[
                                                              index
                                                            ]
                                                          }
                                                          onChange={(value) => {
                                                            const newValue =
                                                              parseInt(
                                                                value,
                                                                10
                                                              );
                                                            if (newValue < 0) {
                                                              formik.setFieldValue(
                                                                `pp_Deposit_amount.${index}`,
                                                                0
                                                              );
                                                            } else {
                                                              formik.setFieldValue(
                                                                `pp_Deposit_amount.${index}`,
                                                                newValue
                                                              );
                                                            }
                                                          }}
                                                          autoComplete="off"
                                                        />
                                                      </div>
                                                      <div className="pp_deposit_btns">
                                                        <ButtonGroup variant="segmented">
                                                          <Button
                                                            pressed={
                                                              formik.values
                                                                .pp_deposit_amount_type[
                                                                index
                                                              ] === 0
                                                            }
                                                            onClick={() => {
                                                              formik.setFieldValue(
                                                                `pp_deposit_amount_type.${index}`,
                                                                0
                                                              );
                                                            }}
                                                          >
                                                            %
                                                          </Button>
                                                          <Button
                                                            name
                                                            pressed={
                                                              formik.values
                                                                .pp_deposit_amount_type[
                                                                index
                                                              ] === 1
                                                            }
                                                            onClick={() => {
                                                              formik.setFieldValue(
                                                                `pp_deposit_amount_type.${index}`,
                                                                1
                                                              );
                                                            }}
                                                          >
                                                            $
                                                          </Button>
                                                        </ButtonGroup>
                                                      </div>
                                                    </div>
                                                    <div className="pt-2 display-flex">
                                                      <RadioButton
                                                        label="Fixed date"
                                                        checked={
                                                          ppOrderDateType[index]
                                                        }
                                                        onChange={(
                                                          _,
                                                          newValue
                                                        ) =>
                                                          handlePPOrderDateType(
                                                            _,
                                                            true,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    {ppOrderDateType[index] && (
                                                      <div
                                                        className="row"
                                                        style={{
                                                          paddingLeft: "40px",
                                                        }}
                                                      >
                                                        <div className="start_date mob-pb-1">
                                                          <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular ">
                                                            Collect Payment On
                                                          </span>
                                                          <Datetime
                                                            value={
                                                              formik.values
                                                                .pp_cp_fixed_date[
                                                                index
                                                              ]
                                                            }
                                                            onChange={(
                                                              value
                                                            ) => {
                                                              formik.setFieldValue(
                                                                `pp_cp_fixed_date.${index}`,
                                                                value._d
                                                              );
                                                            }}
                                                            renderInput={(
                                                              params
                                                            ) => (
                                                              <TextField
                                                                {...params}
                                                                readOnly
                                                              />
                                                            )}
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                    <div className="pt-2 display-flex">
                                                      <RadioButton
                                                        label="Calculate from order date"
                                                        checked={
                                                          !ppOrderDateType[
                                                            index
                                                          ]
                                                        }
                                                        onChange={(
                                                          _,
                                                          newValue
                                                        ) =>
                                                          handlePPOrderDateType(
                                                            _,
                                                            false,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    {!ppOrderDateType[
                                                      index
                                                    ] && (
                                                      <>
                                                        <div
                                                          className="row"
                                                          style={{
                                                            paddingLeft: "40px",
                                                          }}
                                                        >
                                                          <Text
                                                            variant="headingMd"
                                                            as="h6"
                                                          >
                                                            Collect Payment On
                                                          </Text>
                                                        </div>
                                                        <div
                                                          className="row"
                                                          style={{
                                                            paddingLeft: "40px",
                                                          }}
                                                        >
                                                          <Select
                                                            label="event"
                                                            options={
                                                              partial_payment_event_opts
                                                            }
                                                            value={
                                                              formik.values
                                                                .pp_cp_event[
                                                                index
                                                              ]
                                                            }
                                                            name="pp_cp_event"
                                                            onChange={(
                                                              value
                                                            ) => {
                                                              formik.setFieldValue(
                                                                `pp_cp_event.${index}`,
                                                                value
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        <div
                                                          className="row pt-2"
                                                          style={{
                                                            paddingLeft: "40px",
                                                          }}
                                                        >
                                                          <Select
                                                            label="days"
                                                            options={
                                                              partial_payment_days_opts
                                                            }
                                                            value={
                                                              formik.values
                                                                .pp_cp_days[
                                                                index
                                                              ]
                                                            }
                                                            name="pp_cp_days"
                                                            onChange={(
                                                              value
                                                            ) => {
                                                              formik.setFieldValue(
                                                                `pp_cp_days.${index}`,
                                                                value
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                        {formik.values
                                                          .pp_cp_days[index] ===
                                                          "5" && (
                                                          <div
                                                            className="row pt-2"
                                                            style={{
                                                              paddingLeft:
                                                                "40px",
                                                            }}
                                                          >
                                                            <TextField
                                                              label="collect after"
                                                              type="number"
                                                              name="pp_cp_collect_after"
                                                              value={
                                                                formik.values
                                                                  .pp_cp_collect_after[
                                                                  index
                                                                ]
                                                              }
                                                              onChange={(
                                                                value
                                                              ) => {
                                                                const newValue =
                                                                  parseInt(
                                                                    value,
                                                                    10
                                                                  );
                                                                if (
                                                                  newValue < 0
                                                                ) {
                                                                  formik.setFieldValue(
                                                                    `pp_cp_collect_after.${index}`,
                                                                    0
                                                                  );
                                                                } else {
                                                                  formik.setFieldValue(
                                                                    `pp_cp_collect_after.${index}`,
                                                                    newValue
                                                                  );
                                                                }
                                                              }}
                                                              autoComplete="off"
                                                            />
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </FormLayout>
                                          </BlockStack>
                                        </Card>
                                      </div>
                                      {/* Pre-order partial payment End Card */}
                                      {/* Pre-order Period Card */}
                                      <div className="card-border-setting z-1">
                                        <div
                                          className="date-picker-card-box"
                                          key={`cardsix-${index}`}
                                        >
                                          <BlockStack gap={"200"}>
                                            <Text as="h1" variant="headingSm">
                                              Pre-order period
                                            </Text>
                                            <div className="row ">
                                              <div>
                                                <p>Timezone: Asia/Kolkata</p>
                                                <p className="py-2">
                                                  The pre-order time tells you
                                                  when your pre-order settings
                                                  will turn on and/or off. If
                                                  you want your pre-order
                                                  settings to take effect right
                                                  away, leave the pre-order
                                                  start date blank or choose a
                                                  date in the past.
                                                </p>
                                              </div>
                                              <div className="col-lg-6 start_date mob-pb-1">
                                                <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular mob-pb-1">
                                                  Pre-order start date
                                                </span>
                                                <Datetime
                                                  value={
                                                    formik.values
                                                      .order_start_date[index]
                                                  }
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `order_start_date.${index}`,
                                                      value._d
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      readOnly
                                                    />
                                                  )}
                                                />
                                              </div>
                                              <div className="col-lg-6 end_date">
                                                <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                                                  Pre-order end date
                                                </span>
                                                <Datetime
                                                  value={
                                                    formik.values
                                                      .order_end_date[index]
                                                  }
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `order_end_date.${index}`,
                                                      value._d
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      readOnly
                                                    />
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </BlockStack>
                                        </div>
                                      </div>
                                      <div className="card-border-setting">
                                        <Card>
                                          <BlockStack gap={"200"}>
                                            <Text as="h1" variant="headingSm">
                                              A stock alert
                                            </Text>
                                            <div className="row">
                                              <div className="display-flex">
                                                <Checkbox
                                                  label="Prevent customers from ordering more than the pre-order stock available"
                                                  checked={
                                                    formik.values
                                                      .prevent_customers[index]
                                                  }
                                                  onChange={() =>
                                                    formik.setFieldValue(
                                                      `prevent_customers.${index}`,
                                                      !formik.values
                                                        .prevent_customers[
                                                        index
                                                      ]
                                                    )
                                                  }
                                                />
                                                <div className="mt-1 ml-1">
                                                  <Tooltip
                                                    content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                    dismissOnMouseOut
                                                    borderRadius={1}
                                                    width="wide"
                                                  >
                                                    <Icon
                                                      source={InfoIcon}
                                                      color="base"
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                              <div className="row pt-2">
                                                <TextField
                                                  label="Not enough items left to pre-order message"
                                                  value={
                                                    formik.values
                                                      .order_remain_message[
                                                      index
                                                    ]
                                                  }
                                                  placeholder=""
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `order_remain_message.${index}`,
                                                      value
                                                    );
                                                  }}
                                                  autoComplete="off"
                                                />
                                                <div className="stock-warning-color">
                                                  <p>
                                                    Note: We only have qty
                                                    unit(s) left. number of
                                                    units will be filled right
                                                    away, and the rest will have
                                                    to be pre-ordered.
                                                  </p>
                                                  <p className="font-12">
                                                    Variables: qty. Your Shopify
                                                    stock will replace the
                                                    amount.
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="pt-2">
                                                <div className="display-flex">
                                                  <Checkbox
                                                    label="Tell people when a part of their order will be on pre-order."
                                                    checked={
                                                      formik.values
                                                        .notify_customers[index]
                                                    }
                                                    onChange={() =>
                                                      formik.setFieldValue(
                                                        `notify_customers.${index}`,
                                                        !formik.values
                                                          .notify_customers[
                                                          index
                                                        ]
                                                      )
                                                    }
                                                  />
                                                  <div className="mt-1 ml-1">
                                                    <Tooltip
                                                      content={`Only works with Select Inventory Level For Pre-Order Products for Setting #2 . This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                      dismissOnMouseOut
                                                      borderRadius={1}
                                                      width="wide"
                                                    >
                                                      <Icon
                                                        source={InfoIcon}
                                                        color="base"
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row pt-2">
                                                <TextField
                                                  label="Not enough items left to pre-order message"
                                                  value={
                                                    formik.values.order_message[
                                                      index
                                                    ]
                                                  }
                                                  placeholder=""
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `order_message.${index}`,
                                                      value
                                                    );
                                                  }}
                                                  autoComplete="off"
                                                />
                                                <div className="stock-warning-color">
                                                  <p>
                                                    Note: We only have qty
                                                    unit(s) left. number of
                                                    units will be filled right
                                                    away, and the rest will have
                                                    to be pre-ordered.
                                                  </p>
                                                  <p className="font-12">
                                                    Variables: qty. Your Shopify
                                                    stock will replace the
                                                    amount.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </BlockStack>
                                        </Card>
                                      </div>
                                      <div className="card-border-setting">
                                        <Card>
                                          <BlockStack gap={"200"}>
                                            <Text as="h1" variant="headingSm">
                                              Product page
                                            </Text>
                                            <Checkbox
                                              label="Hide the 'buy now' buttons when the current version is on pre-order."
                                              checked={
                                                formik.values.buy_now[index]
                                              }
                                              onChange={() =>
                                                formik.setFieldValue(
                                                  `buy_now.${index}`,
                                                  !formik.values.buy_now[index]
                                                )
                                              }
                                            />
                                          </BlockStack>
                                        </Card>
                                      </div>
                                      {/* Label Card /> */}
                                      <div className="card-border-setting">
                                        <Card key={`cardeight-${index}`}>
                                          <BlockStack gap={"200"}>
                                            <Text as="h1" variant="headingSm">
                                              Cart label
                                            </Text>
                                            <div className="row">
                                              <div className="col-lg-12">
                                                <TextField
                                                  label="Cart label text"
                                                  value={
                                                    formik.values
                                                      .cart_label_text[index]
                                                  }
                                                  placeholder=""
                                                  onChange={(value) => {
                                                    formik.setFieldValue(
                                                      `cart_label_text.${index}`,
                                                      value
                                                    );
                                                  }}
                                                  autoComplete="off"
                                                  error={
                                                    formik.errors
                                                      .cart_label_text &&
                                                    formik.touched
                                                      .cart_label_text
                                                      ? formik.errors
                                                          .cart_label_text
                                                      : ""
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </BlockStack>
                                        </Card>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>
                      <div className="text-end">
                        <Button
                          variant="primary"
                          onClick={() => formik.handleSubmit()}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </BlockStack>
                  </Layout.Section>
                </Layout>
                <Footer />
              </Page>
            ) : null}
          </AppProvider>
        </>
      )}
      <div className="destructive_btn">
        <Modal id="confirm-modal">
          <TitleBar title="Confirm">
            <button
              variant="primary"
              onClick={() => handleModal(varientIndex, true)}
            >
              Yes
            </button>
            <button onClick={handleModal}>No</button>
          </TitleBar>
          <Box padding={"400"}>
            <p>
              Are you sure you'd like to apply these pre-order settings to all
              variants?
            </p>
          </Box>
        </Modal>
      </div>
      {toastMarkup}
    </>
  );
};

export default EditSingleProduct;
