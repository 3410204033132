import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  TextField,
  Page,
  Layout,
  FormLayout,
  Checkbox,
  Button,
  Toast,
  Tooltip,
  Icon,
  Box,
  InlineStack,
  Text,
  BlockStack,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { GetApiCall, ApiCall } from "../helpers/axios";
import {
  htmlSpecialCharacterEncode,
  htmlspecialchars_decode,
} from "../helpers/commonFunctions";
import Footer from "./Footer";
import Datetime from "react-datetime";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { InfoIcon } from "@shopify/polaris-icons";

const AdvancedSetting = () => {
  const navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [defaultSett, setDefaultSett] = useState("");

  const [initialState, setInitialState] = useState({
    /** Default setting**/
    order_start_date: "",
    order_end_date: "",
    prevent_customers: false,
    notify_customers: false,
    diplay_order_items: false,
    order_remain_message: "Not enough stock. There are only qty left.",
    order_message:
      "There are only qty unit(s) left. qty units will be shipped right away, and the rest will have to be pre-ordered.",
    cart_warning_title:
      "You have both pre-order and in-stock things in your shopping cart.",
    cart_warnig_text:
      "Your in-stock items may not ship right away until your pre-ordered item is ready. We suggest making two separate orders, one for your pre-order items and one for your in-stock items, so that your in-stock items arrive faster.",
    product_tag: "Pre-order",
    sync_inventary: false,
    fromSelector: "",
    buttonSelector: "",
    image_container_selector: "",
    buyNowBtnSelector: "",
    gird_container_selector: "",
  });

  const formSchema = Yup.object().shape({
    prevent_customers: Yup.boolean(),
    order_remain_message: Yup.string().when("prevent_customers", {
      is: true,
      then: Yup.string().required(
        "Not enough items left to pre-order message is required"
      ),
    }),
    notify_customers: Yup.boolean(),
    order_message: Yup.string().when("notify_customers", {
      is: true,
      then: Yup.string().required(
        "Not enough items left to pre-order message is required"
      ),
    }),
    diplay_order_items: Yup.boolean(),
    cart_warning_title: Yup.string().when("diplay_order_items", {
      is: true,
      then: Yup.string().required("Title for a mixed cart is required"),
    }),
    cart_warnig_text: Yup.string().when("diplay_order_items", {
      is: true,
      then: Yup.string().required("Mixed cart warning text is required"),
    }),
  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: () => {
      settingSave();
    },
  });

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={3000}
    />
  ) : null;

  const getAllDefaultsetting = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/settings?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        setSettingID(res?.data?.data[0].id);
        let default_settings = JSON.parse(res?.data?.data[0]?.default_settings);
        setDefaultSett(default_settings);
        if (default_settings) {
          formik.setFieldValue(
            "product_tag",
            default_settings.default_settings_product_tag
          );
          formik.setFieldValue(
            "order_start_date",
            default_settings.default_settings_order_start_date !== ""
              ? new Date(default_settings.default_settings_order_start_date)
              : ""
          );
          formik.setFieldValue(
            "order_end_date",
            default_settings.default_settings_order_end_date !== ""
              ? new Date(default_settings.default_settings_order_end_date)
              : ""
          );
          formik.setFieldValue(
            "prevent_customers",
            default_settings.default_settings_prevent_customers
          );
          formik.setFieldValue(
            "notify_customers",
            default_settings.default_settings_notify_customers
          );
          formik.setFieldValue(
            "diplay_order_items",
            default_settings.default_settings_diplay_order_items
          );
          formik.setFieldValue(
            "order_remain_message",
            default_settings.default_settings_order_remain_message
          );
          formik.setFieldValue(
            "order_message",
            default_settings.default_settings_order_message
          );
          formik.setFieldValue(
            "cart_warnig_text",
            default_settings.default_settings_cart_warnig_text
          );
          formik.setFieldValue(
            "cart_warning_title",
            default_settings.default_settings_cart_warning_title
          );
        }

        let advanced_settings = JSON.parse(
          res?.data?.data[0]?.advanced_settings
        );
        if (advanced_settings) {
          formik.setFieldValue(
            "fromSelector",
            htmlspecialchars_decode(
              advanced_settings.advanced_setting_fromSelector
            )
          );
          formik.setFieldValue(
            "buttonSelector",
            htmlspecialchars_decode(
              advanced_settings.advanced_setting_buttonSelector
            )
          );
          formik.setFieldValue(
            "image_container_selector",
            htmlspecialchars_decode(
              advanced_settings.advanced_setting_image_container_selector
            )
          );
          formik.setFieldValue(
            "buyNowBtnSelector",
            htmlspecialchars_decode(
              advanced_settings.advanced_setting_buyNowBtnSelector
            )
          );
          formik.setFieldValue(
            "gird_container_selector",
            htmlspecialchars_decode(
              advanced_settings.advanced_setting_gird_container_selector
            )
          );
        }
      } else if (
        res.data.status === "error" &&
        res.data.message === "Data not found!"
      ) {
        setSettingID(null);
      }
    }
  };

  useEffect(() => {
    getAllDefaultsetting();
  }, [store_client_id, shop_data]);

  const settingSave = async () => {
    setLoading(true);
    let data = {
      default_settings: {
        ...defaultSett,
        default_settings_product_tag:
          formik.values.product_tag !== undefined
            ? formik.values.product_tag
            : "",
        default_settings_order_start_date:
          formik.values.order_start_date !== undefined
            ? formik.values.order_start_date
            : "",
        default_settings_order_end_date:
          formik.values.order_end_date !== undefined
            ? formik.values.order_end_date
            : "",
        default_settings_prevent_customers:
          formik.values.prevent_customers !== undefined
            ? formik.values.prevent_customers
            : "",
        default_settings_notify_customers:
          formik.values.notify_customers !== undefined
            ? formik.values.notify_customers
            : "",
        default_settings_diplay_order_items:
          formik.values.diplay_order_items !== undefined
            ? formik.values.diplay_order_items
            : "",
        default_settings_order_remain_message:
          formik.values.order_remain_message !== undefined
            ? formik.values.order_remain_message
            : "",
        default_settings_order_message:
          formik.values.order_message !== undefined
            ? formik.values.order_message
            : "",
        default_settings_cart_warning_title:
          formik.values.cart_warning_title !== undefined
            ? formik.values.cart_warning_title
            : "",
        default_settings_cart_warnig_text:
          formik.values.cart_warnig_text !== undefined
            ? formik.values.cart_warnig_text
            : "",
      },
      advanced_settings: {
        advanced_setting_fromSelector:
          formik.values.fromSelector !== undefined
            ? htmlSpecialCharacterEncode(formik.values.fromSelector)
            : "",
        advanced_setting_buttonSelector:
          formik.values.buttonSelector !== undefined
            ? htmlSpecialCharacterEncode(formik.values.buttonSelector)
            : "",
        advanced_setting_image_container_selector:
          formik.values.image_container_selector !== undefined
            ? htmlSpecialCharacterEncode(formik.values.image_container_selector)
            : "",
        advanced_setting_buyNowBtnSelector:
          formik.values.buyNowBtnSelector !== undefined
            ? htmlSpecialCharacterEncode(formik.values.buyNowBtnSelector)
            : "",
        advanced_setting_gird_container_selector:
          formik.values.gird_container_selector !== undefined
            ? htmlSpecialCharacterEncode(formik.values.gird_container_selector)
            : "",
      },
    };
    if (settingId !== null) {
      data.id = settingId;
      let res = await ApiCall("PUT", "/settings", data, header);
      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
      }
    } else {
      data.store_client_id = store_client_id;
      let res = await ApiCall("POST", "/settings", data, header);
      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
      }
    }
  };

  return (
    <Page
      backAction={{
        content: "Settings",
        onAction: () => navigate("/setting"),
      }}
      title={"Advance Settings"}
    >
      <Layout>
        {/* <Layout.AnnotatedSection title="A stock alert">
          <Card>
            <FormLayout>
              <BlockStack gap={"200"}>
                <InlineStack gap={"100"} blockAlign="center">
                  <Checkbox
                    name="prevent_customers"
                    label="Customers shouldn't be able to buy more than the amount of pre-order stock."
                    checked={formik.values.prevent_customers}
                    onChange={() =>
                      formik.setFieldValue(
                        "prevent_customers",
                        !formik.values.prevent_customers
                      )
                    }
                  />
                  <Tooltip
                    content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                    dismissOnMouseOut
                    borderRadius={1}
                    width="wide"
                  >
                    <Icon source={InfoIcon} color="base" />
                  </Tooltip>
                </InlineStack>
                <TextField
                  name="order_remain_message"
                  label="Not enough items left to pre-order message"
                  helpText={
                    <>
                      <Text variant="bodyMd">
                        Note: We only have qty unit(s) left. number of units
                        will be filled right away, and the rest will have to be
                        pre-ordered.
                      </Text>
                      <Text variant="bodySm">
                        Variables: qty. Your Shopify stock will replace the
                        amount..
                      </Text>
                    </>
                  }
                  value={formik.values.order_remain_message}
                  placeholder=""
                  onChange={(value) => {
                    formik.setFieldValue("order_remain_message", value);
                  }}
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.order_remain_message &&
                    formik.touched.order_remain_message
                      ? formik.errors.order_remain_message
                      : ""
                  }
                />
                <InlineStack gap={"100"} blockAlign="center">
                  <Checkbox
                    name="notify_customers"
                    label="Tell people when a part of their order will be on pre-order."
                    checked={formik.values.notify_customers}
                    onChange={() =>
                      formik.setFieldValue(
                        "notify_customers",
                        !formik.values.notify_customers
                      )
                    }
                  />
                  <Tooltip
                    content={`Only for Setting #2 of Inventory Management. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions`}
                    dismissOnMouseOut
                    borderRadius={1}
                    width="wide"
                  >
                    <Icon source={InfoIcon} color="base" />
                  </Tooltip>
                </InlineStack>
                <TextField
                  name="order_message"
                  label="Not enough items left to pre-order message"
                  helpText={
                    <>
                      <Text as="p" variant="bodyMd">
                        Note: We only have qty unit(s) left. number of units
                        will be filled right away, and the rest will have to be
                        pre-ordered.
                      </Text>
                      <Text as="p" variant="bodySm">
                        Variables: qty. Your Shopify stock will replace the
                        amount..
                      </Text>
                    </>
                  }
                  value={formik.values.order_message}
                  placeholder=""
                  onChange={(value) => {
                    formik.setFieldValue("order_message", value);
                  }}
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.order_message && formik.touched.order_message
                      ? formik.errors.order_message
                      : ""
                  }
                />
              </BlockStack>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection> */}
        <Layout.AnnotatedSection title="A mixed cart alert">
          <Card>
            <FormLayout>
              <Checkbox
                name="diplay_order_items"
                label="Show a pop-up warning on the cart page when a customer has both items that need to be ordered and items that are already in stock."
                checked={formik.values.diplay_order_items}
                onChange={() =>
                  formik.setFieldValue(
                    "diplay_order_items",
                    !formik.values.diplay_order_items
                  )
                }
              />
              <TextField
                name="cart_warning_title"
                label="Title for a mixed cart"
                helpText="You have both pre-order and in-stock things in your shopping
                    cart."
                value={formik.values.cart_warning_title}
                placeholder=""
                onChange={(value) => {
                  formik.setFieldValue("cart_warning_title", value);
                }}
                autoComplete="off"
                onBlur={formik.handleBlur}
                error={
                  formik.errors.cart_warning_title &&
                  formik.touched.cart_warning_title
                    ? formik.errors.cart_warning_title
                    : ""
                }
              />
              <TextField
                name="cart_warnig_text"
                label="Mixed cart warning text"
                multiline={4}
                value={formik.values.cart_warnig_text}
                placeholder=""
                onChange={(value) => {
                  formik.setFieldValue("cart_warnig_text", value);
                }}
                autoComplete="off"
                onBlur={formik.handleBlur}
                error={
                  formik.errors.cart_warnig_text &&
                  formik.touched.cart_warnig_text
                    ? formik.errors.cart_warnig_text
                    : ""
                }
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Order management">
          <Card>
            <TextField
              label="Add this tag to sales that include pre-order items."
              value={formik.values.product_tag}
              placeholder="Pre-order"
              onChange={(value) => {
                formik.setFieldValue("product_tag", value);
              }}
              autoComplete="off"
            />
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Pre-order period">
          <Card>
            <BlockStack gap={"200"}>
              <p>Timezone: Asia/Kolkata</p>
              <p>
                The pre-order time tells you when your pre-order settings will
                turn on and/or off. If you want your pre-order settings to take
                effect right away, leave the pre-order start date blank or
                choose a date in the past.
              </p>
              <FormLayout>
                <FormLayout.Group condensed>
                  <div className="start_date">
                    <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                      Pre-order start date
                    </span>
                    <Datetime
                      value={formik.values.order_start_date}
                      onChange={(value) => {
                        formik.setFieldValue("order_start_date", value._d);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} readOnly />
                      )}
                    />
                  </div>
                  <div className="end_date">
                    <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                      Pre-order end date
                    </span>
                    <Datetime
                      value={formik.values.order_end_date}
                      onChange={(value) => {
                        formik.setFieldValue("order_end_date", value._d);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} readOnly />
                      )}
                    />
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Selectors">
          <Card>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField
                  label={`Form selector`}
                  value={formik.values.fromSelector}
                  onChange={(value) => {
                    formik.setFieldValue("fromSelector", value);
                  }}
                  autoComplete="off"
                  helpText="Example: form[action*='/cart/add']"
                />
                <TextField
                  label="Product page image container selector"
                  value={formik.values.image_container_selector}
                  onChange={(value) => {
                    formik.setFieldValue("image_container_selector", value);
                  }}
                  autoComplete="off"
                  helpText="Example: modal-opener"
                />
              </FormLayout.Group>
              <FormLayout.Group condensed>
                <TextField
                  label="Add to cart Button selector"
                  value={formik.values.buttonSelector}
                  onChange={(value) => {
                    formik.setFieldValue("buttonSelector", value);
                  }}
                  autoComplete="off"
                  helpText="Example: form[action*='/cart/add'] button[type='submit']...."
                />
                <TextField
                  label="Buy now button selector"
                  value={formik.values.buyNowBtnSelector}
                  onChange={(value) => {
                    formik.setFieldValue("buyNowBtnSelector", value);
                  }}
                  autoComplete="off"
                  helpText="Example: form[action*='/cart/add'] .shopify-payment-button"
                />
              </FormLayout.Group>
              <FormLayout.Group condensed>
                <TextField
                  label="Collection page grid container selector"
                  value={formik.values.gird_container_selector}
                  onChange={(value) => {
                    formik.setFieldValue("gird_container_selector", value);
                  }}
                  autoComplete="off"
                  helpText="ul#product-grid li.grid__item"
                />
                <div className="col-sm-6"></div>
              </FormLayout.Group>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      <Box paddingBlockStart={"300"}>
        <InlineStack align="end">
          <Button
            onClick={formik.handleSubmit}
            variant="primary"
            loading={loading}
          >
            Save
          </Button>
        </InlineStack>
      </Box>
      <Footer />
      {toastMarkup}
    </Page>
  );
};

export default AdvancedSetting;
