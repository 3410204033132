import React from 'react';
import {
  Checkbox,
  Box,
  Button,
  Text,
  BlockStack,
  ButtonGroup,
  InlineGrid,
  } from "@shopify/polaris";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { methodSelectState } from "../../store/onboardingAtom";
  
const SelectionMethod = ({handleNext}) => {
  const selectMethod = [
    {
      id: 1,
      option: "Pre-order",
      detail:
        "Boost revenue with the Pre-Order widget, enabling merchants to drive sales through pre-sales.",
      primary: "View Report",
      secondary: "Setting",
    },
    {
      id: 2,
      option: "Back-in-stock",
      detail:
        "Everything's set! Check your reports and enhance your campaigns.",
      primary: "View Report",
      secondary: "Setting",
    },
  ];

  const [checkedBox, setCheckedBox] = useRecoilState(methodSelectState);

  const handleChange = (id) => {
    if (checkedBox.includes(id)) {
      setCheckedBox(checkedBox.filter((b) => b !== id)); // Corrected filter condition
    } else {
      setCheckedBox([...checkedBox, id]); // Using spread operator for immutability
    }
  };

  return (
    <Box padding={400}>
      <div className='step-2-layout'>
        <BlockStack gap={400} align={"space-between"}>
          <BlockStack gap={200}>
            <h2 className="heading-size-16">Select the ones to activate.</h2>
            <Text variant="bodyMd" as="p">
              Later, you can customize, disable, or add these widgets in your
              panel.
            </Text>
          </BlockStack>
          <InlineGrid columns={{ sm: "1", md: "2" }} gap={200}>
            {selectMethod.map((method) => (
              <div
                key={method.id}
                className={`${
                  checkedBox.includes(method.id)
                    ? "checked-selection-method"
                    : "unchecked-selection-method "
                }`}
              >
                <Box padding={400}>
                  <Checkbox
                    label={method.option}
                    checked={checkedBox.includes(method.id)} // Ensure default to false if undefined
                    onChange={() => handleChange(method.id)}
                  />
                  <Box paddingInlineStart={"600"}>
                    <BlockStack gap={400}>
                      <Text tone="subdued" as="p">
                        {method.detail}
                      </Text>
                    </BlockStack>
                  </Box>
                </Box>
              </div>
            ))}
          </InlineGrid>
        </BlockStack>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button variant="primary" disabled={checkedBox.length === 0} onClick={handleNext}>Next</Button>
          </div>
      </div>
    </Box>
  );
}

export default SelectionMethod;