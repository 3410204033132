import { useState, useCallback, useEffect } from "react";
import ToggleButton, {
  Link as ToggleButtonLink,
} from "../ToggleBtn/ToggleButton";
import PricingPlanCard from "../PricingCard/PricingPlanCard";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { host } from "../../config";
import { ApiCall } from "../../helpers/axios";

const PricingPlan = ({ handleNext }) => {
  const [isChecked, setChecked] = useState(false);
  const toggleCheck = () => {
    setChecked(!isChecked);
  };

  const [selected, setSelected] = useState(0);
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const shop_data = shopinfo.shopInfo;
  const charge_approve = shop_data.is_charge_approve;
  const plan_type = shop_data.plan_type;
  const shop_plan = shop_data.shop_plan;
  const created_on = new Date(shop_data.created_on);
  const newPlanDate = new Date("2025-02-21T07:06:38Z");
  const navigate = useNavigate();
  const [planButtonTextMonth, setplanButtonTextMonth] = useState("Upgrade");
  const [planButtonTextYear, setplanButtonTextYear] = useState("Upgrade");

  let plan_name, plan_price_month, plan_price_year;
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );
  console.log(plan_type);
  const tabs = [
    {
      id: "Monthly-fitted-2",
      content: "Monthly",
      accessibilityLabel: "Monthly plan",
      panelID: "Monthly-fitted-content-2",
    },
    {
      id: "Yearly-fitted-2",
      content: "Yearly",
      panelID: "Yearly-fitted-Ccontent-2",
    },
  ];

  useEffect(() => {
    if (Number(plan_type) === 1) {
      setplanButtonTextMonth("Activated");
      setSelected(0);
    } else if (Number(plan_type) === 2) {
      setplanButtonTextYear("Activated");
      setSelected(1);
    }
  }, [plan_type]);

  const getCharges = async (value, price) => {
    let data = {
      plan_type: value,
      shop_info: shop_data,
      price: price,
      host,
    };
    let res = await ApiCall("POST", `/recurring`, data, header);
    if (res.status == 200) {
      if (window.location.hostname !== "localhost") {
        window.parent.location.href = res?.data.data;
      }
    }
  };
  const downgradeCharges = async () => {
    let data = {
      shop_info: shop_data,
      host,
    };
    if (Number(plan_type) !== 0) {
      let res = await ApiCall("DELETE", `/payment_downgrade`, data, header);
      if (res.status === 200) {
        handleNext(res.data);
      }
    } else {
      handleNext(null);
    }
  };

  if (created_on >= newPlanDate) {
    if (shop_plan === "shopify_plus" || shop_plan === "enterprise") {
      plan_name = "Shopify Plus";
      plan_price_month = "39.99";
      plan_price_year = "383.99";
    }
    else if (shop_plan === "advanced" || shop_plan === "unlimited" || shop_plan === "singtel_unlimited") {
      plan_name = "Shopify Advanced";
      plan_price_month = "29.99";
      plan_price_year = "287.99";
    }
    else if (shop_plan === "standard" || shop_plan === "professional" || shop_plan === "uafrica_professional" || shop_plan === "shopify") {
      plan_name = "Shopify Standard";
      plan_price_month = "19.99";
      plan_price_year = "191.99";
    } else if (shop_plan === "basic" || shop_plan === "singtel_basic") {
      plan_name = "Shopify Basic";
      plan_price_month = "9.99";
      plan_price_year = "95.99";
    } else {
      plan_name = "Shopify Basic";
      plan_price_month = "9.99";
      plan_price_year = "95.99";
    }
  } else {
    if (shop_plan === "shopify_plus" || shop_plan === "enterprise") {
      plan_name = "Shopify Plus";
      plan_price_month = "99.99";
      plan_price_year = "959.99";
    }
    else if (shop_plan === "advanced" || shop_plan === "unlimited" || shop_plan === "singtel_unlimited") {
      plan_name = "Shopify Advanced";
      plan_price_month = "59.99";
      plan_price_year = "575.99";
    }
    else if (shop_plan === "standard" || shop_plan === "professional" || shop_plan === "uafrica_professional" || shop_plan === "shopify") {
      plan_name = "Shopify Standard";
      plan_price_month = "39.99";
      plan_price_year = "383.99";
    } else if (shop_plan === "basic" || shop_plan === "singtel_basic") {
      plan_name = "Shopify Basic";
      plan_price_month = "19.99";
      plan_price_year = "191.99";
    } else {
      plan_name = "Shopify Basic";
      plan_price_month = "19.99";
      plan_price_year = "191.99";
    }
  }

  return (
    <div className="p-4 w-100">
      <div className="m-auto">
        <div className="d-flex gap-2 justify-content-center mb-2">
          <p
            className={`${!isChecked ? "active-plan-is" : "inactive-plan-is"}`}
          >
            MONTHLY
          </p>
          <ToggleButton isChecked={isChecked} handleCheckChange={toggleCheck} />
          <p className={isChecked ? "active-plan-is" : "inactive-plan-is"}>
            YEARLY
          </p>
        </div>
        {isChecked && 
        <div className='m-auto' style={{maxWidth:'352px'}}>
          <PricingPlanCard
            tagTitle="Free for 14 days"
            planType={plan_name}
            trileTime="Free for 14 days"
            planPrice={plan_price_year}
            planDuration='year'
            feature1='Unlimited Products'
            feature2='Unlimited Orders'
            feature3='Partial Payment'
            feature4='Mixed Cart Warning'
            feature5='Preorder with Tags'
            feature6='Badges on Collection Pages'
            feature7='24 x 7 Support'
            feature8={shop_plan === "shopify_plus" ? 'Advanced Analytics' : undefined}
            feature9={shop_plan === "shopify_plus" ? 'Dedicated Account Manager' : undefined}
            planButton={planButtonTextYear}
            onButtonClick={() => getCharges("ANNUAL",plan_price_year)}
          />
        </div>}
        {!isChecked &&
        <div className='m-auto' style={{maxWidth:'352px'}}>
          <PricingPlanCard
            tagTitle="Free for 14 days"
            planType={plan_name}
            trileTime="Free for 14 days"
            planPrice={plan_price_month}
            planDuration='month'
            feature1='Unlimited Products'
            feature2='Unlimited Orders'
            feature3='Partial Payment'
            feature4='Mixed Cart Warning'
            feature5='Preorder with Tags'
            feature6='Badges on Collection Pages'
            feature7='24 x 7 Support'
            feature8={shop_plan === "shopify_plus" ? 'Advanced Analytics' : undefined}
            feature9={shop_plan === "shopify_plus" ? 'Dedicated Account Manager' : undefined}
            planButton={planButtonTextYear}
            onButtonClick={() => getCharges("EVERY_30_DAYS",plan_price_month)}
          />
        </div>}
      </div>
      <a href="#" onClick={downgradeCharges}>
        Continue as free
      </a>
    </div>
  );
};

export default PricingPlan;
export const links = () => {
  return [...ToggleButtonLink()];
};
