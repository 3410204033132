import { useEffect, useCallback, useState } from "react";
import {
  Page,
  Checkbox,
  TextField,
  RadioButton,
  Button,
  Toast,
  Badge,
  FormLayout,
  Select,
  AppProvider,
  BlockStack,
  Card,
  Text,
} from "@shopify/polaris";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector, useDispatch } from "react-redux";
import { setTagsData } from "../redux/TagSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  htmlSpecialCharacterEncode,
  htmlspecialchars_decode,
} from "../helpers/commonFunctions";
import Footer from "./Footer";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { TitleBar } from "@shopify/app-bridge-react";
import React from "react";
import { lazy } from "react";

const LazyDatetime = lazy(() => import("react-datetime"));

const AddTag = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const tag_data = useSelector((state) => state.TagReducer.tag_data);
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };

  const ribbonOptions = [
    { label: "Ribbon", value: "0" },
    { label: "Rectangle", value: "1" },
    { label: "Circle", value: "2" },
  ];

  const [initialState] = useState({
    tag_Status: false,
    tagname: "",
    tag_method: "0",
    text: "Preorder now",
    message: "hello",
    message_placement: "1",
    enable_inventary: false,
    method: 0,
    badge_enabled: true,
    badge_shape: 0,
    badge_text: "Ribbon",
    order_start_date: "",
    order_end_date: "",
    cart_label_text: "pre-order item",
  });

  const getTagData = async (id) => {
    let res = await GetApiCall(
      "GET",
      `/get-tag?store_client_id=${shop_data.store_client_id}&tag_id=${id}`,
      header
    );
    if (res.data.statusCode === 200 && res.data.status === "success") {
      dispatch(setTagsData(res.data.data[0]));
    }
  };

  useEffect(() => {
    if (id && tag_data.length === 0 && Object.keys(shop_data).length > 0) {
      getTagData(id);
    }
  }, [id, shop_data.store_client_id]);

  const formSchema = Yup.object().shape({
    tagname: Yup.string().required("Tag is required"),
    text: Yup.string().required("Pre-order button text is required"),
    badge_enabled: Yup.boolean(),
    badge_text: Yup.string().when("badge_enabled", {
      is: true,
      then: Yup.string().required("Badge text is required"),
    }),
    cart_label_text: Yup.string().required("Cart label words is required"),
  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        handleUpdate(values);
      } else {
        handleSave(values);
      }
    },
  });

  const placementOptions = [
    { label: "Below Add to Cart Button", value: "1" },
    { label: "Above Add to Cart Button", value: "2" },
  ];
  const handleSave = async (values) => {
    let setting = {
      pre_order_button_text: htmlSpecialCharacterEncode(values.text),
      pre_order_btn_message: htmlSpecialCharacterEncode(values.message),
      pre_order_btn_msg_placement: values.message_placement,
      stock: values.enable_inventary === false ? "0" : "1",
      stock_type: values.method,
      product_image_badge: values.badge_enabled === false ? "0" : "1",
      badge_shape: values.badge_shape,
      badge_text: htmlSpecialCharacterEncode(values.badge_text),
      pre_order_start_date: values.order_start_date,
      pre_order_end_date: values.order_end_date,
      cart_label_text: htmlSpecialCharacterEncode(values.cart_label_text),
    };
    const data = {
      status: values.tag_Status === false ? "0" : "1",
      tag_name: htmlSpecialCharacterEncode(values.tagname),
      is_custome_setting: values.tag_method,
    };
    if (values.tag_method === "1") {
      data.tag_settings = setting;
    }
    if (values.tag_Status === true) {
      const res = await ApiCall("POST", "/add-tag", data, header);
      if (res && res.data !== undefined) {
        if (res.data.statusCode === 200 && res.data.status === "success") {
          setToastMessage("Tag added successfully");
          toggleActiveToast();
          handleDiscard();
          setTimeout(() => navigate("/tags"), 2000);
        }
      } else {
        setToastMessage("Tag already exist or something went wrong");
        toggleActiveToast();
      }
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    let setting = {
      pre_order_button_text: htmlSpecialCharacterEncode(values.text),
      pre_order_btn_message: htmlSpecialCharacterEncode(values.message),
      pre_order_btn_msg_placement: values.message_placement,
      stock: values.enable_inventary === false ? "0" : "1",
      stock_type: values.method,
      product_image_badge: values.badge_enabled === false ? "0" : "1",
      badge_shape: values.badge_shape,
      badge_text: htmlSpecialCharacterEncode(values.badge_text),
      pre_order_start_date: values.order_start_date,
      pre_order_end_date: values.order_end_date,
      cart_label_text: htmlSpecialCharacterEncode(values.cart_label_text),
    };
    const data = {
      id: id,
      status: values.tag_Status === false ? "0" : "1",
      is_custome_setting: values.tag_method,
    };
    if (values.tag_method === "1") {
      data.tag_settings = setting;
    }
    let response = await ApiCall("PUT", "/update-tag", data, header);
    if (
      response.data.statusCode === 200 &&
      response.data.status === "success"
    ) {
      navigate("/tags");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      formik.setFieldValue(
        "tagname",
        tag_data?.tag_name ? htmlspecialchars_decode(tag_data?.tag_name) : ""
      );
      formik.setFieldValue(
        "tag_Status",
        tag_data?.status === "1" ? true : false
      );
      formik.setFieldValue("tag_method", tag_data?.is_custome_setting);
      formik.setFieldValue(
        "text",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? htmlspecialchars_decode(
              JSON.parse(tag_data.tag_settings).pre_order_button_text
            )
          : "Preorder now"
      );
      formik.setFieldValue(
        "message",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? htmlspecialchars_decode(
              JSON.parse(tag_data.tag_settings).pre_order_btn_message
            )
          : "hello"
      );
      formik.setFieldValue(
        "message_placement",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? JSON.parse(tag_data.tag_settings).pre_order_btn_msg_placement
          : "1"
      );
      formik.setFieldValue(
        "enable_inventary",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? JSON.parse(tag_data.tag_settings).stock === "0"
            ? false
            : true
          : false
      );
      formik.setFieldValue(
        "method",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? JSON.parse(tag_data.tag_settings).stock_type
          : "0"
      );
      formik.setFieldValue(
        "badge_enabled",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? JSON.parse(tag_data.tag_settings).product_image_badge === "0"
            ? false
            : true
          : true
      );
      formik.setFieldValue(
        "badge_shape",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? JSON.parse(tag_data.tag_settings).badge_shape
          : "0"
      );
      formik.setFieldValue(
        "badge_text",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? htmlspecialchars_decode(
              JSON.parse(tag_data.tag_settings).badge_text
            )
          : "Ribbon"
      );
      formik.setFieldValue(
        "order_start_date",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? JSON.parse(tag_data.tag_settings).pre_order_start_date
          : ""
      );
      formik.setFieldValue(
        "order_end_date",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? JSON.parse(tag_data.tag_settings).pre_order_end_date
          : ""
      );
      formik.setFieldValue(
        "cart_label_text",
        tag_data.tag_settings && tag_data.tag_settings !== ""
          ? htmlspecialchars_decode(
              JSON.parse(tag_data.tag_settings).cart_label_text
            )
          : "Pre-order item"
      );
    }
  }, [id, tag_data]);

  const handleDiscard = () => {
    formik.handleReset();
  };

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={2000}
    />
  ) : null;

  const productPreviewImage = "https://appbackend.preorderbat.com/static/media/preorder_img.94eb138502b56e761554.jpg";

  return (
    <AppProvider>
      <TitleBar
        title={location.pathname.includes("edit") ? "Edit tags" : "New tags"}
      >
        <button variant={"breadcrumb"} onClick={() => navigate("/tags")}>
          Tags
        </button>
      </TitleBar>
      <Page
        backAction={{
          content: "Settings",
          onAction: () => navigate("/tags"),
        }}
        title={
          location.pathname.includes("edit")
            ? formik.values.tagname
            : "Add tags"
        }
      >
        <Card>
          <BlockStack gap={"400"}>
            <Text as="h1" variant="headingSm">
              Enable
            </Text>
            <div className="d-flex mob-flex-column">
              <div className="col-sm-6">
                <Checkbox
                  label="Set this tag on pre-ordering"
                  checked={formik.values.tag_Status}
                  onChange={() =>
                    formik.setFieldValue(
                      "tag_Status",
                      !formik.values.tag_Status
                    )
                  }
                />
              </div>
              {!location.pathname.includes("edit") ? (
                <div className="col-sm-6">
                  <TextField
                    name="tagname"
                    label="Tag"
                    value={formik.values.tagname}
                    disabled={formik.values.tag_Status === true ? false : true}
                    placeholder="Pre-order-product"
                    onChange={(value) => {
                      formik.setFieldValue("tagname", value);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.tagname && formik.touched.tagname
                        ? formik.errors.tagname
                        : ""
                    }
                  />
                </div>
              ) : (
                <div>
                  {formik.values.tag_Status === false ? (
                    <Badge status="critical">Pre-order Disable</Badge>
                  ) : (
                    <Badge status="success">Pre-order Enabled</Badge>
                  )}
                </div>
              )}
            </div>
            <div className="d-flex mob-flex-column">
              <div className="col-sm-6">
                <RadioButton
                  label="Use default settings"
                  checked={formik.values.tag_method === "0"}
                  id="0"
                  name="defaultsettings"
                  onChange={(_checked, newValue) =>
                    formik.setFieldValue("tag_method", newValue)
                  }
                  disabled={formik.values.tag_Status === true ? false : true}
                />
              </div>
              <div className="col-sm-6">
                <RadioButton
                  label="Use settings you choose."
                  checked={formik.values.tag_method === "1"}
                  id="1"
                  name="defaultsettings"
                  onChange={(_checked, newValue) =>
                    formik.setFieldValue("tag_method", newValue)
                  }
                  disabled={formik.values.tag_Status === true ? false : true}
                />
              </div>
            </div>
            {formik.values.tag_method === "0" ||
            formik.values.tag_Status === false ? (
              <p>
                <b>Note:</b> for this feature to work, you must set "Inventory
                policy" to "Shopify tracks this product's inventory" and set an
                inventory quantity in your shopify product settings. for method
                2 to work, you must check the box "Allow customers to purchase
                this product when it's out of stock" in your shopify product
                settings.
              </p>
            ) : (
              ""
            )}
            {formik.values.tag_method === "1" &&
            formik.values.tag_Status === true ? (
              <>
                <BlockStack gap={"200"}>
                  <Text as="h1" variant="headingXs">
                    Button
                  </Text>
                  <FormLayout>
                    <FormLayout.Group>
                      <TextField
                        name="text"
                        label="Text on button"
                        value={formik.values.text}
                        placeholder="Preorder now"
                        onChange={(value) => {
                          formik.setFieldValue("text", value);
                        }}
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors.text && formik.touched.text
                            ? formik.errors.text
                            : ""
                        }
                      />
                      <TextField
                        label="Pre-order message"
                        value={formik.values.message}
                        placeholder="Preorder message"
                        onChange={(value) => {
                          formik.setFieldValue("message", value);
                        }}
                        autoComplete="off"
                      />
                      <Select
                        label="Pre-order message places"
                        options={placementOptions}
                        onChange={(value) => {
                          formik.setFieldValue("message_placement", value);
                        }}
                        value={formik.values.message_placement}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </BlockStack>
                <BlockStack gap={"200"}>
                  <Text as="h1" variant="headingXs">
                    Stock
                  </Text>
                  <FormLayout>
                    <FormLayout.Group>
                      <Checkbox
                        label="Enable inventory management"
                        checked={formik.values.enable_inventary}
                        onChange={() =>
                          formik.setFieldValue(
                            "enable_inventary",
                            !formik.values.enable_inventary
                          )
                        }
                      />
                    </FormLayout.Group>
                  </FormLayout>
                  {formik.values.enable_inventary && (
                    <>
                      <div>
                        <div className="border-bottom">
                          <RadioButton
                            label={
                              <div>
                                <strong>Type 1:&nbsp;</strong>
                                <span>
                                  Quantity greater than 0.
                                </span>
                              </div>
                            }
                            checked={formik.values.method === 0}
                            id="stock1"
                            onChange={(_checked, newValue) => {
                              formik.setFieldValue("method", 0);
                            }}
                          />
                        </div>
                        <div className="pt-2">
                          <RadioButton
                            label={
                              <div>
                                <strong>
                                   Type 2:&nbsp;
                                </strong>
                                <span>
                                  Quantity less than or equal to 0.
                                </span>
                            </div>
                            }
                            checked={formik.values.method === 1}
                            id="stock2"
                            onChange={(_checked, newValue) =>
                              formik.setFieldValue("method", 1)
                            }
                          />
                        </div>
                      </div>
                      <div className="stock-note">
                      <p>
                        <strong>Note:&nbsp;</strong>
                         Enable "Continue When Selling Out of Stock" In Shopify Products For Type 2 Settings.
                      </p>
                      </div>
                    </>
                  )}
                </BlockStack>
                <BlockStack gap={"200"}>
                  <Text as="h1" variant="headingXs">
                    Product image badge
                  </Text>
                  <div className="row">
                    <div className="col-lg-4 product-img-badge">
                      <img
                        src={productPreviewImage}
                        loading="lazy"
                        alt="Product preview"
                        width="300"
                        height="300"
                      />
                      {formik.values.badge_enabled ? (
                        <>
                          {formik.values.badge_shape == 0 ? (
                            <div className="preorder-badge-ribbon">
                              <span className="badge_shape">
                                {formik.values.badge_text}
                              </span>
                            </div>
                          ) : formik.values.badge_shape == 1 ? (
                            <div className="preorder-badge-rectangle badge_shape">
                              <span>{formik.values.badge_text}</span>
                            </div>
                          ) : (
                            <div className="preorder-badge-circle badge_shape">
                              <span>{formik.values.badge_text}</span>
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-8">
                      <div className="pb-2 mob-pt-1">
                        <Checkbox
                          name="badge_enabled"
                          label="Badge enabled"
                          checked={formik.values.badge_enabled}
                          onChange={() =>
                            formik.setFieldValue(
                              "badge_enabled",
                              !formik.values.badge_enabled
                            )
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <Select
                            label="Badge shape"
                            options={ribbonOptions}
                            onChange={(value) => {
                              formik.setFieldValue("badge_shape", value);
                            }}
                            value={formik.values.badge_shape}
                            disabled={!formik.values.badge_enabled}
                          />
                        </div>
                        <div className="col-sm-6 mob-pt-1">
                          <TextField
                            name="badge_text"
                            label="Badge text"
                            value={formik.values.badge_text}
                            placeholder="Badge text"
                            maxLength={15}
                            onChange={(value) => {
                              formik.setFieldValue("badge_text", value);
                            }}
                            autoComplete="off"
                            disabled={!formik.values.badge_enabled}
                            onBlur={formik.handleBlur}
                            error={
                              formik.errors.badge_text &&
                              formik.touched.badge_text
                                ? formik.errors.badge_text
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <p>
                          <strong>Badge doesn't show up?</strong>
                          For this feature to work with your theme, it may need
                          to be set up in a certain way. Contact support if you
                          need help.
                        </p>
                      </div>
                    </div>
                  </div>
                </BlockStack>
                <BlockStack gap={"200"}>
                  <Text as="h1" variant="headingXs">
                    Pre-order period
                  </Text>
                  <div>
                    <p className="py-1">Timezone: Asia/Kolkata</p>
                    <p className="pt-2">
                      The pre-order time tells you when your pre-order settings
                      will turn on and/or off. If you want your pre-order
                      settings to take effect right away, leave the pre-order
                      start date blank or choose a date in the past.
                    </p>
                  </div>
                  <FormLayout>
                    <FormLayout.Group>
                      <div>
                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                          Pre-order start date
                        </span>
                        <React.Suspense fallback={<div>Loading...</div>}>
                          <LazyDatetime
                            value={formik.values.order_start_date}
                            onChange={(value) => {
                              formik.setFieldValue("order_start_date", value._d);
                            }}
                          />
                        </React.Suspense>
                      </div>
                      <div>
                        <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                          Pre-order end date
                        </span>
                        <React.Suspense fallback={<div>Loading...</div>}>
                          <LazyDatetime
                            value={formik.values.order_end_date}
                            onChange={(value) => {
                              formik.setFieldValue("order_end_date", value._d);
                            }}
                          />
                        </React.Suspense>
                      </div>
                    </FormLayout.Group>
                  </FormLayout>
                </BlockStack>
                <BlockStack gap={"200"}>
                  <Text as="h1" variant="headingXs">
                    Cart label
                  </Text>
                  <FormLayout>
                    <FormLayout.Group>
                      <TextField
                        name="cart_label_text"
                        label={"Cart label words"}
                        value={formik.values.cart_label_text}
                        placeholder=""
                        onChange={(value) => {
                          formik.setFieldValue("cart_label_text", value);
                        }}
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        error={
                          formik.errors.cart_label_text &&
                          formik.touched.cart_label_text
                            ? formik.errors.cart_label_text
                            : ""
                        }
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </BlockStack>
              </>
            ) : (
              ""
            )}
          </BlockStack>
        </Card>
        <div className="text-end pt-3">
          <Button
            onClick={formik.handleSubmit}
            variant="primary"
            loading={loading}
          >
            Save
          </Button>
        </div>
        {toastMarkup}
        <Footer />
      </Page>
    </AppProvider>
  );
};

export default AddTag;
