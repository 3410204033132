import {
  LegacyCard,
  TextField,
  Icon,
  IndexTable,
  useIndexResourceState,
  Button,
  Modal,
  Text,
  EmptySearchResult,
  Toast,
  DataTable,
  Pagination,
  Badge,
  InlineStack,
  BlockStack,
  Card,
  Banner,
  ButtonGroup,
  Spinner,
  Grid,
  Layout,
  FormLayout,
  Checkbox,
} from "@shopify/polaris";
import { SearchIcon, EditIcon, DeleteIcon } from "@shopify/polaris-icons";
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall, ShopifyCall } from "../helpers/axios";
import { setSingeProductData } from "../redux/singleProductSlice";
import { useNavigate } from "react-router-dom";
import noimg from "../assets/images/noimg.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setShopData } from "../redux/commonSlice";
// import BannerWarning from "../components/Banners/BannerWarning";

const CommonSingleProduct = ({
  productData = null,
  totalPage,
  currentpage,
  GetProductList,
}) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const is_free = shop_data?.is_freeplan;
  const store_client_id = shop_data.store_client_id;
  const store_name = shop_data.store_name;
  const [searchValue, setSearchValue] = useState("");
  const [productSearchValue, setProductSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [active, setActive] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [getProductData, setgetProductData] = useState([]);
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };
  const [activeModel, setActiveModel] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(0);
  const [cursor, setCursor] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [checkType, setcheckType] = useState(0);
  const [searchLoader, setSearchLoader] = useState(false);
  const [loading, setLoading] = useState(false); // Initialize loading state
  const [loadingAll, setLoadingAll] = useState(false); // Initialize loading state
  let is_all_product = shop_data.is_all_product == "0" ? false : true;
  const [checked, setChecked] = useState(false);
  const shop_plan = shop_data.shop_plan;

  const resourceName = {
    singular: "product",
    plural: "products",
  };

  const emptyStateMarkup = (
    <div className="pt-4">
      <EmptySearchResult
        title={"No products yet"}
        description={"Try changing the filters or search term"}
        withIllustration
      />
    </div>
  );
  const handleChangeAll = useCallback(
    (newChecked) => setChecked(newChecked),
    []
  );

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(productData);
  const rowMarkup =
    productData &&
    productData.map((data, index) => (
      <IndexTable.Row
        id={data.id}
        key={data.id}
        selected={selectedResources.includes(data.id)}
        position={index}
      >
        <IndexTable.Cell>
          <div className="d-flex align-items-center">
            {data.product_detail &&
            JSON.parse(data.product_detail) !== "" &&
            JSON.parse(data.product_detail).image ? (
              <>
                <LazyLoadImage
                  width={"40px"}
                  height={"40px"}
                  src={JSON.parse(data.product_detail).image}
                  alt="image"
                />
              </>
            ) : (
              <>
                <LazyLoadImage
                  width={"40px"}
                  height={"40px"}
                  src={`${noimg}`}
                  alt="image"
                />
              </>
            )}
            <div className="px-3">
              {data.product_detail && JSON.parse(data.product_detail).title}
            </div>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge
            tone={
              data.product_variant_status == 2
                ? "attention"
                : data.product_variant_status == 0
                ? "critical"
                : "success"
            }
          >
            {data.product_variant_status == 1
              ? "Pre-order enabled"
              : data.product_variant_status == 2
              ? "Pre-order enabled but Inactive on some variants"
              : "Pre-order disabled"}
          </Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {/* <ButtonGroup>
            {shop_data.plan_type >= 1 ||
            checkType == 1 ||
            Number(is_free) === 0 ? (
              <div className="edit_btn">
                <Button
                  onClick={() => {
                    navigate("/edit-single-product");
                    dispatch(setSingeProductData(data));
                  }}
                >
                  <Icon source={EditIcon} color="base" />
                </Button>
              </div>
            ) : (
              <div className="edit_btn">
                <Button
                  onClick={() => {
                    navigate("/pricingplan");
                    dispatch(setSingeProductData(data));
                  }}
                  plain
                >
                  <Icon source={EditIcon} color="base" />
                </Button>
              </div>
            )}
            <div className="edit_btn">
              <Button
                onClick={() => {
                  handleChange(data.id);
                }}
              >
                <Icon source={DeleteIcon} destructive />
              </Button>
            </div>
          </ButtonGroup> */}
          <InlineStack wrap={false} gap={100}>
            <Button
              icon={DeleteIcon}
              onClick={() => handleChange(data.id)}
              size="medium"
              tone="critical"
            />
            <Button
              onClick={() => {
                    if(shop_data.plan_type >= 1 || checkType == 1 || Number(is_free) === 0){
                  console.log("shop_data.plan_type 1");

                      navigate("/edit-single-product")
                    }else{
                  console.log("shop_data.plan_type 2");

                      navigate("/pricingplan");
                    }
                
                dispatch(setSingeProductData(data));
              }}
              icon={EditIcon}
              size="medium"
            />
          </InlineStack>
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const promotedBulkActions = [
    {
      content: "Delete",
      onAction: () => handleChange(selectedResources),
      icon: DeleteIcon,
      destructive: true,
    },
  ];

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={1000}
    />
  ) : null;

  const handleDeleteAction = async (id) => {
    let res = await ApiCall("DELETE", "/single-product", { id: id }, header);
    if (res?.data?.statusCode === 200) {
      productData = null;
      setToastMessage(res.data.message);
      setActive(!active);
      toggleActiveToast();
      GetProductList();
      toggleActive();
      getProductApiList();
    } else {
      setToastMessage(res.data.message);
      setActive(!active);
      toggleActiveToast();
      toggleActive();
      getProductApiList();
    }
    setcheckType(0);
    console.log("checkType", checkType);
  };

  const handleChange = (id, flag = false) => {
    setDeleteId(id);
    if (flag) {
      setcheckType(0);
      handleDeleteAction(deleteId);
    } else {
      setActive(!active);
    }
  };

  const toggleActive = useCallback(
    () => setActiveModel((activeModel) => !activeModel),
    []
  );

  const getProductApiList = async (query) => {
    let res = "";
    setSearchLoader(true);
    if (Object.keys(shop_data).length > 0) {
      let data = {
        store_client_id: store_client_id,
        shop_info: shop_data,
        shop: store_name,
        cursor: cursor,
        limit: 5,
        page_query: query ? query : "after",
      };
      if (productSearchValue.length > 0) {
        res = await ShopifyCall(
          "POST",
          `/search-product?search_key=${productSearchValue}`,
          data,
          header
        );
      } else {
        res = await ShopifyCall("POST", `/product-api-list`, data, header);
      }

      const rows = [];
      setcheckType(0);
      if (res?.data?.status === "success" && res?.data?.statusCode === 200) {
        let produts = res?.data?.data?.products;
        setcheckType(res?.data?.data?.check_type);
        if (produts.length > 0) {
          setHasPreviousPage(res?.data?.data?.is_previous_page);
          setHasNextPage(res?.data?.data?.is_next_page);
          setCursor(res?.data?.data?.nxt_page_cursor);
          produts.map(({ featuredImage, title, id }) => {
            rows.push([
              <div className="d-flex align-items-center">
                {featuredImage != "" ? (
                  <>
                    <LazyLoadImage
                      width={"40px"}
                      height={"40px"}
                      src={`${
                        featuredImage ? featuredImage.originalSrc : noimg
                      }`}
                      alt="image"
                    />
                  </>
                ) : (
                  <>
                    <LazyLoadImage
                      width={"40px"}
                      height={"40px"}
                      src={`${noimg}`}
                      alt="image"
                    />
                  </>
                )}
                <div className="px-3">{title}</div>
              </div>,
              <>
                <div className="edit_btn action_edit">
                  {shop_data.plan_type >= 1 ||
                  res?.data?.data?.check_type === 0 ||
                  Number(is_free) === 1 ? (
                    <Button
                      onClick={() =>
                        navigate(
                          `/edit-single-product/${id.split("/").reverse()[0]}`
                        )
                      }
                    >
                      <Icon source={EditIcon} color="base" />
                    </Button>
                  ) : (
                    <Button onClick={() => navigate(`/pricingplan`)}>
                      <Icon source={EditIcon} color="base" />
                    </Button>
                  )}
                </div>
              </>,
            ]);
          });
          setSearchLoader(false);
          setgetProductData(rows);
        } else {
          rows.push([
            <div className="no-data-found">
              No product available for pre-order.
            </div>,
          ]);
          setHasPreviousPage(0);
          setHasNextPage(0);
          setgetProductData(rows);
          setSearchLoader(false);
        }
      } else {
        rows.push([<div className="no-data-found">No data found.</div>]);
        setHasPreviousPage(0);
        setHasNextPage(0);
        setgetProductData(rows);
        setSearchLoader(false);
      }
    }
  };
  const handlePrice = () => {
    navigate("/pricingplan");
  };

  useEffect(() => {
    if (searchValue.length >= 3) {
      setLoading(true); // Set loading to true before calling GetProductList
      GetProductList(currentpage, searchValue).finally(() => {
        setLoading(false); // Set loading to false after GetProductList completes
      });
    } else {
      setLoading(true); // Set loading to true before calling GetProductList
      GetProductList(currentpage).finally(() => {
        setLoading(false); // Set loading to false after GetProductList completes
        setFilteredResults(productData);
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (productSearchValue.length === 0 || productSearchValue.length > 2) {
      getProductApiList();
    }
  }, [productSearchValue, shop_data]);

  return (
    <>
      { shop_data.plan_type == 0 && Number(is_free) === 0 && (
       <Banner
       tone="warning"
       title="Product limit exceeded"
       secondaryAction={{
         content: "Upgrade now",
         onAction: handlePrice,
       }}
     >
       <p>
         Your product limit is 1, you selected more than 1 product, to
         continue upgrade your plan.
       </p>
     </Banner>
      )}

      <div className="card-border-setting">
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <div className="card-border-setting card-flex-item">
              <Card>
                <BlockStack gap={400}>
                  <Text as="h1" variant="headingMd">
                    Search for products to make available for pre-order
                  </Text>
                  <TextField
                    prefix={<Icon source={SearchIcon} color="base" />}
                    value={productSearchValue}
                    onChange={(value) => setProductSearchValue(value)}
                    clearButton
                    onClearButtonClick={() => setProductSearchValue("")}
                    autoComplete="off"
                    placeholder="Search product"
                  />
                  <div className="pre-product-list">
                    {searchLoader ? (
                      <Spinner
                        accessibilityLabel="Spinner example"
                        size="large"
                      />
                    ) : (
                      <DataTable
                        columnContentTypes={["text", "text"]}
                        headings={[
                          <div className="preorder_pro_heading">Product</div>,
                          <div className="preorder_pro_heading action_edit">
                            Action
                          </div>,
                        ]}
                        rows={getProductData}
                      />
                    )}
                    <div className="single-product-pagination">
                      {hasNextPage || hasPreviousPage ? (
                        <Pagination
                          hasPrevious={hasPreviousPage}
                          previousTooltip="Previous"
                          onPrevious={() => getProductApiList("before")}
                          hasNext={hasNextPage}
                          nextTooltip="Next"
                          onNext={() => getProductApiList("after")}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </BlockStack>
              </Card>
            </div>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <div className="card-border-setting card-flex-item">
              <Card sectioned>
                <BlockStack gap={400}>
                  <Text as="h1" variant="headingMd">
                    Pre-order products
                  </Text>
                  <TextField
                    prefix={<Icon source={SearchIcon} color="base" />}
                    value={searchValue}
                    onChange={(value) => {
                      setSearchValue(value);
                    }}
                    clearButton
                    onClearButtonClick={() => setSearchValue("")}
                    autoComplete="off"
                    placeholder="Search product"
                  />
                  {loading ? ( // Show spinner if loading is true
                    <div className="d-flex justify-content-center pt-3">
                      <Spinner
                        accessibilityLabel="Loading products"
                        size="large"
                      />
                    </div>
                  ) : rowMarkup && rowMarkup.length ? (
                    <div className="pre-order-table pre-order-pro-reponsive">
                      <IndexTable
                        resourceName={resourceName}
                        itemCount={
                          searchValue.length > 1
                            ? filteredResults.length
                            : productData?.length
                        }
                        selectedItemsCount={
                          allResourcesSelected
                            ? "All"
                            : selectedResources?.length
                        }
                        promotedBulkActions={promotedBulkActions}
                        emptyState={emptyStateMarkup}
                        onSelectionChange={handleSelectionChange}
                        headings={[
                          { title: "Product" },
                          { title: "Status" },
                          { title: "Action" },
                        ]}
                      >
                        {rowMarkup}
                      </IndexTable>
                      <div className="single-product-pagination">
                        {totalPage > 1 ? (
                          <Pagination
                            hasPrevious={
                              currentpage === 1 || currentpage > totalPage
                                ? false
                                : true
                            }
                            previousTooltip="Previous"
                            onPrevious={() => {
                              setLoading(true);
                              GetProductList(
                                currentpage - 1,
                                searchValue
                              ).finally(() => {
                                setLoading(false);
                              });
                            }}
                            hasNext={totalPage === currentpage ? false : true}
                            nextTooltip="Next"
                            onNext={() => {
                              setLoading(true);
                              GetProductList(
                                currentpage + 1,
                                searchValue
                              ).finally(() => {
                                setLoading(false);
                              });
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    emptyStateMarkup
                  )}
                </BlockStack>
              </Card>
            </div>
          </Grid.Cell>
        </Grid>
        <Modal
          open={active}
          onClose={handleChange}
          title="Delete Product"
          primaryAction={{
            content: "Delete",
            destructive: true,
            onAction: () => {
              handleChange("", true);
            },
          }}
          secondaryActions={[
            {
              content: "Cancle",
              onAction: handleChange,
            },
          ]}
        >
          <Modal.Section>
            <Text>
              Are you sure, you want to delete{" "}
              {selectedResources.length > 1
                ? "Selected products"
                : "this product"}
              ?
            </Text>
          </Modal.Section>
        </Modal>
        {toastMarkup}
      </div>
    </>
  );
};

export default CommonSingleProduct;
